import * as React from 'react';

import * as Styled from './dimmer.styles';
import type { DimmerProps } from './dimmer.types';

export const DimmerPrimitive = React.forwardRef<HTMLDivElement, DimmerProps>(
  ({ className, onClick, title, ...props }, forwardedRef): JSX.Element => (
    <Styled.Dimmer
      className={className}
      data-xds="Dimmer"
      onClick={onClick}
      ref={forwardedRef}
      title={title}
      {...props}
    />
  )
);

DimmerPrimitive.displayName = 'Dimmer (Primitive)';
