import * as React from 'react';

import { scale030, scale110 } from '@xing-com/tokens';

import type { SkeletonSliderProps } from '../';
import * as Styled from './skeleton-slider.styles';

export const SkeletonSlider = React.forwardRef<
  HTMLDivElement,
  SkeletonSliderProps
>(
  ({ className, ...props }, forwardedRef): JSX.Element => (
    <Styled.Container className={className} ref={forwardedRef} {...props}>
      <Styled.Handle size={scale110} />
      <Styled.Slider height={scale030} />
    </Styled.Container>
  )
);

SkeletonSlider.displayName = 'SkeletonSlider';
