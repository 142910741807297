type ActivityCenterEntry = {
  id: string;
};

export function shouldOpenActivityCenterHelper(
  previousJobBookmarks: number | null,
  totalByEvents: Record<string, number | null>,
  origin: string
): boolean {
  if (typeof window === 'undefined') {
    return false;
  }

  const currentJobBookmarks = totalByEvents.jobBookmarks ?? 0;
  const shouldOpen =
    (previousJobBookmarks === 0 && currentJobBookmarks === 1) ||
    (previousJobBookmarks === 1 && currentJobBookmarks === 2);

  return shouldOpen && origin !== 'checkout_page';
}

export function removeDuplicatesFromRecentlyViewedJobs(
  recentlyViewedJobs: ActivityCenterEntry[],
  jobBookmarks: ActivityCenterEntry[]
): ActivityCenterEntry[] {
  return recentlyViewedJobs.filter(
    (recentJob) =>
      !jobBookmarks.some((bookmark) => bookmark.id === recentJob.id)
  );
}

export function getFirstFiveEntries(
  entries: ActivityCenterEntry[]
): ActivityCenterEntry[] {
  return entries.slice(0, 5);
}
