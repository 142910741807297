import type * as React from 'react';
import styled from 'styled-components';

import { MetaRenderer } from '@xing-com/meta-renderer';
import type { ConfigForResolvers, Config } from '@xing-com/meta-renderer';

import { commonResolvers } from './common-resolvers';
import { FooterNavItem } from './footer-nav-item';
import { FooterSection } from './footer-section';

const resolvers = { ...commonResolvers, FooterSection, FooterNavItem };

export type LoggedInFooterConfig = ConfigForResolvers<typeof resolvers>;

const FOOTER_SECTION_TOTAL_COUNT = 5;

export const BigScreenFooter: React.FC<{ config: Config }> = ({ config }) => {
  const placeholderCount = FOOTER_SECTION_TOTAL_COUNT - (config || []).length;

  return (
    <FooterSections data-qa="frame-footer-sections">
      <MetaRenderer resolvers={resolvers} config={config} />
      {Array(placeholderCount)
        .fill('')
        .map((_, i) => (
          <Placeholder key={i} />
        ))}
    </FooterSections>
  );
};

const FooterSections = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    flex: 1 1 20%;
  }
`;

const Placeholder = styled.li``;
