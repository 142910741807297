import styled from 'styled-components';

import { ConnectionDegree as ConnectionDegreeXdl } from '@xing-com/badge';

// *** Components ***
export const MaximumSizeContainer = styled.div`
  max-height: 318px;
  max-width: 318px;
  position: relative;
`;

export const Container = styled.div`
  height: 0;
  padding-block-start: 100%;
  width: 100%;
`;

export const ConnectionDegree = styled(ConnectionDegreeXdl)`
  inset-block-end: -4px;
  inset-inline-end: -2px;
  z-index: 2;
`;
