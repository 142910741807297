import type * as React from 'react';

import * as Styled from './notification-badge.styles';
const { variants } = Styled;

export type NotificationBadgeVariant = keyof typeof variants;
export type ChildrenType = string | number;
export type NotificationBadgeProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Content of the Notification Badge */
  children?: ChildrenType;
  /** Add custom CSS */
  className?: string;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLAnchorElement>;
  /** @deprecated Please use the "ref" property instead */
  innerRef?: React.Ref<HTMLAnchorElement>;
  /** Defines the boundary after that the maximum number is shown with a “+” attached */
  maxNumber?: number;
} & React.HTMLAttributes<HTMLAnchorElement>;
