import * as React from 'react';

import type { usePopOverType } from './use-pop-over.types';

export const usePopOver: usePopOverType = () => {
  const triggerRef = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const handleShow = (): void => setShow(true);
  const handleHide = React.useCallback(() => setShow(false), []);
  const togglePopOver = (): void => setShow(!show);
  const handleEsc = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        handleHide();
      }
    },
    [handleHide]
  );
  const getState = (): boolean => show;

  React.useEffect(() => {
    if (show) {
      document.addEventListener('keydown', handleEsc);
    }
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [show, handleEsc]);

  return {
    getState,
    handleHide,
    handleShow,
    togglePopOver,
    show,
    triggerRef,
  };
};
