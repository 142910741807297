import { useHost } from '@xing-com/crate-xinglet';
import type { Metadata } from '@xing-com/crate-xinglet';

export const useRoutingData = (baseSite: string | undefined): RouteMeta[] => {
  const host = useHost();
  const metadata = host.getXingletMetadata();
  const data = createDataLayer(metadata);
  const dataForBaseSite = filterForBaseSite(data, baseSite);
  return dataForBaseSite;
};

const DEFAULT_BASE_SITE = 'xing';

export const filterForBaseSite = (
  data: RouteMeta[],
  baseSite: string | undefined
): RouteMeta[] => {
  baseSite ??= DEFAULT_BASE_SITE;
  return data
    .filter(
      (item) =>
        item.route.startsWith(`site:${baseSite}`) ||
        (!item.route.startsWith('site:') && baseSite === DEFAULT_BASE_SITE)
    )
    .map((item) => ({
      ...item,
      route: item.route.replace(`site:${baseSite}`, ''),
    }));
};

export const createDataLayer = (metadata: Metadata[]): RouteMeta[] => {
  return metadata
    .map((item) => ({
      xinglet: item.name,
      ...item.contributes,
    }))
    .filter((item) => 'route' in item || 'routes' in item)
    .map((item) => {
      const { routes, route, ...rest } = item;

      if (routes) {
        return routes.map((route: string) => ({
          route,
          ...rest,
        }));
      } else {
        return [{ route, ...rest }];
      }
    })
    .flat()
    .map(
      ({
        route,
        xinglet,
        layout,
        layoutLoggedIn,
        activeItem,
        visibleTo,
        trackingChannel,
        pageName,
        loginDestination,
        loginTracking,
        disableCookieConsentBanner,
        cacheMaxAge,
      }) => ({
        route: route || '',
        xinglet,
        layout: layout || 'xing',
        layoutLoggedIn: layoutLoggedIn || layout || 'xing',
        activeItem,
        visibleTo: {
          loggedIn: visibleTo?.loggedIn ?? true,
          loggedOut: visibleTo?.loggedOut ?? false,
          softLoggedIn: visibleTo?.softLoggedIn ?? false,
        },
        trackingChannel,
        pageName,
        loginDestination,
        loginTracking,
        disableCookieConsentBanner: disableCookieConsentBanner ?? false,
        cacheMaxAge,
      })
    );
};

export type Layout = Exclude<
  Exclude<Metadata['contributes'], undefined>['layout'],
  undefined
>;

export type RouteMeta = {
  route: string;
  xinglet: string;
  layout: Layout;
  layoutLoggedIn: Layout;
  activeItem?: string;
  visibleTo: {
    loggedIn: boolean;
    loggedOut: boolean;
    softLoggedIn: boolean;
  };
  trackingChannel?: string;
  pageName?: string;
  loginDestination?: string;
  loginTracking?: string;
  disableCookieConsentBanner: boolean;
  cacheMaxAge?: number;
};
