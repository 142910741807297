/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconRocket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconRocket"
    {...props}
  >
    <path
      fill="currentColor"
      d="m10.071 16.782-4.243 4.243-1.414-1.415 4.243-4.242zm-2.828-2.829-2.829 2.829L3 15.368l2.828-2.829zM19.967 2.636l.16.39c.253.614.488 1.594.482 2.808-.01 2.129-.773 4.075-2.425 5.695.227 1.06.233 2.04.037 2.933-.363 1.653-1.335 2.815-2.524 3.558l-.24.141q-.108.06-.194.1l-.701.304L4.05 8.054l.268-.623.08-.17.056-.103.14-.239c.743-1.19 1.906-2.162 3.559-2.524.896-.197 1.878-.19 2.942.04 1.044-1.058 2.234-1.76 3.54-2.133a8 8 0 0 1 3.326-.222c.595.082 1.087.21 1.449.337l.147.055zM9.66 6.259a4.4 4.4 0 0 0-.823.042l-.255.047-.222.056A3.5 3.5 0 0 0 6.652 7.49l-.15.187 1.673 1.672q.644-1.767 1.485-3.09m6.698 6.697-.01.005q-1.318.831-3.086 1.475l1.678 1.678.018-.013a3.5 3.5 0 0 0 1.254-1.845l.056-.223q.11-.5.09-1.077m2.046-8.764-.148-.04a6 6 0 0 0-3.07.073c-2.192.627-4.02 2.52-5.255 6.156l-.118.357-.058.19 1.927 1.928.128-.039c4.669-1.469 6.605-3.81 6.785-6.587l.01-.204.004-.201c.002-.43-.04-.841-.112-1.225l-.061-.282zm-1.676 1.69a1.5 1.5 0 1 1-2.121 2.122 1.5 1.5 0 0 1 2.121-2.122"
    />
  </svg>
);
export default IconRocket;
