import styled, { css } from 'styled-components';

import { DotLoadingIndicator } from '@xing-com/dot-loading-indicator';
import {
  mediaConfined,
  mediaWide,
  zIndexBackgroundLayer1,
  zIndexContentLayer2,
} from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import {
  motionEasingEnter,
  motionTimeS,
  scale130,
  scale150,
  scale160,
  xdlColorDisabledStrong,
  xdlColorHoverSecondary,
  xdlColorSelected,
  xdlColorText,
  xdlColorTextInvert,
} from '@xing-com/tokens';

import type { IconButtonSizes } from './icon-button.types';

type IconButtonTransientProps = {
  $size?: IconButtonSizes;
  $sizeConfined?: IconButtonSizes;
  $sizeWide?: IconButtonSizes;
};

type IconContainerTransientProps = {
  $iconSize?: 18 | 24;
};

type LoadingTransientProps = {
  $size?: IconButtonSizes;
  $sizeConfined?: IconButtonSizes;
  $sizeWide?: IconButtonSizes;
};

// *** Sizes ***
export const sizes = {
  small: css`
    height: ${scale130};
    width: ${scale130};
  `,
  medium: css`
    height: ${scale150};
    width: ${scale150};
  `,
  large: css`
    height: ${scale160};
    width: ${scale160};
  `,
};

// *** Base ***
const baseButton = css`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${xdlColorText};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  vertical-align: top;
  z-index: ${zIndexContentLayer2};

  &::after {
    background-color: transparent;
    border-radius: 50px;
    box-sizing: border-box;
    content: '';
    height: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    transition-duration: ${motionTimeS};
    transition-property: transform, background-color;
    transition-timing-function: ${motionEasingEnter};
    width: 100%;
    z-index: ${zIndexBackgroundLayer1};
  }

  &:hover {
    color: ${xdlColorText};

    &::after {
      background-color: ${xdlColorHoverSecondary};
    }
  }

  &:active {
    color: ${xdlColorTextInvert};

    &::after {
      background-color: ${xdlColorSelected};
      transform: scale(0.95);
    }
  }

  &:disabled {
    color: ${xdlColorDisabledStrong};
    cursor: default;

    &::after {
      background-color: transparent;
      transform: none;
      user-select: none;
    }
  }

  &:visited {
    color: ${xdlColorText};
  }
`;

// *** Icon ***
export const IconContainer = styled.span<IconContainerTransientProps>`
  ${({ $iconSize }) =>
    $iconSize &&
    css`
      height: ${$iconSize}px;
      width: ${$iconSize}px;
    `};
`;

export const IconButton = styled.button<IconButtonTransientProps>`
  ${baseButton};

  ${({ $size }) => $size && sizes[$size]};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};
`;

export const RouterIconButton = styled(Link)<IconButtonTransientProps>`
  ${baseButton};

  ${({ $size }) => $size && sizes[$size]};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};
`;

const smallLoading = css`
  transform: translate(-50%, -50%) scale(0.6);
`;

const mediumLoading = css`
  transform: translate(-50%, -50%) scale(0.7);
`;

export const Loading = styled(DotLoadingIndicator)<LoadingTransientProps>`
  inset-block-start: 50%;
  inset-inline-start: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  ${({ $size }) => $size === 'small' && smallLoading};
  ${({ $size }) => $size === 'medium' && mediumLoading};

  ${({ $sizeConfined }) =>
    $sizeConfined === 'small' &&
    css`
      @media ${mediaConfined} {
        ${smallLoading}
      }
    `};

  ${({ $sizeConfined }) =>
    $sizeConfined === 'medium' &&
    css`
      @media ${mediaConfined} {
        ${mediumLoading}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide === 'small' &&
    css`
      @media ${mediaWide} {
        ${smallLoading}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide === 'medium' &&
    css`
      @media ${mediaWide} {
        ${mediumLoading}
      }
    `};
`;
