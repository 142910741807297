import { Link } from '@reach/router';
import type * as React from 'react';

import { joinUrl } from '@xing-com/crate-core-assets';
import { useHost, useLoginAppUrl } from '@xing-com/crate-xinglet';
import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';
import type { LinkProps, ReactLinkHandler } from '@xing-com/hub';
import { ReactLinkContext } from '@xing-com/hub';

import { handleLinkWithDomain } from './handle-link-with-domain';
import { internalPaths } from './internal-paths';

type LinkTo = LinkProps['to'];

export function isInternal(url: string): boolean {
  for (const internalPath of internalPaths) {
    let [path] = internalPath.split('?'); // remove params for the match
    path = path.length > 1 ? path.replace(/\/$/, '') : path; // ignore trailing slash
    const pattern = `^${path.replace('/', '\\/')}(\\/.*)?(\\?.*)?$`;

    if (new RegExp(pattern, 'i').test(url)) {
      return true;
    }
  }

  return false;
}

export function buildUrl(to: LinkTo, basePath?: string): string {
  if (typeof to !== 'string') {
    const path = [to.pathname, to.search].filter(Boolean).join('?');

    to = [path, to.hash].filter(Boolean).join('#');
  }

  if (basePath && basePath !== '/' && !/https?:/.test(to)) {
    to = joinUrl(basePath, to);
  }

  return to;
}

const handler: ReactLinkHandler = {
  Link({ to, href, children, ...props }): JSX.Element {
    const { getHostname } = useHost();
    const { entryPoint, basePath } = useRuntimeConfig();

    const loginAppUrl = useLoginAppUrl();
    const hostname = getHostname();

    const options = {
      entryPoint,
      loginAppUrl,
      hostname,
    };

    to = handleLinkWithDomain(to, options);
    href = handleLinkWithDomain(href, options);

    if (!to && !href) {
      return <a {...props}>{children}</a>;
    }

    const url = buildUrl(to ?? href, basePath);

    if (isInternal(url)) {
      return (
        <Link to={url} {...props}>
          {children}
        </Link>
      );
    } else {
      return (
        <a href={url} {...props}>
          {children}
        </a>
      );
    }
  },
};

export function ReachLinkModule({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element {
  return (
    <ReactLinkContext.Provider value={handler}>
      {children}
    </ReactLinkContext.Provider>
  );
}
