import styled from 'styled-components';

import { Link } from '@xing-com/link';
import {
  spaceXS,
  xdlColorTextInvert,
  xdlPalettePetrol10,
} from '@xing-com/tokens';
import { Butterfly, XingLogo } from '@xing-com/xing-logo';

const StyledButterfly = styled(Butterfly)`
  display: flex;
  align-items: center;
  color: ${xdlPalettePetrol10};
  height: 100%;
  width: 100%;
`;

const StyledXingLogo = styled(XingLogo)`
  padding-top: ${spaceXS};
`;

const StyledLink = styled(Link)`
  align-items: center;
  color: ${xdlColorTextInvert};
  display: flex;
  flex: 1 0 auto;
  height: 100%;
`;

export { StyledButterfly, StyledXingLogo, StyledLink };
