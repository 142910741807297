import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { zIndexElevatedLayer5 } from '@xing-com/layout-tokens';
import { Menu } from '@xing-com/menu';
import {
  scale070,
  scale150,
  spaceM,
  spaceXL,
  xdlColorHover,
  xdlColorControlFillTertiary,
  xdlColorText,
} from '@xing-com/tokens';

export const SettingsDropdownContainer = styled.div`
  position: relative;
`;

export const SettingsDropdownButton = styled(Button)`
  border-radius: ${scale150};
  padding: 3px ${spaceM};
  display: inline-flex;
  height: 56px;
  padding-left: ${spaceM};
  padding-right: ${spaceM};

  &:hover {
    background-color: ${xdlColorHover};
  }

  &:active {
    background-color: ${xdlColorControlFillTertiary};
    border-color: ${xdlColorControlFillTertiary};
    color: ${xdlColorText};
  }

  /* InnerContent */

  & > div {
    flex-direction: column;

    & > span:nth-child(2) {
      display: flex;
    }
    & > span {
      /* Icon */
      &:nth-child(1) {
        margin-right: 0;
        width: ${spaceXL};
        height: ${spaceXL};
        & > svg {
          width: ${spaceXL};
          height: ${spaceXL};
        }
      }
      /* Text */
      &:nth-child(2) {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: ${scale070};
        font-weight: normal;
      }
    }
  }
`;

const listMaxHeightTabletAndDesktop = '556px';
const listMinWidth = '264px';
const topMarginTabletAndDesktop = '62px';

export const StyledMenu = styled(Menu)`
  max-height: ${listMaxHeightTabletAndDesktop};
  min-width: ${listMinWidth};
  overflow: auto;
  right: 0;
  z-index: ${zIndexElevatedLayer5};
  position: absolute;
  top: ${topMarginTabletAndDesktop};
`;
