import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { TextButton } from '@xing-com/button';
import { getOriginFromPath } from '@xing-com/crate-activity-center-module';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { XingletLoader } from '@xing-com/crate-xinglet';
import { useActivityCenter } from '@xing-com/hub';
import { usePopOver } from '@xing-com/pop-over';
import { Portal } from '@xing-com/portal';
import { SuperEllipse } from '@xing-com/super-ellipse';
import { useMediaListener, BREAKPOINTS } from '@xing-com/util';

import {
  StyledBodyCopy,
  StyledMenu,
  StyledModalPage,
  StyledPopOver,
  StyledPopOverContentContainer,
  StyledPopOverTextContainer,
  SuperEllipseWrapper,
} from './wrapper.styled';

type WrapperProps = {
  activityCenterProps?: Record<string, unknown>;
};

const shouldOpenWrapper = (
  shouldOpenExternal: boolean,
  isActivityCenterVisible: boolean,
  isCheckoutPage: boolean
): boolean => {
  if (isCheckoutPage) {
    return false;
  }
  return shouldOpenExternal || isActivityCenterVisible;
};

export const Wrapper: React.FC<WrapperProps> = ({ activityCenterProps }) => {
  const {
    isActivityCenterVisible,
    setIsActivityCenterVisible,
    shouldOpenExternal,
    setShouldOpenExternal,
    totalByEvents,
  } = useActivityCenter();
  const [isAnimating, setIsAnimating] = useState(false);
  const [isPopOverVisible, setIsPopOverVisible] = useState(false);
  const popOver = usePopOver();
  const isMobile = BREAKPOINTS.handheld === useMediaListener();
  const { formatMessage } = useIntl();
  const { track } = useTracking<{
    PropTrackAction: string;
    PropContextDimension1: string;
    PropContextDimension2: string;
  }>();
  const origin = getOriginFromPath();
  const isCheckoutPage = origin === 'checkout_page';

  useEffect(() => {
    if (shouldOpenExternal && isMobile) {
      setIsPopOverVisible(true);
      setIsAnimating(true);
      setTimeout(() => {
        setShouldOpenExternal(false);
      }, 3000);
    } else {
      setIsPopOverVisible(false);
      setTimeout(() => {
        setIsAnimating(false);
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpenExternal]);

  useEffect(() => {
    if (isAnimating) {
      popOver.handleShow();
    } else {
      popOver.handleHide();
    }
  }, [isAnimating]);

  useEffect(() => {
    if (isActivityCenterVisible && !shouldOpenExternal) {
      const jobBookmarks = totalByEvents.jobBookmarks || 0;
      const jobViews = totalByEvents.jobVisits || 0;
      track({
        type: 'interaction',
        event: 'PropTrackAction',
        PropTrackAction: 'navigation_activitycenter_click',
        PropContextDimension1: `activitycenter_jobs_viewed_${jobViews}`,
        PropContextDimension2: `activitycenter_jobs_saved_${jobBookmarks}`,
      });
    }
  }, [isActivityCenterVisible, shouldOpenExternal]);

  if (isMobile) {
    return (
      <>
        <StyledPopOver
          className="position-override"
          // @ts-expect-error FIXME: SC6
          dimmerTitle="close activity center"
          onOutsideClick={() => {
            setIsPopOverVisible(false);
            setTimeout(() => {
              popOver.handleHide();
            }, 300);
          }}
          show={popOver.show}
          popOver={popOver}
          isVisible={isPopOverVisible}
        >
          <StyledPopOverContentContainer>
            <SuperEllipseWrapper>
              <SuperEllipse type="avatar" size="small" />
            </SuperEllipseWrapper>
            <StyledPopOverTextContainer>
              <StyledBodyCopy size="medium">
                {formatMessage({
                  id: 'ACTIVITY_CENTER_NOTIFICATION_MESSAGE',
                })}
              </StyledBodyCopy>
              <TextButton onClick={() => setIsActivityCenterVisible(true)}>
                {formatMessage({
                  id: 'ACTIVITY_CENTER_NOTIFICATION_OPEN',
                })}
              </TextButton>
            </StyledPopOverTextContainer>
          </StyledPopOverContentContainer>
        </StyledPopOver>
        {isActivityCenterVisible ? (
          <StyledModalPage
            closeButton={{
              onClick: () => {
                setIsActivityCenterVisible(false);
              },
            }}
            noGrid
            noMargin
          >
            <XingletLoader
              name="@xing-com/crate-activity-center-component"
              {...activityCenterProps}
            />
          </StyledModalPage>
        ) : null}
      </>
    );
  }
  return (
    <Portal containerId="activity-center-button">
      <StyledMenu
        dimmerTitle="close activity center"
        onOutsideClick={() => {
          setIsActivityCenterVisible(false);
          setShouldOpenExternal(false);
        }}
        show={shouldOpenWrapper(
          shouldOpenExternal,
          isActivityCenterVisible,
          isCheckoutPage
        )}
        isStatic
        isInFlow
        data-qa="activity-center-menu"
      >
        <XingletLoader
          name="@xing-com/crate-activity-center-component"
          {...activityCenterProps}
        />
      </StyledMenu>
    </Portal>
  );
};
