export const zIndexBelowContent = -100;
export const zIndexContent = 0;
export const zIndexAboveContent = 100;
export const zIndexSticky = 200;
export const zIndexSheet = 300;
export const zIndexBanner = 400;
export const zIndexAlert = 500;

export const zIndexBackgroundLayer1 = -100;
export const zIndexContentLayer2 = 0;
export const zIndexStickyLayer3 = 100;
export const zIndexElevatedLayer4 = 200;
export const zIndexElevatedLayer5 = 300;

/* GRID CHANGE MEDIA QUERIES */
export const mediaTiny = `(max-width: 539px)`;
export const mediaConfined = `(min-width: 740px)`;
export const mediaWide = `(min-width: 883px) and (max-width: 1023px), (min-width: 1113px)`;
export const mediaWideNavless = `(min-width: 1024px)`;
export const mediaXWide = `(min-width: 1310px)`;

export const mediaSmallOrTiny = `(max-width: 739px)`;
export const mediaSmallOnly = `(min-width: 540px) and (max-width: 739px)`;
export const mediaConfinedOnly = `(min-width: 740px) and (max-width: 882px), (min-width: 1024px) and (max-width: 1112px)`;
export const mediaConfinedNavlessOnly = `(min-width: 740px) and (max-width: 1023px)`;
/**
 * Range at which the side navigation appears in its normal, wider design.
 */
export const mediaNavExtended = '(min-width: 1268px)';
/**
 * Range at which the side navigation appears in its more condensed "tablet style"
 */
export const mediaNavCollapsed = '(min-width: 740px) and (max-width: 1267px)';
/**
 * Range at which the top bar is always visible, even without `showOnLargerScreens`.
 */
export const mediaTopBarVisible = '(max-width: 1023px)';
/**
 * Range at which the top bar is hidden, at least without `showOnLargerScreens`.
 */
export const mediaTopBarHidden = mediaWideNavless;
export const mediaWideOnly = `(min-width: 883px) and (max-width: 1023px), (min-width: 1113px) and (max-width: 1309px)`;
export const mediaWideNavlessOnly = `(min-width: 1024px) and (max-width: 1309px)`;

/* INTERNAL LAYOUT TOKENS */

export const columnPaddingS = '4px';
export const rowPaddingS = '16px';
export const containerPaddingS = '20px';
export const pagePaddingS = '0';

export const columnPaddingM = '8px';
export const rowPaddingM = '8px';
export const containerPaddingM = '16px';
export const pagePaddingM = '18px';

export const columnPaddingL = '10px';
export const rowPaddingL = '8px';
export const containerPaddingL = '18px';
export const pagePaddingL = '18px';

export const columnPaddingXL = '12px';
export const rowPaddingXL = '12px';
export const containerPaddingXL = '24px';
export const pagePaddingXL = '32px';

export const pagePaddingWXGA = '60px';

export const columnPaddingXXL = '16px';
export const rowPaddingXXL = '12px';
export const containerPaddingXXL = '28px';
export const pagePaddingXXL = '66px';

export const pagePaddingHD = '72px';

// from css file
export const columnWidth1 = 'calc(100% / 12 - 0.01px)';
export const columnWidth2 = 'calc(100% / 12 * 2 - 0.01px)';
export const columnWidth3 = '25%';
export const columnWidth4 = 'calc(100% / 12 * 4 - 0.01px)';
export const columnWidth5 = 'calc(100% / 12 * 5 - 0.01px)';
export const columnWidth6 = '50%';
export const columnWidth7 = 'calc(100% / 12 * 7 - 0.01px)';
export const columnWidth8 = 'calc(100% / 12 * 8 - 0.01px)';
export const columnWidth9 = '75%';
export const columnWidth10 = 'calc(100% / 12 * 10 - 0.01px)';
export const columnWidth11 = 'calc(100% / 12 * 11 - 0.01px)';
export const columnWidth12 = '100%';

export const armstrongTypeMaxWidth = '740px';
export const standardTypeMaxWidth = '984px';
export const wideTypeMaxWidth = '1356px';
export const pageMaxWidth = '2136px';
