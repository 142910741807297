import type * as React from 'react';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { useViewerData } from '@xing-com/hub';
import { HorizontalNavMenu } from '@xing-com/platform-layout-horizontal-nav-menu';
import { MeMenuDropdownList } from '@xing-com/platform-layout-me-menu';
import { ProfileImage } from '@xing-com/profile-image';
import { ProgressBadge } from '@xing-com/progress-badge';
import { scale170, scale430 } from '@xing-com/tokens';

const ProfileImageMeMenuContainer = styled.div`
  position: relative;
`;

const StyledProfileImageWrapper = styled(Button)`
  padding: 0;
  align-items: center;
  display: flex;
  height: ${scale170};
  justify-content: center;
`;

export const MeMenuDropdown: React.FC = () => {
  const viewerData = useViewerData();
  const profileImageUrl = (viewerData?.data?.user?.profileImage ?? [])[0]?.url;
  const networkEfficiencyIndicator =
    viewerData?.data?.networkEfficiencyIndicator || {};

  const { score, userTypeSupported } = networkEfficiencyIndicator;
  const showProfileFillingIndicator = userTypeSupported && score && score !== 1;

  const neffiValue = score
    ? Math.ceil(Math.round(score * 100) / 5) * 5
    : 'not_available';

  return (
    <ProfileImageMeMenuContainer>
      <HorizontalNavMenu
        tracking={{
          PropTrackAction: 'navigation_me_menu',
          PropActionOrigin: `navigation_neffi_${neffiValue}`,
        }}
        data-testid="me-menu-dropdown"
        width={scale430}
        trigger={(onClick: (event: React.MouseEvent) => void) => (
          <StyledProfileImageWrapper onClick={onClick}>
            <ProfileImage
              data-testid="header-profile-logo"
              src={profileImageUrl}
              // @ts-expect-error FIXME: SC6
              href="/profile"
              size="small"
              progressBadge={
                showProfileFillingIndicator ? (
                  <ProgressBadge
                    progress={Math.round(score * 100)}
                    data-testid="top-bar-profile-progress-badge"
                  />
                ) : null
              }
            />
          </StyledProfileImageWrapper>
        )}
        withMenu
      >
        <MeMenuDropdownList onBigScreen />
      </HorizontalNavMenu>
    </ProfileImageMeMenuContainer>
  );
};
