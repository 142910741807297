import { createGlobalStyle } from 'styled-components';

import {
  scale070,
  scale100,
  xdlColorBackground,
  xdlColorLink,
  xdlColorText,
} from '@xing-com/tokens';

export const BaseStyles = createGlobalStyle`

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: ${xdlColorBackground};
  color: ${xdlColorText};
  font-size: ${scale070};
  line-height: ${scale100};
  min-width: 320px;
  margin: 0;
}

body {
  visibility: visible;
}

main {
  width: 100%;
}

a {
  color: ${xdlColorLink};
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
}
p,
li {
  font-size: ${scale070};
  line-height: ${scale100};
}

/* due to https://github.com/necolas/normalize.css/blob/master/CHANGELOG.md#500-october-3-2016 */
button,
input,
select,
textarea {
  font-family: inherit;
  line-height: inherit;
}`;
