import { useEffect, useState, type FC, type PropsWithChildren } from 'react';

import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { ActivityCenterContext } from '@xing-com/hub';

import { shouldOpenActivityCenterHelper } from './activity-center-rules';
import { ActivityListener } from './activity-listener';
import { useTotals } from './use-totals';
import { getOriginFromPath } from './utils';

export const ActivityCenterModule: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const origin = getOriginFromPath();

  const [isActivityCenterVisible, setIsActivityCenterVisible] = useState(false);
  const [shouldOpenExternal, setShouldOpenExternal] = useState(false);
  const {
    total,
    totalByEvents,
    previousTotalByEvents,
    refetch,
    setTotalByEvents,
  } = useTotals(false);

  useEffect(() => {
    const previousJobBookmarks = previousTotalByEvents?.jobBookmarks;

    if (typeof previousJobBookmarks === 'number') {
      const shouldOpen = shouldOpenActivityCenterHelper(
        previousJobBookmarks,
        totalByEvents,
        origin
      );

      if (shouldOpen) {
        setShouldOpenExternal(true);
      } else {
        setShouldOpenExternal(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalByEvents, origin]);
  return (
    <ActivityCenterContext.Provider
      value={{
        isActivityCenterVisible,
        total,
        totalByEvents,
        setTotalByEvents,
        setIsActivityCenterVisible,
        origin,
        shouldOpenExternal,
        setShouldOpenExternal,
      }}
    >
      {children}
      <NoSSR>
        <ActivityListener onMessageReceived={refetch} />
      </NoSSR>
    </ActivityCenterContext.Provider>
  );
};
