import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import type {
  AsyncHookResult,
  LoginState,
  NetworkEfficiencyIndicator,
  ViewerData,
  XingId,
} from '@xing-com/hub';
import { ViewerDataContext } from '@xing-com/hub';

import type { XingFrameQueryQuery } from './xing-frame-query.gql-types';
import { XingFrameQueryDocument } from './xing-frame-query.gql-types';

export type LoggedInState = Exclude<LoginState, 'LOGGED_OUT'>;

const context = {
  LOGGED_OUT: {
    loginState: 'LOGGED_OUT' as const,
    useViewerData: useViewerDataLO,
  },
  LOGGED_IN: {
    loginState: 'LOGGED_IN' as const,
    useViewerData: useViewerData.bind(null, 'LOGGED_IN'),
  },
  SOFT_LOGGED_IN: {
    loginState: 'SOFT_LOGGED_IN' as const,
    useViewerData: useViewerData.bind(null, 'SOFT_LOGGED_IN'),
  },
};

export function ViewerDataModule({
  children,
  loginState,
}: React.PropsWithChildren<{ loginState: LoginState }>): JSX.Element {
  return (
    <ViewerDataContext.Provider value={context[loginState]}>
      {children}
    </ViewerDataContext.Provider>
  );
}

export function useViewerDataLO(): AsyncHookResult<ViewerData> {
  return {
    loading: false,
    data: { loginState: 'LOGGED_OUT' },
  };
}

export function useViewerData(
  loginState: LoggedInState
): AsyncHookResult<ViewerData> {
  const result = useQuery(XingFrameQueryDocument, { errorPolicy: 'all' });

  return useMemo(() => {
    const { data, error } = result;

    if (data?.viewer) {
      const viewerData = extractViewerData(loginState, data.viewer);

      return { loading: false, error: undefined, data: viewerData };
    } else if (error) {
      return { loading: false, error, data: undefined };
    } else {
      return { loading: true, error: undefined, data: undefined };
    }
  }, [loginState, result]);
}

function extractViewerData(
  loginState: LoggedInState,
  viewer: Exclude<XingFrameQueryQuery['viewer'], null | undefined>
): ViewerData {
  const hashedUserId = viewer.webTrackingData?.PropHashedUserId ?? '';
  const hasThirdPartyTrackingConsent =
    viewer.webTrackingData?.PropTrackingThirdpartyExternal ?? false;
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const networkEfficiencyIndicator = (viewer.networkEfficiencyIndicator ?? {
    score: 0,
    category: '',
    userTypeSupported: false,
  }) as NetworkEfficiencyIndicator;
  const willingnessToChangeJobs =
    viewer.tracy?.willingnessToChangeJobs?.probability ?? 0;
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const xingId = viewer.xingId as XingId;

  return {
    loginState,
    hashedUserId,
    hasThirdPartyTrackingConsent,
    networkEfficiencyIndicator,
    user: xingId,
    willingnessToChangeJobs,
  };
}
