import * as React from 'react';
import styled from 'styled-components';

import { useLoginState, useMetaConfig } from '@xing-com/hub';
import {
  mediaWideNavless,
  mediaConfined,
  mediaSmallOrTiny,
} from '@xing-com/layout-tokens';
import { MetaRenderer } from '@xing-com/meta-renderer';
import {
  spaceXS,
  spaceM,
  spaceXL,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

import { commonResolvers } from './common-resolvers';
import { LegalFooterNavItem as FooterNavItem } from './legal-footer-nav-item';

const FooterBox = styled.div`
  display: block;
  color: ${xdlColorTextSecondary};

  @media ${mediaConfined} {
    padding-left: 0;
    padding-right: 0;
  }

  @media ${mediaWideNavless} {
    padding: 0 ${spaceM};
  }
`;

const FooterNav = styled.nav`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -3px 0;

  & li {
    list-style: none;
    margin-right: ${spaceXL};

    @media ${mediaSmallOrTiny} {
      margin: 0 ${spaceXS};
    }
  }
`;

const FooterLanguage: React.VFC = () => <span> | &copy; New Work SE</span>;

const RenderNothing: React.VFC = () => null;

const resolvers = {
  ...commonResolvers,
  FooterNavItem,
  FooterBox,
  FooterBranding: RenderNothing,
  FooterNav,
  FooterNavLinks: React.Fragment,
  FooterLanguage,
  FooterLanguageLabel: RenderNothing,
  Button: RenderNothing,
};

export const LegalFooterStandalone: React.VFC = () => {
  const isLoggedIn = useLoginState() === 'LOGGED_IN';

  const { data } = useMetaConfig();

  if (!data) return null;

  const config = isLoggedIn
    ? data.navigation?.legalFooter
    : data.navigation?.loggedOutlegalFooter;

  if (!config) return null;

  return <MetaRenderer resolvers={resolvers} config={config} />;
};
