import type { FC } from 'react';
import styled, { css } from 'styled-components';

import type { ChildrenType } from '@xing-com/badge';
import { NotificationBadge } from '@xing-com/badge';
import { zIndexElevatedLayer4 } from '@xing-com/layout-tokens';
import { SuperEllipse } from '@xing-com/super-ellipse';
import { spaceXS, spaceS, spaceL, spaceM } from '@xing-com/tokens';

export const SuperEllipseWithBadge: FC<{ count: number }> = ({ count }) => {
  return (
    <SuperEllipseWrapper>
      <SuperEllipse type="avatar" size="xsmall" />
      {count ? (
        <StyledNotificationBadge>{count}</StyledNotificationBadge>
      ) : null}
    </SuperEllipseWrapper>
  );
};

const SuperEllipseWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  position: relative;
`;

const spaceForCount = (countString: ChildrenType | undefined): string => {
  const count = Number(countString);
  if (isNaN(count)) return spaceS;
  if (count < 10) return spaceS;
  if (count > 99) return spaceL;
  return spaceM;
};

const StyledNotificationBadge = styled(NotificationBadge)`
  position: absolute;
  z-index: ${zIndexElevatedLayer4};
  bottom: calc(${spaceXS} * -1);
  right: ${({ children }) => css`calc(${spaceForCount(children)} * -1)`};
`;
