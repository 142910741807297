import styled, { css, keyframes } from 'styled-components';

import {
  xdlColorSkeletonPrimary,
  xdlColorSkeletonPrimaryAnimation,
  motionTimeXXL,
  motionEasingStandard,
} from '@xing-com/tokens';

type SkeletonSuperEllipseTProps = {
  $size?: string;
};

// *** Animations ***
const animation = keyframes`
  0% {
    fill: ${xdlColorSkeletonPrimaryAnimation};
  }
  100% {
    fill: ${xdlColorSkeletonPrimary};
  }
`;

export const SkeletonSuperEllipse = styled.svg<SkeletonSuperEllipseTProps>`
  animation-direction: alternate;
  animation-duration: ${motionTimeXXL};
  animation-iteration-count: infinite;
  animation-name: ${animation};
  animation-timing-function: ${motionEasingStandard};
  fill: ${xdlColorSkeletonPrimary};

  ${({ $size }) =>
    $size &&
    css`
      height: ${$size};
      width: ${$size};
    `};
`;
