import type * as React from 'react';
import styled from 'styled-components';

import { SingleSelect } from '@xing-com/menu';
import { scale090, spaceS, scale070 } from '@xing-com/tokens';
import { trackingURL } from '@xing-com/xing-tracking';

export type SearchDropdownItemProps = {
  itemKey: string;
  href: string;
  title: string;
  icon: React.ElementType;
  tracking: Record<string, string | [string, string]>;
};

const StyledSingleSelect = styled(SingleSelect)`
  font-size: ${scale070};
`;

const StyledIcon = styled.svg`
  flex: 0 0 ${scale090};
  height: ${scale090};
  width: ${scale090};
  margin-right: ${spaceS};
`;

export const SearchDropdownItem: React.VFC<SearchDropdownItemProps> = ({
  itemKey,
  href,
  title,
  icon: IconElement,
  tracking,
}) => {
  const completeTrackingUrl = trackingURL(href, 'tracking')({ tracking });

  return (
    <StyledSingleSelect
      data-testid={`nav-search-${itemKey}`}
      to={completeTrackingUrl}
    >
      <StyledIcon as={IconElement} />
      <span>{title}</span>
    </StyledSingleSelect>
  );
};
