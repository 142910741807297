import * as React from 'react';

import devNote from '@xing-com/dev-note';

import type {
  Resolvers,
  Config,
  SerialisablePayload,
  SerialisablePayloadObject,
  SerialisablePayloadWithComponents,
  SerialisablePayloadWithComponentsObject,
} from './types';

type MetaRendererProps = {
  config: Config;
  resolvers: Resolvers;
  [key: string]: unknown;
};

export const MetaRenderer: React.FC<MetaRendererProps> = ({
  config,
  resolvers,
  ...globalProps
}) => {
  if (!Array.isArray(config)) {
    devNote.error('Meta config should be an Array', config);
    return null;
  }

  const elements = config.map((item, i) => {
    if (item === null) {
      devNote.error('Missing meta structure');
      return null;
    }
    if (typeof item === 'string') item = [item, {}];
    if (!Array.isArray(item)) {
      devNote.error('Malformed meta structure');
      return null;
    }
    if (item.length !== 2) {
      devNote.error('Missing component or props in meta structure declaration');
      return null;
    }
    const [componentId, componentProps] = item;

    if (!componentId) {
      devNote.warn('Missing Component', componentId);
      return null;
    }
    const Component = resolvers[componentId];
    if (!Component) {
      devNote.warn('Couldnt resolve', componentId);
      return null;
    }

    const mappedComponentProps = convertNestedMetasToComponents(
      componentProps,
      {
        config,
        resolvers,
        ...globalProps,
      }
    );

    return (
      <Component
        key={`${componentId}-${i}`}
        {...globalProps}
        {...mappedComponentProps}
      />
    );
  });

  return <React.Fragment>{elements}</React.Fragment>;
};

function convertNestedMetasToComponents(
  componentProps: SerialisablePayloadObject,
  props: MetaRendererProps
): SerialisablePayloadWithComponentsObject;
function convertNestedMetasToComponents(
  componentProps: SerialisablePayload,
  props: MetaRendererProps
): SerialisablePayloadWithComponents;
function convertNestedMetasToComponents(
  componentProps: SerialisablePayload,
  props: MetaRendererProps
): SerialisablePayloadWithComponents {
  if (componentProps && typeof componentProps === 'object') {
    if ('$component' in componentProps && componentProps.$component) {
      return props.resolvers[componentProps.$component];
    } else if ('$meta' in componentProps && componentProps.$meta) {
      return <MetaRenderer {...props} config={componentProps.$meta} />;
    } else {
      if (Array.isArray(componentProps)) {
        return componentProps.map((element) =>
          convertNestedMetasToComponents(element, props)
        );
      } else {
        return Object.fromEntries(
          Object.entries(componentProps).map(([key, value]) => [
            key,
            convertNestedMetasToComponents(value, props),
          ])
        );
      }
    }
  } else {
    return componentProps;
  }
}
