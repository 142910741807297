import * as React from 'react';

import { cornerRadiusM, scale150, scale160, scale170 } from '@xing-com/tokens';

import type { SkeletonDropdownProps } from '..';
import * as Styled from './skeleton-dropdown.styles';

export const SkeletonDropdown = React.forwardRef<
  HTMLDivElement,
  SkeletonDropdownProps
>(({ className, size = 'medium', ...props }, forwardedRef): JSX.Element => {
  const sizes = {
    small: scale150,
    medium: scale160,
    large: scale170,
  };

  return (
    <Styled.Dropdown
      className={className}
      cornerRadius={cornerRadiusM}
      data-xds="SkeletonDropdown"
      height={sizes[size]}
      ref={forwardedRef}
      {...props}
    />
  );
});

SkeletonDropdown.displayName = 'SkeletonDropdown';
