import type { MouseEvent } from 'react';

const COOKIE_REGEXP = /(?:^|;\s*)xing_csrf_token=([^;]+)/;
const postLogoutUser = (event: MouseEvent<HTMLButtonElement>): void => {
  event.preventDefault();

  if (!global.document) return;

  // ideally this could be retrieved from xing-hub
  const csrfTokenMatch = global.document.cookie.match(COOKIE_REGEXP);
  const csrfToken = csrfTokenMatch ? csrfTokenMatch[1] : '';

  // ideally this will be an absolute url (externalLoginUrl) from xing-hub
  const form = global.document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', '/login/logout');

  const csrfInput = global.document.createElement('input');
  csrfInput.setAttribute('type', 'hidden');
  csrfInput.setAttribute('name', 'authenticity_token');
  csrfInput.setAttribute('value', csrfToken);

  form.appendChild(csrfInput);
  document.body.appendChild(form);
  form.submit();
};

export default postLogoutUser;
