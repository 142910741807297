import styled from 'styled-components';

import { SkeletonRectangle } from '../';

type ImageTransientProps = {
  $height?: string;
  $width?: string;
};

// *** Components ***
export const Image = styled(SkeletonRectangle)<ImageTransientProps>`
  ${({ $height }) => $height && `height: ${$height}`};
  ${({ $width }) => $width && `width: ${$width}`};
`;
