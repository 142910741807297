import * as React from 'react';

import * as Styled from './progress-badge-fractional.styles';
import type { ProgressBadgeFractionalProps } from './progress-badge-fractional.types';

export const ProgressBadgeFractional = React.forwardRef<
  HTMLSpanElement,
  ProgressBadgeFractionalProps
>(
  (
    { className, currentValue, totalValue, ...props },
    forwardedRef
  ): JSX.Element | null => {
    if (!currentValue || !totalValue) {
      return null;
    }

    return (
      <Styled.ProgressBadgeFractional
        className={className}
        data-xds="ProgressBadgeFractional"
        ref={forwardedRef}
        {...props}
      >
        <Styled.Text
          forwardedAs="span"
          fontWeight="bold"
          noMargin
          size="xsmall"
        >
          {`${currentValue}/${totalValue}`}
        </Styled.Text>
      </Styled.ProgressBadgeFractional>
    );
  }
);

ProgressBadgeFractional.displayName = 'ProgressBadgeFractional';
