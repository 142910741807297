import { useIntl } from 'react-intl';

import { ProductsMenuItem } from './products-menu-item';

type ProductsMenuUpsellAreaProps = {
  isAdmasterUser?: boolean;
  isJobsPoster?: boolean;
  isCraUser?: boolean;
  isBrandManagerEditor?: boolean;
  isRecruitingConditionMet?: boolean;
};

export const ProductsMenuUpsellArea: React.FC<ProductsMenuUpsellAreaProps> = ({
  isAdmasterUser,
  isJobsPoster,
  isCraUser,
  isRecruitingConditionMet,
}) => {
  const { formatMessage, locale } = useIntl();

  return (
    <>
      {!isJobsPoster ? (
        <ProductsMenuItem
          upsellBackground
          isBestseller
          testId="products-menu-item-jobs-poster-upsell"
          link={{
            href:
              locale === 'de'
                ? 'https://recruiting.xing.com/de/produkte/stellenanzeigen'
                : 'https://recruiting.xing.com/en/products/job-ads',
            title: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_JOBS_POSTER_UPSELL',
            }),
            tracking: {
              sc_o: 'navigation_business_solution_job_ad',
            },
          }}
        />
      ) : null}
      {!isAdmasterUser ? (
        <ProductsMenuItem
          upsellBackground
          testId="products-menu-item-admaster-upsell"
          link={{
            href: 'https://werben.xing.com',
            title: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_ADVERTISING_UPSELL',
            }),
            tracking: {
              sc_o: 'navigation_business_solution_advertise',
            },
          }}
        />
      ) : null}
      {!isCraUser || (isJobsPoster && isCraUser) ? (
        <ProductsMenuItem
          upsellBackground
          testId="products-menu-item-talent-manager-upsell"
          link={{
            href: 'https://recruiting.xing.com',
            title: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_RECRUITING_UPSELL',
            }),
            tracking: {
              sc_o: 'navigation_business_solutions_recruiting',
            },
          }}
        />
      ) : null}
      {isRecruitingConditionMet ? (
        <ProductsMenuItem
          is360Upsell
          testId="products-menu-item-recruiting-360-upsell"
          link={{
            href: 'https://recruiting.xing.com/de/produkte/xing-360',
            title: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_RECRUITING_360_UPSELL',
            }),
            tracking: {
              sc_o: 'navigation_business_solutions_recruiting',
            },
          }}
        />
      ) : null}
      <ProductsMenuItem
        testId="products-menu-item-lebenslauf"
        link={{
          href: 'https://www.xing.com/lebenslauf',
          title: formatMessage({
            id: 'MAINNAV_PRODUCTS_MENU_LEBENSLAUF',
          }),
          description: formatMessage({
            id: 'MAINNAV_PRODUCTS_MENU_LEBENSLAUF_DESCRIPTION',
          }),
          tracking: {
            sc_o: 'navigation_lebenslauf',
          },
        }}
      />
    </>
  );
};
