/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconCompaniesFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconCompaniesFilled"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 2v20h-4v-4h-2v4H9v-4H7v4H3V9h8V2zM9 13H7v2h2zm8-3h-2v2h2zm0-4h-2v2h2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCompaniesFilled;
