import type * as React from 'react';

import type { BadgeSize } from '..';
import * as Styled from './connection-degree.styles';
const { variants } = Styled;

export type ConnectionDegreeVariants = keyof typeof variants;
export type ConnectionDegreeProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Content of the Connection Degree */
  children?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLAnchorElement>;
  /** The size on mobile screens or larger */
  size?: BadgeSize;
  /** The size on rablet screens or larger */
  sizeConfined?: BadgeSize;
  /** The size on desktop screens or larger */
  sizeWide?: BadgeSize;
  /** The size on large desktop screens or larger */
  sizeXWide?: BadgeSize;
  /** The variant of Connection Degree */
  variant?: ConnectionDegreeVariants;
} & React.HTMLAttributes<HTMLAnchorElement>;
