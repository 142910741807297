import type * as React from 'react';
import styled from 'styled-components';

import {
  useIsBottomBarEnabled,
  useIsBrowserBarOpen,
  useMetaConfig,
  useLoginState,
} from '@xing-com/hub';
import {
  mediaSmallOrTiny,
  mediaWideNavless,
  zIndexStickyLayer3,
} from '@xing-com/layout-tokens';
import { MetaRenderer } from '@xing-com/meta-renderer';
import type { ConfigForResolvers } from '@xing-com/meta-renderer';
import { commonMetaRendererResolvers } from '@xing-com/platform-layout-common-meta-renderer-resolvers';
import {
  xdlColorBackground,
  xdlColorBorderSoft,
  scale180,
} from '@xing-com/tokens';

import { MainListItem } from './main-list';

const NavList = styled.ul`
  display: flex;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Nav = styled.nav`
  text-align: center;
  background-color: ${xdlColorBackground};
  border-top: 1px solid ${xdlColorBorderSoft};
  height: 100%;
  width: 100%;
`;

type ContainerProps = {
  $hide?: boolean;
};

const Container = styled.div<ContainerProps>`
  bottom: ${({ $hide }) => ($hide ? `-${scale180}` : 0)};
  height: ${scale180};
  left: 0;
  position: fixed;
  right: 0;
  transition: 150ms cubic-bezier(0.2, 0.2, 0.2, 1);
  transition-property: bottom;
  z-index: ${zIndexStickyLayer3};
`;

type MainListProps = React.PropsWithChildren<{
  'data-testid'?: string;
}>;

const MainList: React.FC<MainListProps> = (props) => {
  return (
    <Nav key="main">
      <NavList {...props} />
    </Nav>
  );
};

const resolvers = {
  ...commonMetaRendererResolvers,
  MainList,
  MainListItem,
};

const StyledBottomBarPlacebo = styled.div`
  @media ${mediaSmallOrTiny} {
    height: ${scale180};
  }
  @media ${mediaWideNavless} {
    display: none;
  }
`;

export type BottomNavigationConfig = ConfigForResolvers<typeof resolvers>;
export type BottomNavigationLoggedOutConfig = ConfigForResolvers<
  typeof resolvers
>;

export const BottomBar: React.FC = () => {
  const isLoggedOut = useLoginState() === 'LOGGED_OUT';
  const { data } = useMetaConfig();
  const { bottomNavigation, bottomNavigationLoggedOut } = data?.navigation ?? {
    bottomNavigation: [],
    bottomNavigationLoggedOut: [],
  };

  const isBrowserBarOpen = useIsBrowserBarOpen();
  const isBottomBarEnabled = useIsBottomBarEnabled();

  return isBottomBarEnabled ? (
    <StyledBottomBarPlacebo>
      <Container data-testid="BottomNav" $hide={!isBrowserBarOpen}>
        <MetaRenderer
          config={isLoggedOut ? bottomNavigationLoggedOut : bottomNavigation}
          resolvers={resolvers}
        />
      </Container>
    </StyledBottomBarPlacebo>
  ) : null;
};
