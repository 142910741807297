import { useState, useRef } from 'react';
import type { FC, PropsWithChildren } from 'react';

import { OmViewContext } from '@xing-com/hub';

export const OmViewModule: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [isOmViewVisible, setIsOmViewVisible] = useState(false);
  const omViewRef = useRef<HTMLDivElement>(null);

  return (
    <OmViewContext.Provider
      value={{ isOmViewVisible, setIsOmViewVisible, omViewRef }}
    >
      {children}
    </OmViewContext.Provider>
  );
};
