import type * as React from 'react';
import styled from 'styled-components';

import { Link } from '@xing-com/link';
import { scale150, spaceXXL, xdlColorTextSecondary } from '@xing-com/tokens';

import { useGeneratedLink } from './generate-link';

export const LegalFooterNavItem: React.FC<{
  title: string;
  href: string;
  tracking?: Record<string, string>;
}> = ({ title, href, tracking }) => {
  const link = useGeneratedLink(href, tracking);

  const reOpenConsent = (): void => {
    // @ts-expect-error UC_UI comes from the usercentrics consent script
    UC_UI.showSecondLayer();
  };

  return (
    <Item>
      {link === 'usercentricsConsentBanner' ? (
        <Link to={''} onClick={reOpenConsent}>
          {title}
        </Link>
      ) : (
        <Link to={link}>{title}</Link>
      )}
    </Item>
  );
};

const Item = styled.li`
  height: ${scale150};
  line-height: ${scale150};
  margin-right: ${spaceXXL};

  & a {
    color: ${xdlColorTextSecondary};
  }
`;
