import styled from 'styled-components';

import { Link } from '@xing-com/link';
import { SingleSelect } from '@xing-com/menu';

type BsMenuItemProps = {
  link: { title: string; href: string };
};

const StyledLink = styled(Link)`
  color: inherit;
  &:hover {
    text-decoration: none;
  }
`;

export const BsMenuItem: React.FC<BsMenuItemProps> = ({ link }) => {
  return (
    <StyledLink target="_blank" to={link.href}>
      <SingleSelect>{link.title}</SingleSelect>
    </StyledLink>
  );
};
