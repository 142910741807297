import type * as React from 'react';
import styled, { css } from 'styled-components';

import { useActiveItem, useViewerData } from '@xing-com/hub';
import { mediaTopBarVisible } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import { ProgressBadge } from '@xing-com/progress-badge';
import {
  spaceS,
  spaceM,
  xdlColorProJobs,
  xdlColorPremium,
  xdlColorHover,
  xdlColorText,
  xdlColorHoverSecondary,
  xdlColorProBusiness,
  cornerRadiusL,
  spaceXL,
  scale090,
  scale080,
} from '@xing-com/tokens';
import { trackingURL } from '@xing-com/xing-tracking';

type StyledLinkProps = {
  $color?: 'premium' | 'proJobs' | 'proBusiness';
  $isActive?: boolean;
};

export const StyledLink = styled(Link)<StyledLinkProps>`
  border-radius: ${cornerRadiusL};
  box-sizing: border-box;
  color: ${xdlColorText};
  font-size: ${scale080};
  display: flex;
  padding: ${spaceM} 0 ${spaceM} ${spaceS};
  width: auto;
  align-items: center;

  ${({ $isActive }) =>
    $isActive
      ? css`
          font-weight: bold;
        `
      : css``}

  &:hover {
    background-color: ${xdlColorHover};
    text-decoration: none;
  }
  &:active {
    background-color: ${xdlColorHoverSecondary};
  }

  ${({ $color }) => css`
    color: ${$color === 'premium'
      ? xdlColorPremium
      : $color === 'proJobs'
        ? xdlColorProJobs
        : $color === 'proBusiness' && xdlColorProBusiness};
  `};

  @media ${mediaTopBarVisible} {
    line-height: ${spaceXL};
    font-size: ${scale090};

    padding: ${spaceM} 0 ${spaceM} 0;
  }
`;

const StyledProgressBadge = styled(ProgressBadge)`
  margin-left: ${spaceM};
`;

type MeMenuItemProps = {
  color?: 'premium' | 'proJobs' | 'proBusiness';
  'data-testid'?: string;
  href: string;
  title: string;
  hasProfileProgressIndicator?: boolean;
  activeItemValues?: string[];
  closeModal?: () => void;
  tracking?: Record<string, string | [string, string] | string | undefined>;
};

export const MeMenuItem: React.FC<MeMenuItemProps> = ({
  closeModal,
  href,
  title,
  color,
  tracking,
  hasProfileProgressIndicator,
  activeItemValues,
  'data-testid': dataTestid,
}) => {
  const activeItem = useActiveItem();
  const isActive = activeItem ? activeItemValues?.includes(activeItem) : false;

  const viewerData = useViewerData();
  const score = viewerData?.data?.networkEfficiencyIndicator?.score;

  const showProfileFillingIndicator =
    hasProfileProgressIndicator && score && score !== 1;

  const neffiValue = score
    ? Math.ceil(Math.round(score * 100) / 5) * 5
    : 'not_available';
  const trackingProps =
    href === '/profile'
      ? {
          ...tracking,
          sc_o_PropActionOrigin: `navigation_neffi_${neffiValue}`,
          ...(score && { expandNeffi: true }),
        }
      : tracking;
  const completeTrackingURL =
    href && trackingURL(href, 'tracking')({ tracking: trackingProps });

  return (
    <StyledLink
      $isActive={isActive}
      $color={color}
      data-testid={dataTestid}
      to={completeTrackingURL}
      onClick={closeModal}
    >
      <span>{title}</span>

      {showProfileFillingIndicator ? (
        <StyledProgressBadge
          progress={Math.round(score * 100)}
          data-testid="me-menu-item-profile-progress-badge"
        />
      ) : null}
    </StyledLink>
  );
};
