import { createContext, useContext } from 'react';
import type * as React from 'react';

import type { AsyncHookResult, Features, FeatureSwitches } from '@xing-com/hub';
import { FeaturesContext, FeatureSwitchesContext } from '@xing-com/hub';

const featuresContext = { useFeature, useFeatures };
const featureSwitchesContext = { useFeatureSwitch, useFeatureSwitches };

export function FeatureSwitchesModule({
  children,
  features,
  featureSwitches,
}: React.PropsWithChildren<{
  features: AsyncHookResult<Features>;
  featureSwitches: AsyncHookResult<FeatureSwitches>;
}>): JSX.Element {
  return (
    <InternalContext.Provider value={{ features, featureSwitches }}>
      <FeaturesContext.Provider value={featuresContext}>
        <FeatureSwitchesContext.Provider value={featureSwitchesContext}>
          {children}
        </FeatureSwitchesContext.Provider>
      </FeaturesContext.Provider>
    </InternalContext.Provider>
  );
}

function useFeature<TFallback>(
  name: string,
  fallback: TFallback
): boolean | TFallback {
  const { data: features } = useFeatures();

  return features?.[name] ?? fallback;
}

function useFeatures(): AsyncHookResult<Features> {
  return useContext(InternalContext).features;
}

function useFeatureSwitches(): AsyncHookResult<FeatureSwitches> {
  return useContext(InternalContext).featureSwitches;
}

function useFeatureSwitch<TFallback>(
  name: string,
  fallback: TFallback
): boolean | TFallback {
  const { data } = useFeatureSwitches();

  return data?.includes(name) ?? fallback;
}

const InternalContext = createContext<{
  features: AsyncHookResult<Features>;
  featureSwitches: AsyncHookResult<FeatureSwitches>;
}>({
  features: { loading: true },
  featureSwitches: { loading: true },
});
