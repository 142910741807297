import styled from 'styled-components';

import { zIndexElevatedLayer5 } from '@xing-com/layout-tokens';
import { xdlColorDimming } from '@xing-com/tokens';

// *** Components ***
export const Dimmer = styled.div`
  background: ${xdlColorDimming};
  content: '';
  cursor: pointer;
  height: 100vh;
  left: 0;
  opacity: 60%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(${zIndexElevatedLayer5} - 1);
`;
