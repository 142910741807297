import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import {
  scale130,
  scale150,
  scale180,
  scale220,
  spaceL,
  spaceXL,
} from '@xing-com/tokens';

import type { HeroSize } from './hero.types';

type HeroTProps = {
  $noMargin?: boolean;
  $size: HeroSize;
  $sizeConfined?: HeroSize;
  $sizeWide?: HeroSize;
};

// *** Base ***
const baseHero = css`
  font-weight: 700;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
`;

const noMargin = css`
  && {
    margin: 0;
  }
`;

// *** Sizes ***
export const sizes = {
  small: css`
    font-size: ${scale130};
    line-height: 1.2;
    margin-bottom: ${spaceL};
  `,
  medium: css`
    font-size: ${scale150};
    line-height: 1.2;
    margin-bottom: ${spaceXL};
  `,
  large: css`
    font-size: ${scale180};
    line-height: 1.1;
    margin-bottom: ${spaceXL};
  `,
  xlarge: css`
    font-size: ${scale220};
    line-height: 1.1;
    margin-bottom: ${spaceXL};
  `,
};

// *** Components ***
const Hero = styled.h1<HeroTProps>`
  ${baseHero};
  ${({ $size }) => $size && sizes[$size]};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]};
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]};
      }
    `};

  ${({ $noMargin }) => $noMargin && noMargin};
`;

export const Styled = {
  Hero,
};
