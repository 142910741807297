import * as React from 'react';
import ReactDom from 'react-dom';

import { DimmerPrimitive } from './dimmer.primitive';
import type { DimmerProps } from './dimmer.types';

export const Dimmer = React.forwardRef<HTMLDivElement, DimmerProps>(
  ({ className, onClick, title, ...props }, forwardedRef): JSX.Element => {
    return ReactDom.createPortal(
      <DimmerPrimitive
        className={className}
        onClick={onClick}
        ref={forwardedRef}
        title={title}
        {...props}
      />,
      document.body
    );
  }
);

Dimmer.displayName = 'Dimmer';
