import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide, mediaXWide } from '@xing-com/layout-tokens';
import {
  scale050,
  scale070,
  scale100,
  scale110,
  scale130,
} from '@xing-com/tokens';

import type { BadgeSize } from './badge.types';

type BadgeTransientProps = {
  $size?: BadgeSize;
  $sizeConfined?: BadgeSize;
  $sizeWide?: BadgeSize;
  $sizeXWide?: BadgeSize;
};

// *** Sizes ***
export const sizes = {
  small: css`
    font-size: ${scale050};
    height: ${scale100};
    width: ${scale100};
  `,
  medium: css`
    font-size: ${scale070};
    height: ${scale110};
    width: ${scale110};
  `,
  large: css`
    font-size: ${scale100};
    height: ${scale130};
    width: ${scale130};
  `,
};

// *** Components ***
export const Badge = styled.span<BadgeTransientProps>`
  align-items: center;
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  padding: 0;

  /** Alignmet fix for IE11 */
  @media all and (-ms-high-contrast: none) {
    padding-top: 3px;
  }

  ${({ $size }) => $size && sizes[$size]};

  @media ${mediaConfined} {
    ${({ $sizeConfined }) => $sizeConfined && sizes[$sizeConfined]};
  }

  @media ${mediaWide} {
    ${({ $sizeWide }) => $sizeWide && sizes[$sizeWide]};
  }

  @media ${mediaXWide} {
    ${({ $sizeXWide }) => $sizeXWide && sizes[$sizeXWide]};
  }
`;
