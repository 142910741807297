import type * as React from 'react';

import { useNotifications } from '@xing-com/hub';
import { usePopOver } from '@xing-com/pop-over';

import {
  StyledIconWithBadge,
  StyledLink,
  StyledTitle,
} from '../nav-list/nav-item/nav-item.styles';
import { MoreMenuList } from './more-menu-list';
import { MoreMenuContainer, StyledMenu } from './more-menu.styles';

export type MoreMenuProps = {
  item: {
    icon: React.ElementType;
    key: string;
    notificationKey?: string;
    title: string;
    titleShort?: string;
  };
};

export const MoreMenu: React.FC<MoreMenuProps> = ({ item }) => {
  const notifications = useNotifications();
  const popOver = usePopOver();

  const notificationCount =
    notifications && notifications[item.notificationKey || ''];

  return (
    <li>
      <StyledLink
        $isActive={false}
        data-qa={`frame-vnav-${item.key}`}
        onClick={popOver.togglePopOver}
      >
        <StyledIconWithBadge
          icon={item.icon}
          title={item.title}
          count={notificationCount}
        />
        <StyledTitle $size="long">{item.title}</StyledTitle>
        <StyledTitle $size="short">{item.titleShort || item.title}</StyledTitle>
      </StyledLink>

      <MoreMenuContainer>
        <StyledMenu
          data-qa="SettingsDropdown"
          isInFlow
          isStatic
          dimmerTitle="close menu"
          onOutsideClick={popOver.handleHide}
          show={popOver.show}
          triggerRef={popOver.triggerRef}
        >
          <MoreMenuList closeModal={popOver.handleHide} />
        </StyledMenu>
      </MoreMenuContainer>
    </li>
  );
};
