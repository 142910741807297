import type { ConfigurationItem, Translate } from '../types';

const tracking = { sc_o: 'navigation_header' };

export const moreMenuDropdown: ConfigurationItem = (t: Translate) => {
  return [
    [
      'MoreMenuItem',
      {
        link: {
          key: 'invoicesPersonal',
          href: '/settings/purchases',
          title: t('VERTICAL_NAVIGATION_HEADER_PERSONAL_MEMBERSHIPS_INVOICES'),
          tracking,
        },
      },
    ],
    [
      'MoreMenuItem',
      {
        link: {
          key: 'settingsPersonal',
          href: '/settings',
          title: t('VERTICAL_NAVIGATION_HEADER_PERSONAL_SETTINGS'),
          tracking,
        },
      },
    ],
    [
      'MoreMenuItem',
      {
        link: {
          key: 'premiumUpsellPersonal',
          icon: { $component: 'IconPremium' },
          href: '/purchase/premium?reagent=uplt_284',
          title: t('VERTICAL_NAVIGATION_HEADER_PERSONAL_PREMIUM_MEMBERSHIP'),
          color: 'premium',
          tracking,
        },
      },
    ],
    [
      'MoreMenuItem',
      {
        link: {
          key: 'proJobsPersonal',
          icon: { $component: 'IconProJobs' },
          href: '/purchase/projobs?reagent=uplt_283',
          title: t('VERTICAL_NAVIGATION_HEADER_PERSONAL_PROJOBS_MEMBERSHIP'),
          color: 'proJobs',
          tracking,
        },
      },
    ],
    [
      'FeatureCondition',
      {
        children: {
          $meta: [
            [
              'MoreMenuItem',
              {
                link: {
                  key: 'jobsPosterPersonal',
                  href: '/jobmanager/online',
                  title: t('VERTICAL_NAVIGATION_HEADER_MANAGE_JOBS'),
                  tracking,
                },
              },
            ],
          ],
        },
        flags: 'isJobsPoster',
      },
    ],
  ];
};
