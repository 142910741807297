/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconPlus"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.25 12.75V19h1.5v-6.25H19v-1.5h-6.25V5h-1.5v6.25H5v1.5z"
    />
  </svg>
);
export default IconPlus;
