import * as React from 'react';

import { calculateBlur, FallbackImage } from '../util';
import * as Styled from './super-ellipse-responsive.styles';
import type { SuperEllipseResponsiveProps } from './super-ellipse-responsive.types';

export const SuperEllipseResponsive = React.forwardRef<
  HTMLDivElement,
  SuperEllipseResponsiveProps
>(
  (
    { blur, className, profileName, src, type, ...props },
    forwardedRef
  ): JSX.Element => {
    const ref = React.useRef<HTMLDivElement>(null);
    const imageRef = React.useRef<HTMLImageElement>(null);
    const [containerSize, setContainerSize] = React.useState<
      number | undefined
    >();
    const [loaded, setLoaded] = React.useState(false);
    const [imageError, setImageError] = React.useState(false);

    const onImageLoad = (): void => {
      setLoaded(true);
    };

    React.useEffect(() => {
      if (blur) {
        setContainerSize(ref.current?.clientWidth);
      }
    }, [ref, blur]);

    const blurValue = blur ? calculateBlur(containerSize) : undefined;
    const handleImageError = (): void => setImageError(true);

    React.useEffect(() => {
      const img = imageRef.current;
      if (img && img.complete) {
        setLoaded(true);
      }
    }, []);

    return (
      <Styled.Wrapper className={className} ref={forwardedRef}>
        <Styled.SuperEllipse data-xds="SuperEllipseResponsive">
          <Styled.ImageContainer $blur={blur} $blurValue={blurValue} ref={ref}>
            <Styled.ImageSvgBorder viewBox="0 0 256 256">
              <path
                className="strokeWidth"
                d="M18.947 18.947C5.627 32.267 1 61.517 1 128s4.626 95.732 17.947 109.053C32.267 250.373 61.517 255 128 255s95.732-4.626 109.053-17.947C250.373 223.733 255 194.483 255 128s-4.626-95.732-17.947-109.053C223.733 5.627 194.483 1 128 1S32.268 5.626 18.947 18.947z"
                fill="transparent"
                stroke="transparent"
                vectorEffect="non-scaling-stroke"
              />
            </Styled.ImageSvgBorder>
            {src && !imageError ? (
              <Styled.Image
                alt={profileName}
                aria-label={profileName}
                onError={handleImageError}
                onLoad={onImageLoad}
                ref={imageRef}
                src={src}
                style={loaded ? {} : { display: 'none' }}
                title={profileName}
                {...props}
              />
            ) : (
              <Styled.FallbackImage>
                <FallbackImage
                  arial-label={profileName}
                  data-qa="fallback-image"
                  data-testid="fallback-image"
                  type={type}
                  {...props}
                />
              </Styled.FallbackImage>
            )}
          </Styled.ImageContainer>
        </Styled.SuperEllipse>
      </Styled.Wrapper>
    );
  }
);

SuperEllipseResponsive.displayName = 'SuperEllipseResponsive';
