import type { Layout } from '@xing-com/crate-communication-data-layer';
import type { UseLoginState } from '@xing-com/crate-hooks-use-login-state';

import { UnifiedLayout } from './unified-layout';

type LayoutSwitcherProps = {
  layout: Layout;
  loginState: UseLoginState;
  loginDestination?: string;
  loginTracking?: string;
};

const LayoutSwitcher: React.FC<
  React.PropsWithChildren<LayoutSwitcherProps>
> = ({ layout, children, loginState, loginDestination, loginTracking }) => {
  if (layout === 'blank') {
    return <>{children}</>;
  }

  return (
    <UnifiedLayout
      layout={layout}
      loginDestination={loginDestination}
      loginTracking={loginTracking}
      isLoggedInLayout={loginState.isLoggedIn}
    >
      {children}
    </UnifiedLayout>
  );
};

export { LayoutSwitcher };
