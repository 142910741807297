import type * as React from 'react';

import * as Styled from './skeleton-headline.styles';
const { alignments, sizes } = Styled;

export type SkeletonHeadlineAlignment = keyof typeof alignments;
export type SkeletonHeadlineSize = keyof typeof sizes;
export type SkeletonHeadlineVariant = 'primary' | 'secondary';

export type SkeletonHeadlineProps = {
  /** Alignment of the skeleton headline text */
  alignment?: SkeletonHeadlineAlignment;
  /** Amount of headline rows */
  amountRows?: number;
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLDivElement>;
  /** The size on mobile screens or larger */
  size?: SkeletonHeadlineSize;
  /** The size on tablet screens or larger */
  sizeConfined?: SkeletonHeadlineSize;
  /** The size on desktop screens or larger */
  sizeWide?: SkeletonHeadlineSize;
  /** @deprecated: variant secondary is deprecated */
  variant?: SkeletonHeadlineVariant;
} & React.HTMLAttributes<HTMLDivElement>;
