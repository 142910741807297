import * as React from 'react';

import * as Styled from './divider.styles';
import type { DividerProps } from './divider.types';

export const Divider = React.forwardRef<HTMLHRElement, DividerProps>(
  ({ ariaHidden = true, className, ...props }, forwardedRef): JSX.Element => (
    <Styled.Divider
      aria-hidden={ariaHidden}
      className={className}
      data-xds="Divider"
      ref={forwardedRef}
      {...props}
    />
  )
);

Divider.displayName = 'Divider';
