import React from 'react';
import { useIntl } from 'react-intl';

import { Marker } from '@xing-com/marker';
import { trackingURL } from '@xing-com/xing-tracking';

import {
  StyledIllustrationNetwork,
  StyledSingleSelect,
  StyledUpsellContainer,
  StyledUpsellSingleSelect,
} from './products-menu-item.styles';

type ProductMenuItemProps = {
  link: {
    title: string;
    description?: string;
    href: string;
    tracking?: { sc_o: string; sc_o_PropActionOrigin?: string };
  };
  testId?: string;
  upsellBackground?: boolean;
  isBestseller?: boolean;
  is360Upsell?: boolean;
};

export const ProductsMenuItem: React.FC<ProductMenuItemProps> = ({
  link,
  testId,
  upsellBackground,
  isBestseller,
  is360Upsell,
}) => {
  const { formatMessage } = useIntl();
  const isUpsellStyle = upsellBackground || isBestseller || is360Upsell;
  const [to, setTo] = React.useState<string | undefined>(undefined);
  const trackingUrl = trackingURL(
    link.href,
    'tracking'
  )({ tracking: link.tracking });

  React.useEffect(() => {
    setTo(trackingUrl);
  }, [trackingUrl]);

  const Component = isUpsellStyle
    ? StyledUpsellSingleSelect
    : StyledSingleSelect;

  return (
    <Component
      data-testid={testId}
      // @ts-expect-error FIXME: SC6
      $colorSet={is360Upsell ? 'upsell360' : 'upsell'}
      description={link.description}
      target="_blank"
      to={to}
    >
      {isBestseller || is360Upsell ? (
        <StyledUpsellContainer>
          {link.title}
          {isBestseller ? (
            <Marker variant="strong">
              {formatMessage({
                id: 'MAINNAV_PRODUCTS_MENU_RECRUITING_360_UPSELL_BESTSELLER_BADGE',
              })}
            </Marker>
          ) : (
            <StyledIllustrationNetwork />
          )}
        </StyledUpsellContainer>
      ) : (
        link.title
      )}
    </Component>
  );
};
