import * as React from 'react';

import { useTracking } from '@xing-com/crate-communication-tracking';

export type PageViewTrackerProps = {
  trackingChannel?: string;
  pageName?: string;
};

export const PageViewTracker: React.VFC<PageViewTrackerProps> = ({
  trackingChannel,
  pageName,
}) => {
  const { track } = useTracking();

  React.useEffect(() => {
    if (trackingChannel && pageName) {
      track({
        type: 'pageview',
        channel: trackingChannel,
        page: pageName,
      });
    }
  }, [pageName, trackingChannel, track]);

  return <></>;
};
