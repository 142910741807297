import type { Configuration, Language, Translate } from '../types';
import { bottomNavigation } from './bottom-navigation';
import { bottomNavigationLoggedOut } from './bottom-navigation-logged-out';
import { loggedInFooter } from './footer';
import { headerLoggedIn } from './header-logged-in';
import { headerLoggedOut } from './header-logged-out';
import { legalFooter } from './legal-footer';
import { loggedOutFooter } from './loggedout-footer';
import { loggedOutlegalFooter } from './loggedout-legal-footer';
import { moreMenuDropdown } from './more-menu-dropdown';
import { settingsDropdown } from './settings-dropdown';
import { verticalNavigation } from './vertical-navigation';

export const v1: Configuration = (t: Translate, lang: Language) => ({
  navigation: {
    verticalNavigation: verticalNavigation(t, lang),
    headerLoggedIn: headerLoggedIn(t, lang),
    headerLoggedOut: headerLoggedOut(t, lang),
    settingsDropdown: settingsDropdown(t, lang),
    moreMenuDropdown: moreMenuDropdown(t, lang),
    loggedInFooter: loggedInFooter(t, lang),
    legalFooter: legalFooter(t, lang),
    bottomNavigation: bottomNavigation(t, lang),
    bottomNavigationLoggedOut: bottomNavigationLoggedOut(t, lang),

    loggedOutFooter: loggedOutFooter(t, lang),
    loggedOutlegalFooter: loggedOutlegalFooter(t, lang),
  },
  experiments: [],
});
