export const mapProfileSizeToBadgeSize = (
  size: string | undefined
): 'small' | 'medium' | 'large' | undefined => {
  switch (size) {
    case 'xxsmall':
    case 'xsmall':
    case 'small':
      return 'small';
      break;
    case 'medium':
    case 'large':
      return 'medium';
      break;
    case 'xlarge':
    case 'xxlarge':
    case 'xxxlarge':
    case 'xxxxlarge':
      return 'large';
      break;
    default:
      return undefined;
  }
};
