import type { HeaderLoggedOutConfig } from '@xing-com/platform-layout-header-logged-out';

import type { ConfigurationItem, Translate } from '../types';

export const headerLoggedOut: ConfigurationItem = (
  t: Translate
): HeaderLoggedOutConfig => {
  return [
    [
      'OnlyOnLarge',
      {
        children: {
          $meta: [
            [
              'NavItemHeaderLoggedOut',
              {
                item: {
                  key: 'jobs',
                  icon: { $component: 'IconJobs' },
                  activeIcon: { $component: 'IconJobsFilled' },
                  title: t('MAINNAV_JOBS_FIND'),
                  href: 'https://www.xing.com/',
                  tracking: {
                    sc_o: 'navigation_find_jobs',
                  },
                },
              },
            ],
          ],
        },
      },
    ],
    [
      'OnlyOnLarge',
      {
        children: {
          $meta: [
            [
              'NavItemHeaderLoggedOut',
              {
                item: {
                  key: 'companies',
                  icon: { $component: 'IconCompanies' },
                  activeIcon: { $component: 'IconCompaniesFilled' },
                  title: t('MAINNAV_COMPANIES'),
                  href: 'https://www.xing.com/companies',
                  tracking: {
                    sc_o: 'navigation_companies',
                  },
                },
              },
            ],
          ],
        },
      },
    ],
    [
      'OnlyOnLarge',
      {
        children: {
          $meta: [
            [
              'NavItemHeaderLoggedOut',
              {
                item: {
                  key: 'contacts',
                  activeItemValues: ['members'],
                  icon: { $component: 'IconContacts' },
                  activeIcon: { $component: 'IconContactsFilled' },
                  title: t('MAINNAV_NETWORK'),
                  href: 'https://www.xing.com/network',
                  tracking: {
                    sc_o: 'navigation_network',
                  },
                },
              },
            ],
          ],
        },
      },
    ],
    [
      'OnlyOnLarge',
      {
        children: {
          $meta: [
            [
              'NavItemHeaderLoggedOut',
              {
                item: {
                  activeItemValues: ['insights'],
                  key: 'disco',
                  icon: { $component: 'IconNews' },
                  activeIcon: { $component: 'IconNewsFilled' },
                  title: t('MAINNAV_INSIGHTS'),
                  href: 'https://www.xing.com/insights',
                  tracking: {
                    sc_o: 'navigation_insights',
                  },
                },
              },
            ],
          ],
        },
      },
    ],
    [
      'OnlyOnLarge',
      {
        children: {
          $meta: [
            [
              'BusinessSolutionsMenu',
              {
                item: {
                  key: 'business',
                  icon: { $component: 'IconOtherServices' },
                  title: t('MAINNAV_BUSINESS_SOLUTIONS'),
                  href: 'https://www.xing.com/business-solutions',
                },
                children: {
                  $meta: [
                    [
                      'BsMenuItem',
                      {
                        link: {
                          href: 'https://recruiting.xing.com',
                          title: t('MAINNAV_BUSINESS_SOLUTIONS_RECRUITING'),
                          tracking: {
                            sc_o: 'navigation_business_solutions_recruiting',
                          },
                        },
                      },
                    ],
                    [
                      'BsMenuItem',
                      {
                        link: {
                          href: 'https://werben.xing.com',
                          title: t('MAINNAV_BUSINESS_SOLUTIONS_ADVERTISING'),
                          tracking: {
                            sc_o: 'navigation_business_solution_advertise',
                          },
                        },
                      },
                    ],
                  ],
                },
              },
            ],
          ],
        },
      },
    ],
    [
      'ActivityCenterButton',
      {
        item: {
          key: 'activityCenter',
          title: t('MAINNAV_ACTIVITY_CENTER'),
          href: 'https://xing.com/activity?sc_o=navigation_activity',
        },
      },
    ],
  ];
};
