import times from 'lodash/times';
import * as React from 'react';

import { scale130, scale160 } from '@xing-com/tokens';

import { SkeletonSuperEllipse } from '..';
import type { SkeletonFacepileProps } from '..';
import * as Styled from './skeleton-facepile.styles';

export const SkeletonFacepile = React.forwardRef<
  HTMLDivElement,
  SkeletonFacepileProps
>(
  (
    { amount = 3, className, size = 'medium', ...props },
    forwardedRef
  ): JSX.Element => {
    const sizes = {
      small: scale130,
      medium: scale160,
    };

    return (
      <Styled.Container
        $size={size}
        className={className}
        data-xds="SkeletonContentSwitcher"
        ref={forwardedRef}
        {...props}
      >
        {times(amount, (index) => (
          <SkeletonSuperEllipse size={sizes[size]} key={index} />
        ))}
      </Styled.Container>
    );
  }
);

SkeletonFacepile.displayName = 'SkeletonFacepile';
