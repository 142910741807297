import * as React from 'react';

import * as Styled from './skeleton-profile-image.styles';
import type { SkeletonProfileImageProps } from './skeleton-profile-image.types';

export const SkeletonProfileImage = React.forwardRef<
  SVGElement,
  SkeletonProfileImageProps
>(
  (
    { className, size = 'medium', sizeConfined, sizeWide, ...props },
    forwardedRef
  ): JSX.Element => (
    <Styled.SuperEllipse
      $size={size}
      $sizeConfined={sizeConfined}
      $sizeWide={sizeWide}
      className={className}
      data-xds="SkeletonProfileImage"
      ref={forwardedRef}
      viewBox="0 0 256 256"
      {...props}
    >
      <g>
        <path d="M18.947 18.947C5.627 32.267 1 61.517 1 128s4.626 95.732 17.947 109.053C32.267 250.373 61.517 255 128 255s95.732-4.626 109.053-17.947C250.373 223.733 255 194.483 255 128s-4.626-95.732-17.947-109.053C223.733 5.627 194.483 1 128 1S32.268 5.626 18.947 18.947z" />
      </g>
    </Styled.SuperEllipse>
  )
);

SkeletonProfileImage.displayName = 'SkeletonProfileImage';
