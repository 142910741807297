import type * as React from 'react';

import { usePopOver } from '@xing-com/pop-over';

import { StyledMenu } from './business-solutions-menu.styles';
import {
  StyledIconWithBadge,
  StyledTitle,
  StyledLink,
} from './nav-item-header-logged-out.styles';

type BusinessSolutionsMenuProps = React.PropsWithChildren<{
  item: {
    href?: string;
    icon: React.ElementType;
    key: string;
    title: string;
  };
}>;

export const BusinessSolutionsMenu: React.FC<BusinessSolutionsMenuProps> = ({
  item,
  children,
}) => {
  const popOver = usePopOver();

  return (
    <div>
      <StyledLink
        to={item.href}
        $isActive={false}
        data-testid="frame-vnav-business-solutions-menu"
        onClick={(event: React.MouseEvent) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          let target = event.target as HTMLElement;
          while (target && target.tagName !== 'A') {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            target = target.parentElement as HTMLElement;
          }
          if (target === event.currentTarget) {
            event.preventDefault();
          }
          popOver.togglePopOver();
        }}
        onMouseEnter={popOver.handleShow}
        onMouseLeave={popOver.handleHide}
      >
        <StyledIconWithBadge icon={item.icon} />
        <StyledTitle>{item.title}</StyledTitle>
        <StyledMenu
          data-testid="BusinessSolutionsDropDown"
          isInFlow
          isStatic
          dimmerTitle="close menu"
          onOutsideClick={popOver.handleHide}
          show={popOver.show}
          triggerRef={popOver.triggerRef}
        >
          {children}
        </StyledMenu>
      </StyledLink>
    </div>
  );
};
