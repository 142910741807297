import styled from 'styled-components';

import { zIndexElevatedLayer4 } from '@xing-com/layout-tokens';
import {
  cornerRadiusXL,
  dropShadowM,
  scale005,
  spaceXL,
  xdlColorBackground,
  xdlColorModalBorder,
} from '@xing-com/tokens';

// *** Components ***
export const Wrapper = styled.div`
  background: ${xdlColorBackground};
  border-color: ${xdlColorModalBorder};
  border-radius: ${cornerRadiusXL};
  border-style: solid;
  border-width: ${scale005};
  box-shadow: ${dropShadowM};
  padding-bottom: ${spaceXL};
  padding-left: ${spaceXL};
  padding-right: ${spaceXL};
  padding-top: ${spaceXL};
  position: absolute;
  z-index: ${zIndexElevatedLayer4};
`;
