import * as React from 'react';

import * as Styled from './badge.styles';
import type { BadgeProps } from './badge.types';

export const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      children,
      className,
      size = 'medium',
      sizeConfined,
      sizeWide,
      sizeXWide,
      ...props
    },
    forwardedRef
  ): JSX.Element => (
    <Styled.Badge
      $size={size}
      $sizeConfined={sizeConfined}
      $sizeWide={sizeWide}
      $sizeXWide={sizeXWide}
      className={className}
      data-xds="Badge"
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Styled.Badge>
  )
);

Badge.displayName = 'Badge';
