import type * as React from 'react';
import styled, { css } from 'styled-components';

import {
  mediaConfined,
  mediaSmallOnly,
  mediaSmallOrTiny,
  mediaConfinedOnly,
} from '@xing-com/layout-tokens';
import { BottomBar } from '@xing-com/platform-layout-bottom-bar';
import { Footer, LegalFooter } from '@xing-com/platform-layout-footer';
import { HeaderLoggedOut } from '@xing-com/platform-layout-header-logged-out';
import { CenterContainer } from '@xing-com/platform-layout-view-wrapper';
import {
  scale100,
  spaceL,
  spaceXXL,
  xdlColorBackground,
} from '@xing-com/tokens';
import { GridContainer } from '@xing-com/xing-grid';

const StyledLoggedOutViewWrapper = styled.div`
  background-color: ${xdlColorBackground};
`;

const StyledFooter = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${spaceXXL} 0;

  @media ${mediaSmallOnly} {
    padding-left: ${spaceL};
  }
`;

const StyledSection = styled.section`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  /* This flex item doesn't seem to grow up to its parent's width without a given initial width */
  width: 0%;

  @media ${mediaSmallOnly} {
    width: 100%;
  }

  @media ${mediaConfined} {
    padding-bottom: 0;
  }

  /* 740px - 1112px */
  @media ${mediaConfinedOnly} {
    justify-content: center;
  }
`;

const StyledMain = styled.main<{ $gridless: boolean }>`
  display: flex;
  justify-content: center;

  ${({ $gridless }) =>
    $gridless
      ? css`
          @media ${mediaSmallOrTiny} {
            margin: 0 ${scale100};
            display: initial; // no flex when mobile gridless
          }
        `
      : ''}
`;

const StyledCenterContainer = styled(CenterContainer)`
  @media ${mediaConfined} {
    ${({ type }) => type === 'fullScreen' && `width: 100%;`}
  }
`;

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  @media ${mediaConfinedOnly} {
    max-width: 800px;
  }
`;

type LoggedoutViewInnerWrappersProps = {
  children: React.ReactNode;
  contentClassName?: string;
  gridClassName?: string;
  type?: 'standard' | 'wide';
  gridless?: boolean;
};

export const LoggedoutViewInnerWrappers: React.FC<
  LoggedoutViewInnerWrappersProps
> = ({
  children,
  contentClassName,
  gridClassName,
  type = 'standard',
  gridless = false,
}) => {
  return (
    <StyledCenterContainer type={type}>
      <StyledSection>
        <StyledContent className={contentClassName}>
          <StyledMain $gridless={gridless} id="content">
            {gridless ? (
              children
            ) : (
              <GridContainer className={gridClassName} type={type}>
                {children}
              </GridContainer>
            )}
          </StyledMain>
        </StyledContent>
      </StyledSection>
    </StyledCenterContainer>
  );
};

type LoggedoutViewProps = LoggedoutViewInnerWrappersProps & {
  children: React.ReactNode;
  className?: string;
  hideLoginButton?: boolean;
  hideQuickLinks?: boolean;
  hideSignupButton?: boolean;
  homeLink?: string;
  loginLink?: string;
  signupLink?: string;
  testId?: string;
};

export const LoggedoutView: React.FC<LoggedoutViewProps> = ({
  children,
  className,
  contentClassName,
  gridClassName,
  hideLoginButton,
  hideQuickLinks = false,
  hideSignupButton,
  homeLink = '/',
  loginLink,
  signupLink,
  testId,
  type = 'standard',
  gridless = false,
}) => {
  return (
    <StyledLoggedOutViewWrapper
      className={className}
      data-qa={testId && `core-${testId}`}
    >
      <HeaderLoggedOut
        {...{
          hideLoginButton,
          hideSignupButton,
          homeLink,
          loginLink,
          signupLink,
        }}
      />
      <LoggedoutViewInnerWrappers
        {...{
          children,
          contentClassName,
          gridClassName,
          type,
          gridless,
        }}
      />
      {hideQuickLinks ? (
        <StyledFooter>
          <LegalFooter />
        </StyledFooter>
      ) : (
        <Footer homeLink={homeLink} />
      )}
      <BottomBar />
    </StyledLoggedOutViewWrapper>
  );
};
