import type * as React from 'react';

import { XingletLoader } from '@xing-com/crate-xinglet';

export const NoAccessPage: React.FC = () => (
  <XingletLoader
    name="@xing-com/crate-communication-error-pages"
    errorType="NoAccess"
  />
);
