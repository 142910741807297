/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const FallbackImageCompany = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 128"
    data-xds="FallbackImageCompany"
    {...props}
  >
    <g clipPath="url(#clip0_9436_676)">
      <path fill="#F4F4F5" d="M0 0h128v128H0z" />
      <path
        fill="#ACB8CA"
        fillRule="evenodd"
        d="M77.27 14.287h51.012v8.96H128v2.983H66.098V128h-4.196V58.754H0v-4.197h61.902V22.042H77.27zm7.714 26.631h-4.197V128h4.197zm14.688 0h4.197V128h-4.197zm23.082 0h-4.197V128h4.197zM14.689 73.443h32.524v4.196H14.688zm32.524 17.836H14.688v4.196h32.525zm-32.525 17.836h32.525v4.196H14.688z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_9436_676">
        <path fill="#fff" d="M0 0h128v128H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default FallbackImageCompany;
