import { useContext, useEffect, useState } from 'react';
import * as React from 'react';

import devNote from '@xing-com/dev-note';
import { IconNotificationCenter } from '@xing-com/icons';

import { RingingBell } from './ringing-bell';

const IconComponentContext = React.createContext<{ count: number }>({
  count: 0,
});

export const IconComponentContextProvider = IconComponentContext.Provider;

/**
 * If NC counts are different and present, bell should ring. Known blind spot:
 * the amount of new notifications are the same as the stored ones. Based on
 * the counted, we can't tell if they're new or the same ones.
 */
const checkHasNewNotifications = (count: number): boolean => {
  if (count <= 0) return false;

  try {
    const countString = count.toString();
    const storedCount = global.sessionStorage.getItem('nc-count');

    if (storedCount !== countString) {
      global.sessionStorage.setItem('nc-count', countString);
      return true;
    }

    return false;
  } catch (error) {
    devNote.warn(error);
    return false;
  }
};

export const NotificationCenterIcon: React.FC<{ className?: string }> = (
  props
) => {
  const { count } = useContext(IconComponentContext);

  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  useEffect(() => {
    setHasNewNotifications(checkHasNewNotifications(count));
  }, [count]);

  const BellIcon = hasNewNotifications ? RingingBell : IconNotificationCenter;

  return <BellIcon {...props} />;
};
