import styled, { css } from 'styled-components';

import { DotLoadingIndicator } from '@xing-com/dot-loading-indicator';
import {
  mediaConfined,
  mediaWide,
  zIndexContentLayer2,
  zIndexBackgroundLayer1,
} from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import {
  motionEasingEnter,
  motionTimeS,
  scale130,
  scale150,
  scale160,
  spaceS,
  xdlColorDisabledStrong,
  xdlColorError,
  xdlColorText,
} from '@xing-com/tokens';

import type {
  TextButtonFontWeights,
  TextButtonSizes,
} from './text-button.types';

type TextButtonTransientProps = {
  $ellipsis?: boolean;
  $error?: boolean;
  $fontWeight?: TextButtonFontWeights;
  $iconOnly: boolean;
  $size?: TextButtonSizes;
  $sizeConfined?: TextButtonSizes;
  $sizeWide?: TextButtonSizes;
};

type IconContainerTransientProps = {
  $iconAfter?: boolean;
  $iconSize?: 18 | 24;
};

type LoadingTransientProps = {
  $size?: TextButtonSizes;
  $sizeConfined?: TextButtonSizes;
  $sizeWide?: TextButtonSizes;
};

type TextTransientProps = {
  $ellipsis?: boolean;
  $loading?: boolean;
};

type InnerContentTransientProps = {
  $ellipsis?: boolean;
};

// *** Base ***
const baseButton = css`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${xdlColorText};
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  vertical-align: top;
  z-index: ${zIndexContentLayer2};

  &::after {
    box-sizing: border-box;
    content: '';
    cursor: pointer;
    height: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    transition-duration: ${motionTimeS};
    transition-property: transform, background-color;
    transition-timing-function: ${motionEasingEnter};
    width: 100%;
    z-index: ${zIndexBackgroundLayer1};
  }

  &:active,
  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: none;
  }

  &:disabled {
    color: ${xdlColorDisabledStrong};
    cursor: default;
  }

  &:disabled::after {
    cursor: default;
    user-select: none;
  }

  &:visited {
    color: ${xdlColorText};
  }
`;

// *** Sizes ***
export const sizes = {
  small: css`
    font-size: 14px;
    height: ${scale130};
    min-width: ${scale130};
  `,
  medium: css`
    font-size: 16px;
    height: ${scale150};
    min-width: ${scale150};
  `,
  large: css`
    font-size: 18px;
    height: ${scale160};
    min-width: ${scale160};
  `,
};

// *** Font weight ***
export const fontWeights = {
  regular: css`
    color: ${xdlColorText};
    font-weight: 400;
    padding: 0;
  `,
};

// *** States ***
const errorState = css`
  color: ${xdlColorError};
`;

const iconAfterStyle = css`
  margin-inline-end: 0;
  margin-inline-start: ${spaceS};
  order: 1;
`;

// *** Icon ***
export const IconContainer = styled.span<IconContainerTransientProps>`
  ${({ $iconSize }) =>
    $iconSize &&
    css`
      height: ${$iconSize}px;
      width: ${$iconSize}px;
    `};
  margin-inline-end: ${spaceS};

  ${({ $iconAfter }) => ($iconAfter ? iconAfterStyle : null)};
`;

const iconOnly = css`
  padding: 0;

  & ${IconContainer} {
    margin: 0;
  }
`;

export const TextButton = styled.button<TextButtonTransientProps>`
  ${baseButton};

  ${({ $size }) => $size && sizes[$size]};

  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      overflow: hidden;
    `};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};

  ${({ $fontWeight }) => $fontWeight && fontWeights[$fontWeight]};

  ${({ $error }) => $error && errorState};

  ${({ $iconOnly }) => $iconOnly && iconOnly};
`;

export const RouterTextButton = styled(Link)<TextButtonTransientProps>`
  ${baseButton};

  ${({ $size }) => $size && sizes[$size]};

  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      overflow: hidden;
    `};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]}
      }
    `};

  ${({ $fontWeight }) => $fontWeight && fontWeights[$fontWeight]};

  ${({ $error }) => $error && errorState};

  ${({ $iconOnly }) => $iconOnly && iconOnly};
`;

export const InnerContent = styled.div<InnerContentTransientProps>`
  align-items: center;
  display: flex;
  justify-content: center;

  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
    `};
`;

const smallLoading = css`
  transform: translate(-50%, -50%) scale(0.6);
`;

const mediumLoading = css`
  transform: translate(-50%, -50%) scale(0.7);
`;

export const Loading = styled(DotLoadingIndicator)<LoadingTransientProps>`
  inset-block-start: 50%;
  inset-inline-start: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  ${({ $size }) => $size === 'small' && smallLoading};
  ${({ $size }) => $size === 'medium' && mediumLoading};

  ${({ $sizeConfined }) =>
    $sizeConfined === 'small' &&
    css`
      @media ${mediaConfined} {
        ${smallLoading}
      }
    `};

  ${({ $sizeConfined }) =>
    $sizeConfined === 'medium' &&
    css`
      @media ${mediaConfined} {
        ${mediumLoading}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide === 'small' &&
    css`
      @media ${mediaWide} {
        ${smallLoading}
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide === 'medium' &&
    css`
      @media ${mediaWide} {
        ${mediumLoading}
      }
    `};
`;

export const Text = styled.span<TextTransientProps>`
  display: inline-flex;

  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

  ${({ $loading }) =>
    $loading &&
    css`
      visibility: hidden;
    `};
`;
