import styled from 'styled-components';

import {
  CookieConsentModal,
  CookieConsentProvider,
} from '@xing-com/cookie-consent';
import { CookieConsentUsercentrics } from '@xing-com/cookie-consent-usercentrics';
import type { RouteMeta } from '@xing-com/crate-communication-data-layer';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import {
  useHost,
  useMetadata,
  useXinglet,
  XingletLoader,
} from '@xing-com/crate-xinglet';
import { useExperiment, ActiveItemContext, useViewerData } from '@xing-com/hub';
import { SkeletonRectangle } from '@xing-com/skeleton';
import { cornerRadiusL, spaceXL } from '@xing-com/tokens';

import { NoAccessPage, Generic } from './error-components';
import { LayoutSwitcher } from './layout-switcher';
import { PageViewTracker } from './page-view-tracker/page-view-tracker';
import { Zerofier } from './zerofier';

declare global {
  // eslint-disable-next-line no-var
  var DD_RUM:
    | { setGlobalContextProperty: (key: string, value: any) => void }
    | undefined;
}

export type RouteProps = {
  path?: string;
  data: RouteMeta;
};

const StyledSkeletonRectangle = styled(SkeletonRectangle)`
  max-width: 596px;
  margin: ${spaceXL} auto;
`;

export const RouteXinglet: React.FC<{
  name: string;
  pageName?: string;
  trackingChannel?: string;
}> = ({ name, pageName, trackingChannel }) => {
  const { ssr } = useMetadata(name);
  const { loading: loadingXinglet } = useXinglet(name);
  const { loading: loadingViewerData } = useViewerData();

  if (loadingXinglet || (!ssr && loadingViewerData)) {
    const skeletonHeights = ['200px', '200px', '124px', '324px', '124px'];
    return (
      <>
        {skeletonHeights.map((height, index) => (
          <StyledSkeletonRectangle
            key={index}
            height={height}
            cornerRadius={cornerRadiusL}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <PageViewTracker pageName={pageName} trackingChannel={trackingChannel} />
      <Zerofier />
      <XingletLoader name={name} key={name} error={<Generic />} />
    </>
  );
};

export const Route: React.VFC<RouteProps> = ({ data }) => {
  const isOldBanner = useExperiment('CMP-141') === 'A';

  const {
    layout: layoutLoggedOut,
    layoutLoggedIn,
    activeItem,
    visibleTo,
    xinglet: xingletName,
    pageName,
    trackingChannel,
    loginDestination,
    loginTracking,
    disableCookieConsentBanner,
  } = data;

  // Set xinglet as global context for Datadog RUM events
  if (globalThis.DD_RUM) {
    globalThis.DD_RUM.setGlobalContextProperty('xinglet', {
      name: xingletName.replace('@xing-com/crate-', ''),
      pageName,
    });
  }

  const loginState = useLoginState();
  const host = useHost();

  const layout = loginState.isLoggedIn ? layoutLoggedIn : layoutLoggedOut;

  // available for logged in users only, redirect to login page
  if (loginState.isLoggedOut && !visibleTo.loggedOut) {
    return host.redirectToLogin();
  }
  if (loginState.isSoftLoggedIn && !visibleTo.softLoggedIn) {
    return host.redirectToLogin();
  }

  // path does not go to any xinglet (logged in + logged out)
  if (!visibleTo.loggedIn && !visibleTo.loggedOut) {
    return <NoAccessPage />;
  }
  // available for logged out users only
  if (loginState.isLoggedIn && !visibleTo.loggedIn) {
    return <NoAccessPage />;
  }

  if (host.isServer && loginState.isLoggedOut && data.cacheMaxAge) {
    host.setHeader(
      'Cache-Control',
      `max-age=0, s-maxage=${data.cacheMaxAge}, must-revalidate`
    );
  }

  return (
    <CookieConsentProvider>
      {isOldBanner ? (
        <CookieConsentModal disableBanner={disableCookieConsentBanner} />
      ) : (
        <CookieConsentUsercentrics disableBanner={disableCookieConsentBanner} />
      )}
      <ActiveItemContext.Provider value={activeItem}>
        <LayoutSwitcher
          {...{ layout, loginState, loginDestination, loginTracking }}
        >
          <RouteXinglet
            name={xingletName}
            pageName={pageName}
            trackingChannel={trackingChannel}
          />
        </LayoutSwitcher>
      </ActiveItemContext.Provider>
    </CookieConsentProvider>
  );
};
