import styled from 'styled-components';

import { scale060, scale110 } from '@xing-com/tokens';

import { SkeletonCircle, SkeletonRectangle } from '../';

// *** Components ***
export const Container = styled.div`
  align-items: center;
  display: flex;
  height: ${scale110};
  position: relative;
  width: 100%;
`;

export const Slider = styled(SkeletonRectangle)`
  margin-inline-start: ${scale060};
`;

export const Handle = styled(SkeletonCircle)`
  inset-inline-start: 20%;
  position: absolute;
`;
