import * as React from 'react';

import { useExperiment } from '@xing-com/hub';

export type SplitKitSwitchProps = {
  experiment: string;
  cases: Record<string, React.ReactNode>;
};

/**
 * Similar to the Feature Switch, with this, you can show different
 * elements based on the test case
 */
export const SplitKitSwitch: React.FC<SplitKitSwitchProps> = ({
  experiment,
  cases,
}) => {
  const testCase = useExperiment(experiment, 'A');

  return <React.Fragment>{cases[testCase]}</React.Fragment>;
};
