import type * as React from 'react';

import * as Styled from './icon-button.styles';
const { sizes } = Styled;

export type IconButtonSizes = keyof typeof sizes;

export type IconButtonProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** Disable the TextButton */
  disabled?: boolean;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLButtonElement | HTMLAnchorElement>;
  /** The href, if it should be an anchor */
  href?: string;
  /** Hand over an icon component for the IconButton */
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  /** @deprecated Please use the "ref" property instead */
  innerRef?: React.RefObject<HTMLElement>;
  /** Loading state of the TextButton */
  loading?: boolean;
  /** @callback */
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  /** The size on mobile screens or larger */
  size?: IconButtonSizes;
  /** The size on tablet screens or larger */
  sizeConfined?: IconButtonSizes;
  /** The size on desktop screens or larger */
  sizeWide?: IconButtonSizes;
  /** The to, if it should be an anchor */
  to?: string;
  /** The type of button */
  type?: 'button' | 'submit' | 'reset';
} & React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>;
