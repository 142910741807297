import type * as React from 'react';
import styled from 'styled-components';

import {
  mediaConfinedNavlessOnly,
  mediaSmallOrTiny,
} from '@xing-com/layout-tokens';
import { scale090, spaceL } from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

export const FooterSection: React.FC<{
  title: string;
  id: string;
  children: React.ReactNode;
}> = ({ title, children }) => (
  <LinkGroup>
    <Headline size="small" as="p">
      {title}
    </Headline>
    <FooterNav>{children}</FooterNav>
  </LinkGroup>
);

const LinkGroup = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1 0 0;
`;

const FooterNav = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  @media ${mediaSmallOrTiny} {
    padding-left: ${spaceL};
    padding-right: ${spaceL};
  }

  @media ${mediaConfinedNavlessOnly} {
    padding-left: ${scale090};
    padding-right: ${scale090};
  }
`;
