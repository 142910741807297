import styled from 'styled-components';

import { scale030, xdlColorNotification } from '@xing-com/tokens';

// *** Components ***
export const MiniBadge = styled.span`
  background: ${xdlColorNotification};
  border-radius: 50%;
  display: inline-flex;
  height: ${scale030};
  width: ${scale030};
`;
