import styled, { keyframes } from 'styled-components';

import { Bell } from './bell';
import { Clapper } from './clapper';

const ringingBellDelay = '1s';
const ringingBellDuration = '1s';

const bellKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  13% {
    transform: rotate(16deg);
  }
  33% {
    transform: rotate(-10deg);
  }
  36% {
    transform: rotate(-11deg);
  }
  40% {
    transform: rotate(-11deg);
  }
  55% {
    transform: rotate(11deg);
  }
  71% {
    transform: rotate(-4deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const StyledIconWrapper = styled.div`
  animation-delay: ${ringingBellDelay};
  animation-duration: ${ringingBellDuration};
  animation-name: ${bellKeyframes};
  animation-timing-function: ease-in-out;
  transform-origin: top center;
  text-align: center;
`;

const clapperKeyframes = keyframes`
  0% {
    transform: translateX(0);
  }
  13% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(-3px);
  }
  55% {
    transform: translateX(3px);
  }
  65% {
    transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
  }
  95% {
    transform: translateX(-1px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const StyledClapper = styled(Clapper)`
  animation-delay: ${ringingBellDelay};
  animation-duration: ${ringingBellDuration};
  animation-name: ${clapperKeyframes};
  animation-timing-function: ease-in;
  position: absolute;
  height: 100%;
`;

export const StyledBell = styled(Bell)`
  height: 100%;
`;
