import type { PropsWithChildren, FC } from 'react';
import { useEffect, useState, useRef } from 'react';

import { BrowserBarOpenContext } from '@xing-com/hub';

export const BrowserBarOpenModule: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isBrowserBarOpen, setIsBrowserBarOpen] = useState(true);
  const prevInnerHeight = useRef(0);

  useEffect(() => {
    // we need to gather the render size on component mount
    prevInnerHeight.current = global.innerHeight;
  }, []);

  useEffect(() => {
    const onResize = (): void => {
      const isScrollable =
        global.document.documentElement.scrollHeight >
        global.document.documentElement.clientHeight;

      const heightDiff = global.innerHeight - prevInnerHeight.current;

      if (Math.abs(heightDiff) > 10) {
        setIsBrowserBarOpen(!isScrollable || heightDiff < 0);
        prevInnerHeight.current = global.innerHeight;
      }
    };

    global.addEventListener('resize', onResize);
    return () => global.removeEventListener('resize', onResize);
  }, []);

  return (
    <BrowserBarOpenContext.Provider value={isBrowserBarOpen}>
      {children}
    </BrowserBarOpenContext.Provider>
  );
};
