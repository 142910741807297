import styled, { css, keyframes } from 'styled-components';

import {
  motionEasingStandard,
  motionTimeM,
  motionTimeXS,
  scale040,
  spaceS,
  xdlColorText,
} from '@xing-com/tokens';

// *** Animations ***
const dotAnimation1 = keyframes`
  0%    { transform: scale(1); }   /* 0ms */
  25.5% { transform: scale(1); }   /* 240ms */
  51.1% { transform: scale(0.6); } /* 480ms */
  76.6% { transform: scale(1); }   /* 720ms */
  100%  { transform: scale(1); }   /* 940ms */
`;

const dotAnimation2 = keyframes`
  0%    { transform: scale(1); }   /* 0ms */
  37.2% { transform: scale(1); }   /* 350ms */
  62.8% { transform: scale(0.6); } /* 590ms */
  88.3% { transform: scale(1); }   /* 830ms */
  100%  { transform: scale(1); }   /* 940ms */
`;

const dotAnimation3 = keyframes`
  0%    { transform: scale(1); }   /* 0ms */
  48.9% { transform: scale(1); }   /* 460ms */
  74.5% { transform: scale(0.6); } /* 700ms */
  100%  { transform: scale(1); }   /* 940ms */
`;

// *** Base ***
const baseDotLoadingIndicator = css`
  align-items: center;
  display: flex;
  height: calc(${scale040} * 1.2); /* 1.2 is max. scale transformation */
`;

const baseDot = css`
  animation-duration: calc(3 * ${motionTimeM} + 2 * ${motionTimeXS});
  animation-iteration-count: infinite;
  animation-timing-function: ${motionEasingStandard};
  background-color: ${xdlColorText};
  border-radius: 100%;
  height: ${scale040};
  min-width: ${scale040}; /* needed for IE 11 */
  width: ${scale040};
`;

// *** Components ***
export const DotLoadingIndicator = styled.div`
  ${baseDotLoadingIndicator};
`;

export const Dot1 = styled.div`
  ${baseDot};
  animation-name: ${dotAnimation1};
  margin-right: ${spaceS};
`;

export const Dot2 = styled.div`
  ${baseDot};
  animation-name: ${dotAnimation2};
`;

export const Dot3 = styled.div`
  ${baseDot};
  animation-name: ${dotAnimation3};
  margin-left: ${spaceS};
`;
