import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaConfined } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import {
  scale150,
  spaceM,
  spaceS,
  xdlColorHover,
  xdlColorControlFillTertiary,
  xdlColorText,
} from '@xing-com/tokens';

export const StyledLink = styled(Link)`
  margin-left: ${spaceS};
  margin-right: ${spaceS};

  &:hover {
    text-decoration: none;
  }
`;

export const NotificationCenterButton = styled(Button)`
  border-radius: ${scale150};

  &:hover {
    background-color: ${xdlColorHover};
  }

  &:active {
    background-color: ${xdlColorControlFillTertiary};
    border-color: ${xdlColorControlFillTertiary};
    color: ${xdlColorText};
  }

  @media ${mediaConfined} {
    display: inline-flex;
    height: 56px;
    padding-left: ${spaceM};
    padding-right: ${spaceM};
  }

  /* InnerContent */
  & > div {
    flex-direction: column;

    & > span:nth-child(2) {
      display: flex;
    }
    & > span {
      /* Icon */
      &:nth-child(1) {
        margin-right: 0;
      }
      /* Text */
      &:nth-child(2) {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
`;
