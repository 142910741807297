import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';
import { Dimmer as XdlDimmer } from '@xing-com/dimmer';
import { mediaWide, zIndexElevatedLayer5 } from '@xing-com/layout-tokens';
import {
  cornerRadiusXL,
  motionEasingEnter,
  motionEasingLeave,
  motionTimeL,
  motionTimeS,
  scale005,
  spaceL,
  spaceXL,
  spaceXXL,
  xdlColorBackground,
  xdlColorModalBorder,
} from '@xing-com/tokens';

type PopUpWindowTransientProps = {
  $noPadding?: boolean;
};

export const dimmerDuration = motionTimeL;
export const dimmerTransitionName = 'fade';
export const popUpWindowDuration = motionTimeL;
export const popUpWindowTransitionName = 'slideFade';

// *** Wrapper  ***
export const Wrapper = styled.div`
  inset-block-start: 50%;
  inset-inline-start: 50%;
  outline: 0;
  position: fixed;
  transform: translate(-50%, -50%);
  width: inherit;
  z-index: ${zIndexElevatedLayer5};
`;

// *** Components ***
export const PopUpWindow = styled.div<PopUpWindowTransientProps>`
  background-color: ${xdlColorBackground};
  border-color: ${xdlColorModalBorder};
  border-radius: ${cornerRadiusXL};
  border-style: solid;
  border-width: ${scale005};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-height: 90vh;
  overflow: hidden;
  padding-block-end: ${spaceXL};
  padding-block-start: ${spaceXL};
  padding-inline-end: ${spaceL};
  padding-inline-start: ${spaceL};
  position: relative;
  width: 320px;

  @media ${mediaWide} {
    min-height: 400px;
    padding: ${spaceXXL};
    width: 600px;
  }

  ${({ $noPadding }) =>
    $noPadding &&
    css`
      && {
        padding: 0;
      }
    `};

  /* Pop Up Window Animation */
  &.${popUpWindowTransitionName}-enter {
    opacity: 0;
    transform: translateY(${spaceXL});
  }

  &.${popUpWindowTransitionName}-appear {
    opacity: 0;
    transform: translateY(${spaceXL});
  }

  &.${popUpWindowTransitionName}-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: ${motionTimeS}, ${motionTimeL};
    transition-property: opacity, transform;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${popUpWindowTransitionName}-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: ${motionTimeS}, ${motionTimeL};
    transition-property: opacity, transform;
    transition-timing-function: ${motionEasingEnter}, ${motionEasingEnter};
  }

  &.${popUpWindowTransitionName}-enter-done {
    opacity: 1;
    transform: translateY(0);
  }

  &.${popUpWindowTransitionName}-appear-done {
    opacity: 1;
    transform: translateY(0);
  }

  &.${popUpWindowTransitionName}-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.${popUpWindowTransitionName}-exit-active {
    opacity: 0;
    transform: translateY(${spaceXL});
    transition-duration: ${motionTimeL}, ${motionTimeL};
    transition-property: opacity, transform;
    transition-timing-function: ${motionEasingLeave}, ${motionEasingLeave};
  }

  &.${popUpWindowTransitionName}-exit-done {
    opacity: 0;
    transform: translateY(${spaceXL});
  }
`;

export const CloseButton = styled(TextButton)`
  inset-block-start: 0;
  inset-inline-end: 0;
  position: absolute;
`;

export const Dimmer = styled(XdlDimmer)`
  /* Dimmer Animation */
  &.${dimmerTransitionName}-enter {
    opacity: 0;
  }

  &.${dimmerTransitionName}-appear {
    opacity: 0;
  }

  &.${dimmerTransitionName}-enter-active {
    opacity: 60%;
    transition-duration: ${dimmerDuration};
    transition-property: opacity;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${dimmerTransitionName}-appear-active {
    opacity: 60%;
    transition-duration: ${dimmerDuration};
    transition-property: opacity;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${dimmerTransitionName}-enter-done {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-appear-done {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-exit {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-exit-active {
    opacity: 0;
    transition-duration: ${dimmerDuration};
    transition-property: opacity;
    transition-timing-function: ${motionEasingLeave};
  }

  &.${dimmerTransitionName}-exit-done {
    opacity: 0;
  }
`;
