/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconBusinessSolutionsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconBusinessSolutionsFilled"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2 17V3h20v14h-8v2h2v2H8v-2h2v-2zM19 6h-5v1.5h2.416l-4.36 4L9.46 8.819l-5.167 4.224.95 1.161 4.098-3.35 2.65 2.74L17.5 8.543V11H19z"
    />
  </svg>
);
export default IconBusinessSolutionsFilled;
