/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const FallbackImageAvatar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 128"
    data-xds="FallbackImageAvatar"
    {...props}
  >
    <path fill="#E8D5FF" d="M0 0h128v128H0z" />
    <path
      fill="#1D2124"
      d="m66.5 71.72-5-.07c0-.18.26-18.3 0-35.87-.09-6.53-2.11-11.18-5.98-13.82-9.73-6.63-28.26.26-28.45.33l-1.77-4.68c.86-.33 21.13-7.87 33.02.21 5.31 3.61 8.06 9.62 8.18 17.88.26 17.64 0 35.84 0 36.02M82.98 56.31a2.5 2.5 0 0 1-2.5-2.5v-6.13a2.5 2.5 0 0 1 5 0v6.13a2.5 2.5 0 0 1-2.5 2.5"
    />
    <path
      fill="#1D2124"
      d="M45.17 56.31a2.5 2.5 0 0 1-2.5-2.5v-6.13a2.5 2.5 0 0 1 5 0v6.13a2.5 2.5 0 0 1-2.5 2.5M64.1 93.5c-9.71 0-18.82-3.77-25.66-10.61l3.54-3.54c5.9 5.9 13.76 9.15 22.13 9.15s16.23-3.25 22.13-9.15l3.54 3.54C82.92 89.73 73.81 93.5 64.1 93.5"
    />
  </svg>
);
export default FallbackImageAvatar;
