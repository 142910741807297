import * as React from 'react';
import { useIntl } from 'react-intl';

import type { XingLogoVariant } from '@xing-com/xing-logo';
import { trackingURL } from '@xing-com/xing-tracking';

import { LogoSvg } from './logo-svg';
import { StyledLink } from './logo.styles';

const A11Y_LOGO_KEY = 'A11Y_LOGO';

export type LogoProps = {
  butterfly?: boolean;
  className?: string;
  homeLink?: string;
  dataQa?: string;
  variant?: XingLogoVariant;
  size?: number;
};

const Logo: React.FC<LogoProps> = ({
  butterfly = false,
  className,
  homeLink = '/',
  dataQa,
  variant,
  size,
}) => {
  const intl = useIntl();
  const [to, setTo] = React.useState<string | undefined>(undefined);

  const trackingUrl = trackingURL(
    homeLink,
    'tracking'
  )({ tracking: { sc_o: 'navigation_header_logo' } });

  React.useEffect(() => {
    setTo(trackingUrl);
  }, [trackingUrl]);

  return (
    <StyledLink
      {...(homeLink && { to })}
      title={intl.formatMessage({ id: A11Y_LOGO_KEY })}
      className={className}
      data-qa={dataQa}
    >
      <LogoSvg butterfly={butterfly} variant={variant} size={size} />
    </StyledLink>
  );
};

export { Logo };
