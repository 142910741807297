import { useContext, useEffect, useState } from 'react';
import * as React from 'react';

import devNote from '@xing-com/dev-note';
import { IconWithBadge } from '@xing-com/platform-layout-icon-with-badge';

import { RingingBell } from './ringing-bell';

type IconComponentContextType = {
  isActive: boolean;
  icon: React.ComponentType;
  activeIcon: React.ComponentType;
  count: number;
};

const IconComponentContext = React.createContext<IconComponentContextType>({
  isActive: false,
  icon: React.Fragment,
  activeIcon: React.Fragment,
  count: 0,
});

export const IconComponentContextProvider = IconComponentContext.Provider;

const checkHasNewNotifications = (count: number): boolean => {
  if (count <= 0) {
    return false;
  }

  try {
    const countString = count.toString();
    const storedCount = global.sessionStorage.getItem('nc-count');
    const storedNumber = Number(storedCount);

    // Set default state (or overwrite any wrong value)
    if (storedCount !== countString) {
      global.sessionStorage.setItem('nc-count', countString);
    }

    // Known blind spot: the amount of new notifications are the same as the stored ones.
    // Based on the counted, we can't tell if they're new or the same ones.
    if (count === storedNumber) {
      return false;
    }

    // If NC counts are different, bell should ring
    return true;
  } catch (error) {
    devNote.warn(error);
    return false;
  }
};

export const IconComponent: React.FC = () => {
  const contextValue = useContext(IconComponentContext);
  const { isActive, icon, activeIcon, count } = contextValue;

  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  useEffect(() => {
    setHasNewNotifications(checkHasNewNotifications(count));
  }, [count]);

  const bellIcon = isActive
    ? activeIcon
    : hasNewNotifications
      ? RingingBell
      : icon;

  return <IconWithBadge icon={bellIcon} count={count} />;
};
