/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconProJobs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconProJobs"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.5 15a4.5 4.5 0 1 1 0 9h-9a4.5 4.5 0 1 1 0-9zm-.204 2c-1.341 0-2.146.942-2.146 2.497 0 1.588.805 2.503 2.146 2.503 1.348 0 2.152-.942 2.152-2.503 0-1.59-.804-2.497-2.152-2.497m-8.755.117H9v4.766h1.128v-1.589h.516c1.1 0 1.884-.515 1.884-1.623 0-1.045-.756-1.554-1.987-1.554m4.147 0h-1.562v4.766h1.129v-1.822h.44l.976 1.822h1.273l-1.21-2.029c.618-.282.914-.66.914-1.286 0-.99-.667-1.451-1.96-1.451m4.608.708c.64 0 .97.468.97 1.672 0 1.21-.324 1.678-.97 1.678-.62 0-.963-.468-.963-1.678 0-1.204.33-1.672.963-1.672m-8.77.097c.558 0 .84.24.84.75 0 .552-.264.776-.705.807l-.114.004h-.42v-1.561zm4.134-.014c.557 0 .826.2.826.66s-.22.678-.638.71l-.119.005h-.474v-1.375zM9 0a5 5 0 0 1 4.9 3.999L18 4v9h-2v-2h-6v2H8v-2H2v5l3.023-.001a6.5 6.5 0 0 0-.849 2L0 18V4l4.1-.001A5 5 0 0 1 9 0m7 6H2v3h14zM9 2a3 3 0 0 0-2.829 2h5.658A3 3 0 0 0 9 2"
    />
  </svg>
);
export default IconProJobs;
