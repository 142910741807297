import type { RouteComponentProps } from '@reach/router';
import type * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import { XingletLoader } from '@xing-com/crate-xinglet';

export const Generic: React.FC<RouteComponentProps> = () => (
  <XingletLoader
    name="@xing-com/crate-communication-error-pages"
    errorType="Generic"
    error={
      <p>
        <Button
          variant="primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          <FormattedMessage id="BUTTON_TRY_AGAIN" />
        </Button>
      </p>
    }
  />
);
