const entryPointDomainMap: Record<string, string> = {
  '@xingternal/crate-communication-root': 'www.xing.com',
  '@xing-com/crate-lebenslauf-root': 'lebenslauf.com',
  '@xing-com/crate-login-root': 'login.xing.com',
};

type LinkWithDomainOptions = {
  entryPoint: string;
  loginAppUrl: string;
  hostname: string;
};

export const handleLinkWithDomain = <T>(
  to: T,
  { entryPoint, loginAppUrl, hostname }: LinkWithDomainOptions
): T | string => {
  const domain = entryPointDomainMap[entryPoint];

  if (!domain) return to;
  if (typeof to !== 'string') return to;

  const result = to.replace(
    new RegExp(`^https?:\\/\\/${domain.replaceAll('.', '\\.')}`),
    ''
  );
  if (!result.startsWith('https://')) return result;

  const url = new URL(result);

  if (url.hostname === 'www.xing.com') {
    if (
      hostname === 'login.preview.xing.com' ||
      hostname === 'vita.preview.lebenslauf.com'
    ) {
      url.hostname = 'preview.xing.com';

      return url.toString();
    }
  }

  if (url.hostname === 'login.xing.com') {
    if (url.pathname === '/login') {
      return result.replace('https://login.xing.com/login', loginAppUrl);
    }

    const loginUrl = new URL(loginAppUrl);

    url.protocol = loginUrl.protocol;
    url.hostname = loginUrl.hostname;
    url.port = loginUrl.port;

    return url.toString();
  }

  if (url.hostname === 'lebenslauf.com') {
    if (hostname === 'preview.xing.com') {
      url.hostname = 'vita.preview.lebenslauf.com';
      return url.toString();
    }
  }

  return result;
};
