import styled from 'styled-components';

import { ActivityCenterWrapper } from '@xing-com/crate-activity-center-wrapper';
import { Header } from '@xing-com/crate-layout-header';
import { useDeviceInfo } from '@xing-com/crate-xinglet';
import { useFeatureSwitch } from '@xing-com/hub';
import { Section } from '@xing-com/layout-primitives';
import { mediaTopBarVisible } from '@xing-com/layout-tokens';
import { BottomBar } from '@xing-com/platform-layout-bottom-bar';
import { Footer } from '@xing-com/platform-layout-footer';
import { HeaderLoggedIn } from '@xing-com/platform-layout-header-logged-in';
import { LoggedoutViewInnerWrappers } from '@xing-com/platform-layout-logged-out';
import { LayoutMainInnerWrappers } from '@xing-com/platform-layout-main';
import { SkipNavigation } from '@xing-com/platform-layout-skip-navigation';
import { xdlColorBackground } from '@xing-com/tokens';
import { useMediaListener, BREAKPOINTS } from '@xing-com/util';

import { HeaderLoggedOutWithLoginLink } from './header-logged-out-with-login-link';

const StyledLayoutWrapper = styled.div`
  background-color: ${xdlColorBackground};
`;

export const StyledHeaderLoggedIn = styled(HeaderLoggedIn)`
  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

type UnifiedLayoutProps = {
  layout: 'blank-with-footer' | 'xing-containerless' | 'xing' | 'xing-gridless';
  isLoggedInLayout: boolean;
  loginDestination?: string;
  loginTracking?: string;
};

export const UnifiedLayout: React.FC<
  React.PropsWithChildren<UnifiedLayoutProps>
> = ({
  children,
  layout,
  isLoggedInLayout,
  loginDestination,
  loginTracking,
}) => {
  const isBlankLayout = layout === 'blank-with-footer';
  const isContainerlessLayout = layout === 'xing-containerless';
  const isGridless = layout === 'xing-gridless';
  const isHeaderEnabled = !isBlankLayout;

  const breakpoint = useMediaListener();
  const isNotHandheld = breakpoint !== BREAKPOINTS.handheld;
  const isFooterEnabled = !isLoggedInLayout || isNotHandheld;

  const { isWebview } = useDeviceInfo();
  const isBottomBarEnabled = isLoggedInLayout || !isWebview;
  const isVerticalNavEnabled = !useFeatureSwitch('nav_update_mid_2024');

  return (
    <>
      {isLoggedInLayout ? null : <ActivityCenterWrapper />}
      <StyledLayoutWrapper data-qa="core-crate-layout-main">
        {isBlankLayout ? null : <SkipNavigation />}
        {isHeaderEnabled ? (
          !isVerticalNavEnabled ? (
            <Header />
          ) : isLoggedInLayout ? (
            <StyledHeaderLoggedIn homeLink="/" />
          ) : (
            <HeaderLoggedOutWithLoginLink
              loginDestination={loginDestination}
              loginTracking={loginTracking}
            />
          )
        ) : null}
        {isBlankLayout || isContainerlessLayout ? (
          <main id="content">{children}</main>
        ) : isLoggedInLayout ? (
          isVerticalNavEnabled ? (
            <LayoutMainInnerWrappers
              {...{
                children,
                gridless: isGridless,
              }}
            />
          ) : isGridless ? (
            <Section noBlockPadding>{children}</Section>
          ) : (
            children
          )
        ) : (
          <LoggedoutViewInnerWrappers {...{ children, gridless: isGridless }} />
        )}

        {isFooterEnabled ? <Footer homeLink="/" /> : null}
        {isBottomBarEnabled ? <BottomBar /> : null}
      </StyledLayoutWrapper>
    </>
  );
};
