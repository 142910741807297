import { useRef, type MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { mediaConfined, zIndexElevatedLayer5 } from '@xing-com/layout-tokens';
import { Menu } from '@xing-com/menu';
import { PopOver, usePopOver } from '@xing-com/pop-over';
import ttt from '@xing-com/ticktricktrack';
import { spaceL, spaceM, spaceXL } from '@xing-com/tokens';

type HorizontalNavMenuProps = {
  'data-testid'?: string;
  width?: string;
  withMenu?: boolean;
  tracking: Record<string, string>;
  trigger: (onClick: (event: React.MouseEvent) => void) => React.ReactNode;
};

const menuStyles = css`
  z-index: ${zIndexElevatedLayer5};
  min-width: 288px;
  max-height: calc(100vh - 60px);
  overflow: auto;
  right: 0;
`;

const StyledPopOver = styled(PopOver)`
  ${menuStyles}
  padding: ${spaceM} ${spaceL};
`;

const StyledMenu = styled(Menu)<{ $width?: string }>`
  @media ${mediaConfined} {
    width: ${({ $width }) => $width};
    ${menuStyles}
    && {
      padding: ${spaceXL};
    }
  }
`;

const preventDefaultIfTriggerClicked = (event: MouseEvent): void => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  let target = event.target as HTMLElement;
  while (target && target.tagName !== 'A') {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    target = target.parentElement as HTMLElement;
  }
  if (target === event.currentTarget) {
    event.preventDefault();
  }
};

export const HorizontalNavMenu: React.FC<
  React.PropsWithChildren<HorizontalNavMenuProps>
> = ({
  'data-testid': dataTestId,
  tracking,
  width,
  trigger,
  withMenu = false,
  children,
}) => {
  const popOver = usePopOver();
  const lastOutsideClick = useRef<number>(0); // 0 means 1970-01-01 here

  const handleOutsideClick = (): void => {
    lastOutsideClick.current = new Date().getTime();
    popOver.handleHide();
  };

  const handleTriggerClick = (event: MouseEvent): void => {
    preventDefaultIfTriggerClicked(event);

    const wasJustClosed = new Date().getTime() - lastOutsideClick.current < 100;
    if (wasJustClosed) return;

    if (tracking) ttt.event('PropTrackAction', tracking);
    popOver.togglePopOver();
  };

  const triggerElement = trigger(handleTriggerClick);

  const MenuComponent = withMenu ? StyledMenu : StyledPopOver;

  return (
    <>
      {triggerElement}
      <MenuComponent
        $width={width}
        data-testid={dataTestId}
        onClick={popOver.handleHide}
        onOutsideClick={handleOutsideClick}
        isInFlow
        isStatic
        show={popOver.show}
        triggerRef={popOver.triggerRef}
      >
        {children}
      </MenuComponent>
    </>
  );
};
