import styled, { css } from 'styled-components';

import { scale020, scale030 } from '@xing-com/tokens';

import type { SkeletonFacepileSize } from '.';

type FacepileTProps = {
  $size: SkeletonFacepileSize;
};

// *** Components ***
export const Container = styled.div<FacepileTProps>`
  display: flex;
  flex-shrink: 0;

  ${({ $size }) =>
    $size === 'small' &&
    css`
      gap: ${scale020};
    `};
  ${({ $size }) =>
    $size === 'medium' &&
    css`
      gap: ${scale030};
    `};
`;
