import React from 'react';
import ReactDOM from 'react-dom';

import { useSetIsOmViewVisible, useOmViewRef } from '@xing-com/hub';
import {
  ViewWrapper,
  ViewWrapperDefaultGrid,
} from '@xing-com/platform-layout-view-wrapper';

import { Header } from './header';

type SharedProps = {
  className?: string;
  onClose?: () => void;
  interceptClose?: () => void;
  plainOmView?: boolean;
  trigger: (
    activate: (e?: { currentTarget: HTMLElement | undefined }) => void
  ) => React.ReactNode;
  preventPropagation?: boolean;
  children:
    | React.ReactNode
    | {
        ({
          handleClose,
        }: {
          handleClose: (popState?: boolean) => void;
        }): React.ReactNode;
      };
};

const OmViewGridLess: React.FC<SharedProps> = ({
  className,
  onClose,
  children,
  interceptClose,
  plainOmView,
  trigger,
  preventPropagation,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const omViewRef = useOmViewRef();

  const [scrollPosition, setScrollPosition] = React.useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [triggerElement, setTriggerElement] = React.useState<
    HTMLElement | undefined
  >(undefined);

  const onCloseTimeout = React.useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  const isActive = React.useRef(false);
  const setIsOmViewVisible = useSetIsOmViewVisible();

  const handleActivate = (e?: {
    currentTarget: HTMLElement | undefined;
  }): void => {
    setScrollPosition({
      x: window.scrollX,
      y: window.scrollY,
    });
    window.history.pushState(null, document.title, window.location.href);

    setTriggerElement(e?.currentTarget);

    isActive.current = true;
    setIsOpen(true);
    setIsOmViewVisible(true);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  };

  const handleDeactivate = React.useCallback(
    (popState = true): void => {
      if (!isActive.current) {
        return;
      }

      isActive.current = false;

      if (popState) {
        window.history.back();
      }

      setIsOmViewVisible(false);

      setTimeout(() => {
        const { x, y } = scrollPosition;
        window.scrollTo(x, y);
      }, 0);

      onCloseTimeout.current = setTimeout(() => {
        setIsOpen(false);
        if (triggerElement && triggerElement.focus) {
          triggerElement.focus();
        }
      }, 200);
    },
    [scrollPosition, setIsOmViewVisible, triggerElement]
  );

  const handleOnClose = React.useCallback((): void => {
    if (typeof interceptClose === 'function') {
      interceptClose();
    } else {
      handleDeactivate();
      if (typeof onClose === 'function') {
        onClose();
      }
    }
  }, [handleDeactivate, interceptClose, onClose]);

  React.useEffect(() => {
    const handleOnCloseOnKeyUp = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        handleOnClose();
      }
    };
    window.addEventListener('keyup', handleOnCloseOnKeyUp);

    return () => {
      window.removeEventListener('keyup', handleOnCloseOnKeyUp);
    };
  }, [handleOnClose]);

  React.useEffect(() => {
    const closeAndPopState = (): void => {
      handleDeactivate(false);
    };

    window.addEventListener('popstate', closeAndPopState);

    return () => {
      if (onCloseTimeout.current) {
        clearTimeout(onCloseTimeout.current);
      }
      window.removeEventListener('popstate', closeAndPopState);
    };
  }, [handleDeactivate, onCloseTimeout]);

  return (
    <React.Fragment>
      {trigger(handleActivate)}
      {isOpen &&
        omViewRef.current &&
        ReactDOM.createPortal(
          <ViewWrapper
            className={className}
            preventPropagation={preventPropagation}
            Header={!plainOmView && <Header onClose={handleOnClose} />}
          >
            {typeof children === 'function'
              ? children({ handleClose: handleDeactivate })
              : children}
          </ViewWrapper>,
          omViewRef.current
        )}
    </React.Fragment>
  );
};

const OmView: React.FC<SharedProps> = ({ children, ...props }) => {
  return (
    <OmViewGridLess {...props}>
      {({ handleClose }) => (
        <ViewWrapperDefaultGrid>
          {typeof children === 'function'
            ? children({ handleClose })
            : children}
        </ViewWrapperDefaultGrid>
      )}
    </OmViewGridLess>
  );
};

export { OmView, OmViewGridLess };
