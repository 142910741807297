import type { FC, PropsWithChildren } from 'react';

import { VisitorIdContext } from '@xing-com/hub';

type VisitorIdModuleProps = {
  visitorId: string;
};

export const VisitorIdModule: FC<PropsWithChildren<VisitorIdModuleProps>> = ({
  children,
  visitorId,
}) => {
  return (
    <VisitorIdContext.Provider value={visitorId}>
      {children}
    </VisitorIdContext.Provider>
  );
};
