export const mapBadges = (badges: Array<string>): Array<string> =>
  badges.flatMap((badge) => {
    switch (badge) {
      case 'ncWeb': {
        return 'nc_web';
      }
      default:
        return [badge];
    }
  });
