import type { ConfigItem } from '@xing-com/meta-renderer';

import type { ConfigurationItem, Language, Translate } from '../types';

export const loggedOutFooter: ConfigurationItem = (
  t: Translate,
  lang: Language
) => {
  return [
    [
      'FooterSection',
      {
        id: 'company',
        title: t('VERTICAL_NAVIGATION_FOOTER_COMPANY'),
        children: {
          $meta: [
            [
              'FooterNavItem',
              {
                id: 'newWorkFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_NEWWORKSE'),
                href:
                  lang === 'de'
                    ? 'https://www.new-work.se/de'
                    : 'https://www.new-work.se/en',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                id: 'careersFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_CAREER'),
                href:
                  lang === 'de'
                    ? 'https://www.new-work.se/de/karriere/'
                    : 'https://www.new-work.se/en/career/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                id: 'investorRelationsFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_INVESTOR_RELATIONS'),
                href:
                  lang === 'de'
                    ? 'https://www.new-work.se/de/investor-relations/'
                    : 'https://www.new-work.se/en/investor-relations/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                id: 'pressFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_PRESS'),
                href:
                  lang === 'de'
                    ? 'https://www.new-work.se/de/newsroom/'
                    : 'https://www.new-work.se/en/newsroom/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            ...(lang === 'de'
              ? [
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  [
                    'FooterNavItem',
                    {
                      id: 'newWorkExperienceFooter',
                      title: t(
                        'VERTICAL_NAVIGATION_FOOTER_NEW_WORK_EXPERIENCE'
                      ),
                      href: 'https://nwx.new-work.se',
                      tracking: {
                        sc_o: 'navigation_footer',
                      },
                    },
                  ] as ConfigItem,
                ]
              : []),
          ],
        },
      },
    ],
    [
      'FooterSection',
      {
        id: 'resources',
        title: t('VERTICAL_NAVIGATION_FOOTER_RESOURCES'),
        children: {
          $meta: [
            [
              'FooterNavItem',
              {
                id: 'helpFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_HELP'),
                href:
                  lang === 'de'
                    ? 'https://faq.xing.com/de'
                    : 'https://faq.xing.com/en',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                id: 'devBlogFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_DEVBLOG'),
                href: 'https://tech.new-work.se/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                id: 'appsFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_APP_GALLERY'),
                href:
                  lang === 'de'
                    ? 'https://mobile.xing.com/de/'
                    : 'https://mobile.xing.com/en/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                id: 'ballerLeagueFooter',
                title: 'Baller League',
                href: 'https://ballerleague.xing.com/',
              },
            ],
          ],
        },
      },
    ],
    [
      'FooterSection',
      {
        id: 'memberships',
        title: t('VERTICAL_NAVIGATION_FOOTER_MEMBERSHIPS'),
        children: {
          $meta: [
            [
              'FooterNavItem',
              {
                id: 'premiumOfferFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_PREMIUM_MEMBERSHIP'),
                href: 'https://www.xing.com/purchase/premium?reagent=uplt_98',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                id: 'proJobsOfferFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_PROJOBS_MEMBERSHIP'),
                href: 'https://www.xing.com/purchase/projobs?reagent=uplt_205',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
          ],
        },
      },
    ],
    [
      'FooterSection',
      {
        id: 'mainSections',
        title: t('VERTICAL_NAVIGATION_FOOTER_YOUR_XING'),
        children: {
          $meta: [
            [
              'FooterNavItem',
              {
                key: 'peopleDirectoryFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_PEOPLE_DIRECTORY'),
                href: 'https://www.xing.com/people/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'jobsFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_JOBS_DIRECTORY'),
                href: 'https://www.xing.com/jobs/directory/a',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'newsFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_XING_NEWS'),
                href: 'https://www.xing.com/news',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'companiesFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_COMPANIES'),
                href: 'https://www.xing.com/companies',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'coachesFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_COACHES'),
                href: 'https://coaches.xing.com/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
          ],
        },
      },
    ],
    [
      'FooterSection',
      {
        id: 'services',
        title: 'Services',
        children: {
          $meta: [
            [
              'FooterNavItem',
              {
                key: 'eRecruitingOfferFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_TALENT_MANAGER'),
                href:
                  lang === 'de'
                    ? 'https://recruiting.xing.com/de/'
                    : 'https://recruiting.xing.com/en/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'advertisementOfferFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_XAS'),
                href: 'https://www.xing.com/xam/personal/ads',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'marketingSolutionsOfferFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_ADVERTISE'),
                href:
                  lang === 'de'
                    ? 'https://werben.xing.com'
                    : 'https://advertising.xing.com',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'curriculumVitaeEditorFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_LEBENSLAUF'),
                href: 'https://lebenslauf.com/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'coverLetterEditorFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_ANSCHREIBEN'),
                href: 'https://anschreiben.com/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'jobApplicationTipsFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_BEWERBUNG'),
                href: 'https://bewerbung.com/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'kununuOfferFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_KUNUNU'),
                href: 'https://www.kununu.com/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'praktikumGuideFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_PRAKTIKUM_GUIDE'),
                href: 'https://praktikum-guide.com/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                key: 'careerGuideFooter',
                title: t('VERTICAL_NAVIGATION_FOOTER_CAREER_GUIDE'),
                href: 'https://www.xing.com/karriere-ratgeber',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
          ],
        },
      },
    ],
  ];
};
