/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconArrowUp"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.532 16.468 20 15l-8-8-8 8 1.468 1.468L12 9.936z"
    />
  </svg>
);
export default IconArrowUp;
