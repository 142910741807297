import styled, { css } from 'styled-components';

import { mediaNavExtended, mediaNavCollapsed } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import { IconWithBadge } from '@xing-com/platform-layout-icon-with-badge';
import {
  cornerRadiusL,
  cornerRadiusM,
  cornerRadiusS,
  scale030,
  scale050,
  scale060,
  scale100,
  scale110,
  xdlColorHover,
  xdlColorHoverSecondary,
  xdlColorLink,
  xdlColorProJobs,
  xdlColorProBusiness,
  xdlColorSkeletonPrimary,
  xdlColorText,
  spaceM,
  spaceL,
  spaceS,
} from '@xing-com/tokens';

// @TODO: Find the JS alternative
const mediaXL = '(min-width: 1113px)';

const membershipToXdlColor: { [key: string]: string } = {
  premium: xdlColorLink,
  proJobs: xdlColorProJobs,
  proBusiness: xdlColorProBusiness,
};
type Memberships = {
  $membership?: string | undefined;
};

type LinkProps = Memberships & {
  $isActive: boolean;
};

type TitleProps = {
  $size: 'long' | 'short';
};

const linkBase = css<LinkProps>`
  align-items: center;
  border-radius: ${cornerRadiusL};
  color: ${xdlColorText};
  display: flex;
  padding: ${spaceS} ${spaceL};

  @media ${mediaNavExtended} {
    padding: ${spaceM} 0 ${spaceM} ${spaceM};
  }

  position: relative;

  @media ${mediaNavCollapsed} {
    border-radius: ${cornerRadiusM};
    flex-direction: column;
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${xdlColorHover};
  }
  &:active {
    background-color: ${xdlColorHoverSecondary};
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const PlaceholderLi = styled.li`
  padding: ${spaceS} ${scale030};

  @media ${mediaXL} {
    padding: ${spaceS} ${spaceL};
  }
`;

const PlaceholderIcon = styled.span`
  background-color: ${xdlColorSkeletonPrimary};
  border-radius: ${scale050};
  flex: 0 0 ${scale100};
  height: ${scale100};
  width: ${scale100};
`;

const PlaceholderText = styled.p`
  display: block;
  width: 100%;
  @media ${mediaXL} {
    display: flex;
    flex: 1 0 1px;
  }
`;

const PlaceholderItemText = styled.span`
  background-color: ${xdlColorSkeletonPrimary};
  border-radius: ${cornerRadiusS};
  display: inline-block;
  height: ${scale050};
  width: 100%;

  @media ${mediaXL} {
    height: ${scale060};
  }
`;

const StyledLink = styled(Link)<LinkProps>`
  ${linkBase}
  ${({ $isActive }) =>
    $isActive &&
    css`
      font-weight: bold;
    `};
  ${({ $membership }) =>
    $membership &&
    css`
      color: ${membershipToXdlColor[$membership]};
    `}
`;
const StyledIconWithBadge = styled(IconWithBadge)<Memberships>`
  ${({ $membership }) =>
    $membership &&
    css`
      color: ${membershipToXdlColor[$membership]};
    `}
`;

const StyledTitle = styled.p<TitleProps>`
  line-height: ${scale110};
  margin: 0 0 0 ${spaceL};
  text-decoration: none;
  text-align: left;

  @media ${mediaNavCollapsed} {
    margin: 0;
    display: ${({ $size }) => ($size === 'long' ? 'none' : `block`)};
    white-space: ${({ $size }) => ($size === 'long' ? '' : `nowrap`)};
  }

  @media ${mediaNavExtended} {
    display: ${({ $size }) => ($size === 'long' ? 'block' : `none`)};
  }
`;

export {
  PlaceholderLi,
  PlaceholderIcon,
  PlaceholderText,
  PlaceholderItemText,
  StyledIconWithBadge,
  StyledLink,
  StyledTitle,
};
