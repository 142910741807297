import * as React from 'react';

import { SkeletonBodyCopy } from '../';
import * as Styled from './skeleton-profile-info.styles';
import type { SkeletonProfileInfoProps } from './skeleton-profile-info.types';

export const SkeletonProfileInfo = React.forwardRef<
  HTMLDivElement,
  SkeletonProfileInfoProps
>(
  (
    { className, variant = 'horizontal', ...props },
    forwardedRef
  ): JSX.Element => (
    <Styled.ProfileInfoContainer
      $variant={variant}
      className={className}
      ref={forwardedRef}
      {...props}
    >
      <Styled.ProfileInfoImage $variant={variant} size="medium" />
      <Styled.ProfileInfoContent>
        <Styled.ProfileInfoHeadline amountRows={1} size="medium" />
        <SkeletonBodyCopy amountRows={variant === 'horizontal' ? 2 : 3} />
      </Styled.ProfileInfoContent>
    </Styled.ProfileInfoContainer>
  )
);

SkeletonProfileInfo.displayName = 'SkeletonProfileInfo';
