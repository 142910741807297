import type { FC, PropsWithChildren } from 'react';

import { useWebSocket } from '@xing-com/crate-core-websocket-helper';
import { useHost } from '@xing-com/crate-xinglet';

const ACTIVITY_CENTER_CHANNEL = 'logged_out:shopping_cart:updated';

type ActivityListenerProps = {
  onMessageReceived: () => void;
};

export const ActivityListener: FC<PropsWithChildren<ActivityListenerProps>> = ({
  onMessageReceived,
}) => {
  const host = useHost();
  const { listen } = useWebSocket(host);

  listen(ACTIVITY_CENTER_CHANNEL, () => {
    onMessageReceived();
  });

  return null;
};
