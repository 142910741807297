import styled, { css, keyframes } from 'styled-components';

import { mediaSmallOrTiny, mediaWideNavless } from '@xing-com/layout-tokens';
import { Menu } from '@xing-com/menu';
import { ModalPage } from '@xing-com/modal-page';
import { PopOver } from '@xing-com/pop-over';
import {
  scale230,
  space3XL,
  spaceL,
  spaceM,
  xdlColorBackground,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const StyledMenu = styled(Menu)`
  &&& {
    padding: 0;
    max-height: 85vh;
    overflow-y: auto;

    @media not ${mediaSmallOrTiny} {
      top: 40px;
      right: 0;
      width: 426px;
      margin-top: ${spaceL};
    }

    @media ${mediaWideNavless} {
      top: 70px;
    }
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(225%);
  }
`;

const animationStyles = css<{ isVisible: boolean }>`
  animation: ${({ isVisible }) => (isVisible ? slideUp : slideDown)} 0.3s
    ease-out;
`;

export const StyledModalPage = styled(ModalPage)`
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: ${xdlColorBackground};
`;

export const StyledPopOver = styled(PopOver)<{ isVisible: boolean }>`
  bottom: ${scale230};
  left: 0;
  right: 0;
  margin: auto;
  &.position-override {
    width: calc(100% - ${space3XL});
    position: fixed;
  }
  ${animationStyles};
  @media ${mediaSmallOrTiny} {
    &.position-override {
      position: fixed;
    }
    padding: ${spaceL};
    width: 100%;
  }
`;

export const StyledPopOverContentContainer = styled.div`
  display: flex;
`;

export const StyledPopOverTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${spaceM};
  align-items: start;
  max-height: 48px;
`;

export const StyledBodyCopy = styled(BodyCopy)`
  margin: 0;
`;

export const SuperEllipseWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  position: relative;
`;
