import { useEffect, useRef } from 'react';

import type { OriginType } from '@xing-com/crate-activity-center-types';

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const getOriginFromPath = (): OriginType => {
  if (typeof window === 'undefined') {
    return 'other';
  }

  return window.location.pathname === '/activity' ? 'checkout_page' : 'other';
};
