import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { Menu } from '@xing-com/menu';
import { scale160 } from '@xing-com/tokens';
export const MoreMenuContainer = styled.div`
  position: relative;
`;

const listMaxHeightMobile = '90vh';
const listMaxHeightTabletAndDesktop = '556px';
const listMinWidth = '288px';

export const StyledMenu = styled(Menu)`
  max-height: ${listMaxHeightMobile};
  min-width: ${listMinWidth};
  overflow: auto;

  /* >= 740px */
  @media ${mediaConfined} {
    max-height: ${listMaxHeightTabletAndDesktop};
    position: absolute;
    top: ${scale160};
    right: -5px;
  }
`;
