import * as React from 'react';

import { mergeRefs } from '../utils/merge-ref';
import * as Styled from './text-button.styles';
import type { TextButtonProps } from './text-button.types';

export const TextButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  TextButtonProps
>(
  (
    {
      children,
      className,
      disabled = false,
      ellipsis,
      error,
      fontWeight,
      href,
      icon: Icon,
      iconAfter,
      innerRef,
      loading,
      onClick,
      size = 'medium',
      sizeConfined,
      sizeWide,
      to,
      type,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const iconSize = size === 'small' ? 18 : 24;
    const iconOnly = !children;

    const combinedRef = React.useMemo(
      () => mergeRefs([innerRef, forwardedRef]),
      [innerRef, forwardedRef]
    );

    const commonProps = {
      $ellipsis: ellipsis,
      $error: error,
      $fontWeight: fontWeight,
      $iconAfter: iconAfter,
      $iconOnly: iconOnly,
      $size: size,
      $sizeConfined: sizeConfined,
      $sizeWide: sizeWide,
      'aria-label': loading ? 'loading' : undefined,
      'data-xds': 'TextButton',
      className,
      disabled: disabled || loading,
      onClick,
      ref: combinedRef,
    };

    const buttonContent = (
      <Styled.InnerContent $ellipsis={ellipsis}>
        {!loading && Icon && (
          <Styled.IconContainer $iconSize={iconSize} $iconAfter={iconAfter}>
            <Icon width={iconSize} height={iconSize} />
          </Styled.IconContainer>
        )}
        {loading && (
          <Styled.Loading
            $size={size}
            $sizeConfined={sizeConfined}
            $sizeWide={sizeWide}
            aria-hidden="true"
          />
        )}
        <Styled.Text $ellipsis={ellipsis} $loading={loading}>
          {children}
        </Styled.Text>
      </Styled.InnerContent>
    );

    if (to || href) {
      return (
        <Styled.RouterTextButton
          {...(to && { to })}
          {...(href && { href })}
          {...commonProps}
          {...props}
        >
          {buttonContent}
        </Styled.RouterTextButton>
      );
    }

    return (
      <Styled.TextButton type={type} {...commonProps} {...props}>
        {buttonContent}
      </Styled.TextButton>
    );
  }
);

TextButton.displayName = 'TextButton';
