import styled, { css } from 'styled-components';

import {
  mediaConfined,
  mediaConfinedNavlessOnly,
  mediaSmallOrTiny,
  mediaNavCollapsed,
  mediaTopBarVisible,
} from '@xing-com/layout-tokens';
import { HeaderLoggedIn } from '@xing-com/platform-layout-header-logged-in';
import {
  scale180,
  scale190,
  scale290,
  scale100,
  spaceXL,
  spaceXXL,
  xdlColorBackground,
} from '@xing-com/tokens';

export const StyledLayoutMainWrapper = styled.div`
  background-color: ${xdlColorBackground};
`;

export const StyledHeaderLoggedIn = styled(HeaderLoggedIn)`
  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

export const StyledSideBarWrapper = styled.div<{
  $gridless: boolean;
}>`
  flex: 0 0 228px;
  /* "Breathing Room" between Vertical Navigation from Footer and Content */
  margin-bottom: ${scale180};
  margin-right: ${({ $gridless }) => ($gridless ? spaceXXL : spaceXL)};

  @media ${mediaSmallOrTiny}, ${mediaConfinedNavlessOnly} {
    display: none;
  }

  @media ${mediaNavCollapsed} {
    flex: 0 0 ${scale190};
  }
`;

export const StyledScrollWrapper = styled.section`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  /* This flex item doesn't seem to grow up to its parent's width without a given initial width */
  width: 0%;

  @media ${mediaSmallOrTiny} {
    width: 100%;
  }

  @media ${mediaConfined} {
    padding-bottom: 0;
  }

  @media ${mediaConfinedNavlessOnly} {
    justify-content: center;
  }
`;

export const StyledContainer = styled.div<{
  $gridless: boolean;
}>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  @media ${mediaConfinedNavlessOnly} {
    /* compat mode with the new grid */
    max-width: ${({ $gridless }) => ($gridless ? 'none' : '800px')};
  }
`;

export const StyledMain = styled.main<{
  $gridless: boolean;
}>`
  min-height: calc(100vh - ${scale290});

  ${({ $gridless }) =>
    $gridless
      ? css`
          @media ${mediaSmallOrTiny} {
            margin: 0 ${scale100};
          }
        `
      : css`
          display: flex;
          justify-content: center;
        `};
`;
