import * as React from 'react';

const NoSSR: React.FC<
  React.PropsWithChildren<{ fallback?: React.ReactNode }>
> = ({ children, fallback }) => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  if (!mounted) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};

export { NoSSR };
