import * as React from 'react';

import { scale030 } from '@xing-com/tokens';

import { SkeletonPillShape } from '..';
import type { SkeletonProgressBarProps } from '..';

export const SkeletonProgressBar = React.forwardRef<
  HTMLDivElement,
  SkeletonProgressBarProps
>(
  ({ className, ...props }, forwardedRef): JSX.Element => (
    <SkeletonPillShape
      className={className}
      data-xds="SkeletonProgressBar"
      height={scale030}
      ref={forwardedRef}
      {...props}
    />
  )
);

SkeletonProgressBar.displayName = 'SkeletonProgressBar';
