/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconLogout"
    {...props}
  >
    <path fill="currentColor" d="M13 5V3H3v18h10v-2H5V5z" />
    <path
      fill="currentColor"
      d="M15.707 16.707 20.414 12l-4.707-4.707-1.414 1.414L16.585 11H9v2h7.585l-2.292 2.293z"
    />
  </svg>
);
export default IconLogout;
