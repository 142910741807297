import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';
import { Dimmer as XdlDimmer } from '@xing-com/dimmer';
import { zIndexElevatedLayer5 } from '@xing-com/layout-tokens';
import {
  cornerRadiusXL,
  motionEasingEnter,
  motionEasingLeave,
  motionTimeM,
  scale005,
  xdlColorBackground,
  xdlColorModalBorder,
} from '@xing-com/tokens';

type DimmerTransientProps = {
  $isNotClickable: boolean;
};

export const dimmerDuration = motionTimeM;
export const dimmerTransitionName = 'fade';
export const bottomSheetDuration = motionTimeM;
export const bottomSheetTransitionName = 'slide';

// *** Components ***
export const BottomSheet = styled.div`
  bottom: 0;
  display: flex;
  left: 0;
  max-height: calc(100vh - 90px);
  position: fixed;
  transform: translateY(100%);
  width: 100%;
  z-index: ${zIndexElevatedLayer5};

  /* BottomSheet Animation */
  &.${bottomSheetTransitionName}-enter {
    transform: translateY(99.9%);
  }

  &.${bottomSheetTransitionName}-enter-active {
    transform: translateY(0%);
    transition-duration: ${bottomSheetDuration};
    transition-property: transform;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${bottomSheetTransitionName}-enter-done {
    transform: translateY(0%);
    transition-duration: ${bottomSheetDuration};
    transition-property: transform;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${bottomSheetTransitionName}-exit {
    transform: translateY(0%);
  }

  &.${bottomSheetTransitionName}-exit-active {
    transform: translateY(99.9%);
    transition-duration: ${bottomSheetDuration};
    transition-property: transform;
    transition-timing-function: ${motionEasingLeave};
  }

  &.${bottomSheetTransitionName}-exit-done {
    transform: translateY(100%);
    transition-duration: ${bottomSheetDuration};
    transition-property: transform;
    transition-timing-function: ${motionEasingLeave};
  }
`;

export const Wrapper = styled.div`
  background: ${xdlColorBackground};
  border-top-color: ${xdlColorModalBorder};
  border-top-left-radius: ${cornerRadiusXL};
  border-top-right-radius: ${cornerRadiusXL};
  border-top-width: ${scale005};
  border-top-style: solid;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
  padding-bottom: env(safe-area-inset-bottom);
`;

export const Close = styled(TextButton)`
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

export const Dimmer = styled(XdlDimmer)<DimmerTransientProps>`
  overflow-y: hidden;
  ${({ $isNotClickable }) =>
    $isNotClickable &&
    css`
      cursor: default;
    `};

  /* Dimmer Animation */
  &.${dimmerTransitionName}-enter {
    opacity: 0;
  }

  &.${dimmerTransitionName}-appear {
    opacity: 0;
  }

  &.${dimmerTransitionName}-enter-active {
    opacity: 60%;
    transition-duration: ${dimmerDuration};
    transition-property: opacity;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${dimmerTransitionName}-appear-active {
    opacity: 60%;
    transition-duration: ${dimmerDuration};
    transition-property: opacity;
    transition-timing-function: ${motionEasingEnter};
  }

  &.${dimmerTransitionName}-enter-done {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-appear-done {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-exit {
    opacity: 60%;
  }

  &.${dimmerTransitionName}-exit-active {
    opacity: 0;
    transition-duration: ${dimmerDuration};
    transition-property: opacity;
    transition-timing-function: ${motionEasingLeave};
  }

  &.${dimmerTransitionName}-exit-done {
    opacity: 0;
  }
`;
