import { space4XL, spaceL, spaceXXL } from '@xing-com/tokens';

export const MIN_WIDTH = 320;

// screen sizes come in as xsmall, small, medium, large, xlarge
export const XSMALL_BREAK = 540; // only for layout changes
export const SMALL_BREAK = 740; // aside appears
export const MEDIUM_BREAK = 1024; // small nav appears
export const LARGE_BREAK = 1268; // wide nav appears

export const GUTTER = spaceL;
export const NUM_COLS = 12;
export const MAIN_GAP = 44;
export const ASIDE_MIN = 244;

// container sizes come in as narrow, regular, wide, expanded
export const CONTAINER_NARROW = 596;
export const CONTAINER_REGULAR = 944;
export const CONTAINER_WIDE = 1256;
export const CONTAINER_EXPANDED = 1520;

// media queries
export const MEDIA_XSMALL = `@media (max-width: ${XSMALL_BREAK - 1}px)`;
export const MEDIA_SMALL = `@media (min-width: ${XSMALL_BREAK}px)`;
export const MEDIA_MEDIUM = `@media (min-width: ${SMALL_BREAK}px)`;
export const MEDIA_LARGE = `@media (min-width: ${MEDIUM_BREAK}px)`;
export const MEDIA_XLARGE = `@media (min-width: ${LARGE_BREAK}px)`;

// page paddings
export const PADDING_XSMALL_SMALL = spaceL;
export const PADDING_MEDIUM_LARGE = spaceXXL;
export const PADDING_WIDE = space4XL;
