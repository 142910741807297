import styled, { css } from 'styled-components';

import { mediaNavCollapsed } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import { IconWithBadge } from '@xing-com/platform-layout-icon-with-badge';
import {
  cornerRadiusL,
  cornerRadiusM,
  scale120,
  scale150,
  xdlColorHover,
  xdlColorHoverSecondary,
  xdlColorLink,
  xdlColorProJobs,
  xdlColorProBusiness,
  xdlColorText,
  space3XL,
} from '@xing-com/tokens';

const membershipToXdlColor: { [key: string]: string } = {
  premium: xdlColorLink,
  proJobs: xdlColorProJobs,
  proBusiness: xdlColorProBusiness,
};
type Memberships = {
  $membership?: string | undefined;
};

type LinkProps = Memberships & {
  $isActive: boolean;
};

const StyledIconWithBadge = styled(IconWithBadge)<Memberships>`
  border-radius: ${cornerRadiusL};
  height: ${scale120};
  width: ${scale150};
  flex: 0 0 ${scale150};
  &:hover {
    background-color: ${xdlColorHover};
  }

  ${({ $membership }) =>
    $membership &&
    css`
      color: ${membershipToXdlColor[$membership]};
    `}
`;

const linkBase = css<LinkProps>`
  color: ${xdlColorText};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media ${mediaNavCollapsed} {
    border-radius: ${cornerRadiusM};
    margin-bottom: 0;
  }

  &:active {
    ${StyledIconWithBadge} {
      background-color: ${xdlColorHoverSecondary};
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const StyledLink = styled(Link)<LinkProps>`
  margin-right: ${space3XL};

  ${linkBase}
  ${({ $isActive }) =>
    $isActive &&
    css`
      font-weight: bold;
    `};
  ${({ $membership }) =>
    $membership &&
    css`
      color: ${membershipToXdlColor[$membership]};
    `}
`;
const StyledTitle = styled.p`
  margin: 0;
  text-decoration: none;
  text-align: left;
`;

export { StyledIconWithBadge, StyledLink, StyledTitle };
