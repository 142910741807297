import type { ComponentType, FC } from 'react';
import styled, { css } from 'styled-components';

import { Link } from '@xing-com/link';
import {
  spaceS,
  spaceL,
  xdlColorProJobs,
  xdlColorPremium,
  xdlColorHover,
  xdlColorText,
  xdlColorHoverSecondary,
} from '@xing-com/tokens';

export const StyledLink = styled(Link)<{
  $color?: 'premium' | 'proJobs';
}>`
  align-items: center;
  border-radius: 21px;
  box-sizing: border-box;
  color: ${xdlColorText};
  cursor: pointer;
  display: flex;
  flex: 1 0 auto;
  font-size: 14px;
  line-height: 20px;
  margin: ${spaceL} calc(-1 * ${spaceL});
  padding: ${spaceS} ${spaceL};
  text-decoration: none;
  transition:
    background-color 50ms cubic-bezier(0.2, 0.2, 0.2, 1),
    color 50ms cubic-bezier(0.2, 0.2, 0.2, 1);
  white-space: nowrap;
  width: auto;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: calc(-1 * ${spaceS});
  }

  &:hover {
    background-color: ${xdlColorHover};
    text-decoration: none;
  }
  &:active {
    background-color: ${xdlColorHoverSecondary};
  }

  ${({ $color }: { $color?: 'premium' | 'proJobs' }) => css`
    color: ${$color === 'premium'
      ? xdlColorPremium
      : $color === 'proJobs' && xdlColorProJobs};
  `};
`;

type LinkItemProps = {
  color?: 'premium' | 'proJobs';
  to: string;
  title: string;
  closeModal: () => void;
  icon: ComponentType<{
    style: { marginRight: string };
    width: number;
    height: number;
  }>;
};

export const LinkItem: FC<LinkItemProps> = ({
  closeModal,
  icon: LinkIconElement,
  title,
  color,
  ...props
}) => {
  return (
    <StyledLink $color={color} onClick={closeModal} {...props}>
      {LinkIconElement && (
        <LinkIconElement
          style={{ marginRight: spaceL }}
          width={24}
          height={24}
        />
      )}

      <span>{title}</span>
    </StyledLink>
  );
};
