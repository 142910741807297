import * as React from 'react';

import { BodyCopy, Meta } from '@xing-com/typography';

import * as Styled from './single-select.styles';
import type { SingleSelectProps } from './single-select.types';

export const SingleSelect = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  SingleSelectProps
>(
  (
    { children, selected, description, ...props },
    forwardedRef
  ): JSX.Element => (
    <Styled.SingleSelect
      $selected={selected}
      data-xds="SingleSelect"
      description={description}
      ref={forwardedRef}
      {...props}
    >
      {description ? (
        <Styled.SingleSelectContentContainer>
          <BodyCopy noMargin size="medium">
            {children}
          </BodyCopy>
          <Meta noMargin size="xsmall">
            {description}
          </Meta>
        </Styled.SingleSelectContentContainer>
      ) : (
        children
      )}
    </Styled.SingleSelect>
  )
);

SingleSelect.displayName = 'SingleSelect';
