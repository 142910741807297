import * as Styled from './pop-over.styles';
import type { PopOverPrimitiveProps } from './pop-over.types';

export const PopOverPrimitive = ({
  children,
  className,
  containerRef,
  position,
  ...props
}: PopOverPrimitiveProps): JSX.Element => (
  <Styled.Wrapper
    className={className}
    data-xds="PopOver"
    ref={containerRef}
    style={{ ...position }}
    {...props}
  >
    {children}
  </Styled.Wrapper>
);

PopOverPrimitive.displayName = 'PopOver (Primitive)';
