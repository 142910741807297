/// <reference types="../assets" />
import styled, { css } from 'styled-components';

import {
  scale005,
  scale010,
  scale020,
  scale160,
  xdlColorBackgroundTertiary,
  xdlColorControlFillSecondary,
} from '@xing-com/tokens';

import maskImage from '../assets/super-ellipse-mask.svg';

type SERImageContainerTransientProps = {
  $blur?: boolean;
  $blurValue?: string;
};

// *** Components ***
export const Wrapper = styled.div`
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  position: absolute;
`;

export const SuperEllipse = styled.div`
  min-height: ${scale160};
  min-width: ${scale160};
  pointer-events: none;
  position: relative;

  & .strokeWidth {
    stroke: ${xdlColorBackgroundTertiary};
  }
`;

export const ImageContainer = styled.div<SERImageContainerTransientProps>`
  mask-image: url(${maskImage});
  mask-position: center;
  mask-repeat: no-repeat;
  position: relative;

  ${({ $blur, $blurValue }) =>
    $blur && $blurValue
      ? css`
          filter: blur(${$blurValue});
          pointer-events: none;
        `
      : null};
`;

export const Image = styled.img`
  background-color: ${xdlColorControlFillSecondary};
  display: block;
  height: calc(100% - ${scale020});
  margin-block-start: ${scale010};
  margin-inline-start: ${scale010};
  min-height: ${scale160};
  min-width: ${scale160};
  width: calc(100% - ${scale020});
  z-index: 0;
`;

export const FallbackImage = styled.div`
  background-color: ${xdlColorControlFillSecondary};
  display: block;
  height: calc(100% - ${scale020});
  margin-block-start: ${scale010};
  margin-inline-start: ${scale010};
  min-height: ${scale160};
  min-width: ${scale160};
  width: calc(100% - ${scale020});
  z-index: 0;
`;

export const ImageSvgBorder = styled.svg`
  height: 100%;
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-end: ${scale005};
  inset-inline-start: ${scale010};
  min-height: ${scale160};
  min-width: ${scale160};
  position: absolute;
  stroke-width: ${scale010};
  width: calc(100% - ${scale020});
  z-index: 1;
`;
