import type * as React from 'react';

import type { LogoProps } from './logo';
import { StyledButterfly, StyledXingLogo } from './logo.styles';

const LogoSvg: React.FC<LogoProps> = ({ butterfly, ...props }) => {
  const Logo = butterfly ? StyledButterfly : StyledXingLogo;
  return <Logo {...props} />;
};

export { LogoSvg };
