import type { VerticalNavigationConfig } from '@xing-com/platform-layout-side-bar';

import type { ConfigurationItem, Translate } from '../types';

const NAVIGATION_BADGE_NO_BADGE = 'navigation_badge_no_badge';

export const verticalNavigation: ConfigurationItem = (
  t: Translate
): VerticalNavigationConfig => {
  return [
    [
      'VertNavMe',
      {
        tracking: {
          sc_o: 'navigation_profile_icon',
        },
        href: '/profile',
        hasProfileProgressIndicator: true,
      },
    ],
    [
      'VertNavUl',
      {
        children: {
          $meta: [
            [
              'VertNavItem',
              {
                item: {
                  key: 'jobs',
                  icon: { $component: 'IconJobs' },
                  activeIcon: { $component: 'IconJobsFilled' },
                  title: t('VERTICAL_NAV_FIND_JOBS_LABEL'),
                  href: '/jobs/find',
                  tracking: {
                    sc_o: 'navigation_find_jobs',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_find_jobs',
                      NAVIGATION_BADGE_NO_BADGE,
                    ],
                  },
                },
              },
            ],
            [
              'VertNavItem',
              {
                item: {
                  key: 'my-jobs',
                  icon: { $component: 'IconBookmark' },
                  activeIcon: { $component: 'IconBookmarkFilled' },
                  title: t('VERTICAL_NAV_YOUR_JOBS_LABEL'),
                  href: '/jobs/my-jobs',
                  tracking: {
                    sc_o: 'navigation_your_jobs',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_jobs',
                      NAVIGATION_BADGE_NO_BADGE,
                    ],
                  },
                },
              },
            ],
            [
              'VertNavItem',
              {
                item: {
                  key: 'contacts',
                  icon: { $component: 'IconContacts' },
                  activeIcon: { $component: 'IconContactsFilled' },
                  title: t('VERTICAL_NAV_NETWORK_LABEL'),
                  titleShort: t('VERTICAL_NAV_NETWORK_SHORT_LABEL'),
                  activeItemValues: ['members'],
                  href: '/network',
                  tracking: {
                    sc_o: 'navigation_network',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_network',
                      NAVIGATION_BADGE_NO_BADGE,
                    ],
                  },
                },
              },
            ],
            [
              'VertNavItem',
              {
                item: {
                  key: 'disco',
                  icon: { $component: 'IconNews' },
                  activeIcon: { $component: 'IconNewsFilled' },
                  title: t('VERTICAL_NAV_INSIGHTS_LABEL'),
                  href: '/discover/updates',
                  tracking: {
                    sc_o: 'navigation_insights',
                    sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
                  },
                },
              },
            ],
            [
              'VertNavItem',
              {
                item: {
                  key: 'messages',
                  icon: { $component: 'IconMessages' },
                  activeIcon: { $component: 'IconMessagesFilled' },
                  title: t('VERTICAL_NAV_MESSAGES_LABEL'),
                  href: '/chats',
                  notificationKey: 'unreadChats',
                  tracking: {
                    sc_o: 'navigation_messages',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_messages',
                      NAVIGATION_BADGE_NO_BADGE,
                    ],
                  },
                },
              },
            ],
            ['HtmlElement', { as: 'li', children: { $meta: ['Spacer'] } }],
            [
              'VertNavItem',
              {
                item: {
                  key: 'profile',
                  icon: { $component: 'IconContact' },
                  activeIcon: { $component: 'IconContactFilled' },
                  title: t('VERTICAL_NAV_PROFILE_LABEL'),
                  href: '/profile',
                  tracking: {
                    sc_o: 'navigation_profile',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_profile',
                      NAVIGATION_BADGE_NO_BADGE,
                    ],
                  },
                },
              },
            ],
            [
              'VertNavItem',
              {
                item: {
                  key: 'jobwishes',
                  icon: { $component: 'IconFilter' },
                  activeIcon: { $component: 'IconContacIconFiltertFilled' },
                  title: t('VERTICAL_NAV_JOB_PREFERENCES_LABEL'),
                  href: '/jobseeker-criteria',
                  tracking: {
                    sc_o: 'navigation_job_preferences',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_job_preferences',
                      NAVIGATION_BADGE_NO_BADGE,
                    ],
                  },
                },
              },
            ],
            [
              'VertNavItem',
              {
                item: {
                  key: 'following',
                  icon: { $component: 'IconPlus' },
                  activeIcon: { $component: 'IconPlus' },
                  title: t('VERTICAL_NAV_FOLLOWING_LABEL'),
                  href: '/news/selections/following',
                  tracking: {
                    sc_o: 'navigation_following',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_following',
                      NAVIGATION_BADGE_NO_BADGE,
                    ],
                  },
                },
              },
            ],
            [
              'VertNavItem',
              {
                item: {
                  key: 'posts',
                  icon: { $component: 'IconNewChat' },
                  activeIcon: { $component: 'IconNewChat' },
                  title: t('VERTICAL_NAV_MY_POSTS_LABEL'),
                  href: '/discover/your-posts',
                  tracking: {
                    sc_o: 'navigation_my_posts',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_my_posts',
                      NAVIGATION_BADGE_NO_BADGE,
                    ],
                  },
                },
              },
            ],
            ['HtmlElement', { as: 'li', children: { $meta: ['Spacer'] } }],
            [
              'FeatureCondition',
              {
                children: {
                  $meta: [
                    [
                      'VertNavItem',
                      {
                        item: {
                          key: 'premium',
                          icon: { $component: 'IconPremium' },
                          activeIcon: { $component: 'IconPremiumFilled' },
                          title: t('VERTICAL_NAV_PREMIUM_LABEL'),
                          titleShort: t('VERTICAL_NAV_PREMIUM_SHORT_LABEL'),
                          href: '/premium',
                          tracking: {
                            sc_o: 'navigation_premium',
                            sc_o_PropActionOrigin: [
                              'navigation_badge_premium',
                              NAVIGATION_BADGE_NO_BADGE,
                            ],
                          },
                        },
                      },
                    ],
                  ],
                },
                elseCase: {
                  $meta: [
                    [
                      'VertNavItem',
                      {
                        item: {
                          key: 'premiumUpsell',
                          activeItemValues: ['premium'],
                          icon: { $component: 'IconPremium' },
                          activeIcon: {
                            $component: 'IconPremiumFilled',
                          },
                          title: t('VERTICAL_NAV_PREMIUM_FOR_BASIC_USER_LABEL'),
                          titleShort: t('VERTICAL_NAV_PREMIUM_SHORT_LABEL'),
                          href: '/purchase/premium?reagent=uplt_1014',
                          tracking: {
                            sc_o: 'navigation_premium',
                            sc_o_PropActionOrigin: [
                              'navigation_badge_premium',
                              NAVIGATION_BADGE_NO_BADGE,
                            ],
                          },
                        },
                      },
                    ],
                  ],
                },
                flags: 'isPremium',
              },
            ],
            [
              'FeatureCondition',
              {
                children: {
                  $meta: [
                    [
                      'VertNavItem',
                      {
                        item: {
                          key: 'proJobs',
                          icon: { $component: 'IconProJobs' },
                          activeIcon: { $component: 'IconProJobsFilled' },
                          title: t('VERTICAL_NAV_PROJOBS_LABEL'),
                          href: '/projobs/dashboard',
                          tracking: {
                            sc_o: 'navigation_projobs',
                            sc_o_PropActionOrigin: [
                              'navigation_badge_projobs',
                              NAVIGATION_BADGE_NO_BADGE,
                            ],
                          },
                        },
                      },
                    ],
                  ],
                },
                elseCase: {
                  $meta: [
                    [
                      'VertNavItem',
                      {
                        item: {
                          key: 'proJobs',
                          icon: { $component: 'IconProJobs' },
                          activeIcon: {
                            $component: 'IconProJobsFilled',
                          },
                          title: t('VERTICAL_NAV_PROJOBS_FOR_BASIC_USER_LABEL'),
                          titleShort: t('VERTICAL_NAV_PROJOBS_LABEL'),
                          href: '/purchase/projobs?reagent=uplt_1015',
                          tracking: {
                            sc_o: 'navigation_projobs',
                            sc_o_PropActionOrigin: [
                              'navigation_badge_projobs',
                              NAVIGATION_BADGE_NO_BADGE,
                            ],
                          },
                        },
                      },
                    ],
                  ],
                },
                flags: 'hasProJobsMembership',
              },
            ],
            [
              'FeatureCondition',
              {
                children: {
                  $meta: [
                    [
                      'VertNavItem',
                      {
                        item: {
                          key: 'proBusiness',
                          icon: { $component: 'IconProBusiness' },
                          activeIcon: { $component: 'IconProBusinessFilled' },
                          title: t('VERTICAL_NAV_PROBUSINESS_LABEL'),
                          href: '/probusiness',
                          tracking: {
                            sc_o: 'navigation_probusiness',
                            sc_o_PropActionOrigin: [
                              'navigation_badge_probusiness',
                              NAVIGATION_BADGE_NO_BADGE,
                            ],
                          },
                        },
                      },
                    ],
                  ],
                },
                flags: 'showProbusinessInNavigation',
              },
            ],
            [
              'VertNavItem',
              {
                item: {
                  key: 'business',
                  icon: { $component: 'IconOtherServices' },
                  activeIcon: { $component: 'IconOtherServicesFilled' },
                  title: t('VERTICAL_NAV_BUSINESS_SOLUTIONS_LABEL'),
                  titleShort: t('VERTICAL_NAV_BUSINESS_SOLUTIONS_SHORT_LABEL'),
                  href: '/business-solutions',
                  tracking: {
                    sc_o: 'navigation_business_solutions',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_business_solutions',
                      NAVIGATION_BADGE_NO_BADGE,
                    ],
                  },
                },
              },
            ],
          ],
        },
      },
    ],
  ];
};
