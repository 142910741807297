import type { BottomNavigationConfig } from '@xing-com/platform-layout-bottom-bar';

import type { ConfigurationItem, Translate, Language } from '../types';

const NAVIGATION_BADGE_NO_BADGE = 'navigation_badge_no_badge';

export const bottomNavigation: ConfigurationItem = (
  t: Translate,
  _lang: Language
): BottomNavigationConfig => [
  [
    'MainList',
    {
      'data-testid': 'MainList',
      children: {
        $meta: [
          [
            'MainListItem',
            {
              item: {
                key: 'jobs',
                icon: { $component: 'IconJobs' },
                activeIcon: { $component: 'IconJobsFilled' },
                title: t('MAINNAV_JOBS_FIND'),
                href: '/jobs/find',
                tracking: {
                  sc_o: 'navigation_find_jobs',
                  sc_o_PropActionOrigin: [
                    'navigation_badge_find_jobs',
                    NAVIGATION_BADGE_NO_BADGE,
                  ],
                },
              },
            },
          ],
          [
            'MainListItem',
            {
              item: {
                key: 'my-jobs',
                icon: { $component: 'IconBookmark' },
                activeIcon: { $component: 'IconBookmarkFilled' },
                title: t('BOTTOM_BAR_YOUR_JOBS_LABEL'),
                href: '/jobs/my-jobs',
                tracking: {
                  sc_o: 'navigation_your_jobs',
                  sc_o_PropActionOrigin: [
                    'navigation_badge_your_jobs',
                    NAVIGATION_BADGE_NO_BADGE,
                  ],
                },
              },
            },
          ],
          [
            'MainListItem',
            {
              item: {
                key: 'contacts',
                icon: { $component: 'IconContacts' },
                activeIcon: { $component: 'IconContactsFilled' },
                title: t('MAINNAV_NETWORK'),
                activeItemValues: ['members'],
                href: '/network',
                tracking: {
                  sc_o: 'navigation_network',
                  sc_o_PropActionOrigin: [
                    'navigation_badge_network',
                    NAVIGATION_BADGE_NO_BADGE,
                  ],
                },
              },
            },
          ],
          [
            'MainListItem',
            {
              item: {
                key: 'disco',
                icon: { $component: 'IconNews' },
                activeIcon: { $component: 'IconNewsFilled' },
                title: t('MAINNAV_INSIGHTS'),
                href: '/discover/updates',
                tracking: {
                  sc_o: 'navigation_insights',
                  sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
                },
              },
            },
          ],

          [
            'MainListItem',
            {
              item: {
                key: 'supi',
                icon: {
                  $component: 'IconNotificationCenter',
                },
                activeIcon: {
                  $component: 'IconNotificationFilled',
                },
                title: t('BOTTOM_BAR_NOTIFICATIONS_LABEL'),
                href: '/notifications',
                notificationKey: 'ncWeb',
                tracking: {
                  sc_o: 'navigation_notifications',
                  sc_o_PropActionOrigin: [
                    'navigation_badge_notifications',
                    NAVIGATION_BADGE_NO_BADGE,
                  ],
                },
              },
            },
          ],
        ],
      },
    },
  ],
];
