/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import devNote from '@xing-com/dev-note';

import type { PortalProps } from './portal.types';

export const Portal: React.FC<PortalProps> = ({ children, containerId }) => {
  const portalContainer = React.useRef<HTMLDivElement | HTMLElement | null>(
    null
  );

  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);

    if (containerId) {
      portalContainer.current = document.getElementById(containerId);
      return undefined;
    } else {
      const element = document.createElement('div');
      portalContainer.current = element;

      document.body.appendChild(element);
      return () => {
        document.body.removeChild(element);
      };
    }
  }, [containerId]);

  if (!mounted) {
    return null;
  }
  if (!portalContainer.current) {
    devNote.warn(
      `You passed a containerId of ${containerId} to @xing-com/portal but we could not find the container in your DOM. Nothing is rendered now. Please check why the container is not present.`
    );
    return null;
  }

  return ReactDOM.createPortal(children, portalContainer.current);
};
