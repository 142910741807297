import type { ExperimentType, RegisteredExperiment, Variant } from './typings';

const experiments: Record<string, RegisteredExperiment> = {};

export const registerExperiment = (
  name: string,
  supportedVariants: ['A', ...Variant[]],
  experimentType: ExperimentType = 'user'
): void => {
  experiments[`${name}/${experimentType}`] = {
    name,
    supportedVariants,
    experimentType,
  };
};

export const getRegisteredExperiments = (): RegisteredExperiment[] => {
  return Object.values(experiments);
};
