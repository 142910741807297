import * as React from 'react';

import * as Styled from './dot-loading-indicator.styles';
import type { DotLoadingIndicatorProps } from './dot-loading-indicator.types';

export const DotLoadingIndicator = React.forwardRef<
  HTMLDivElement,
  DotLoadingIndicatorProps
>(
  ({ className, ...props }, forwardedRef): JSX.Element => (
    <Styled.DotLoadingIndicator
      className={className}
      data-xds="DotLoadingIndicator"
      ref={forwardedRef}
      {...props}
    >
      <Styled.Dot1 />
      <Styled.Dot2 />
      <Styled.Dot3 />
    </Styled.DotLoadingIndicator>
  )
);

DotLoadingIndicator.displayName = 'DotLoadingIndicator';
