/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconMessagesFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconMessagesFilled"
    {...props}
  >
    <path fill="currentColor" d="M13 18v4l-5.333-4H2V6h16v12z" />
    <path fill="currentColor" d="M20 4H7V2h15v11h-2z" />
  </svg>
);
export default IconMessagesFilled;
