import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { zIndexStickyLayer3 } from '@xing-com/layout-tokens';

const StyledButton = styled(TextButton)`
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${zIndexStickyLayer3};
`;

export { StyledButton };
