import * as React from 'react';

import * as Styled from './marker.styles';
import type { MarkerProps } from './marker.types';

export const Marker = React.forwardRef<HTMLSpanElement, MarkerProps>(
  (
    {
      children,
      className,
      ellipsis,
      icon: Icon,
      iconAfter,
      maxCharacters = 21,
      variant = 'default',
      ...props
    },
    forwardedRef
  ): JSX.Element => (
    <Styled.Marker
      $ellipsis={ellipsis}
      $variant={variant}
      className={className}
      data-xds="Marker"
      ref={forwardedRef}
      {...props}
    >
      {Icon && (
        <Styled.IconContainer $iconAfter={iconAfter}>
          <Icon width="14" height="14" />
        </Styled.IconContainer>
      )}
      <Styled.Text
        $ellipsis={ellipsis}
        $iconAfter={iconAfter}
        $maxCharacters={String(maxCharacters)}
        $variant={variant}
        forwardedAs="span"
        lineHeight="dense"
        noMargin
        size="small"
      >
        {children}
      </Styled.Text>
    </Styled.Marker>
  )
);

Marker.displayName = 'Marker';
