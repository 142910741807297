import styled from 'styled-components';

import {
  CONTAINER_EXPANDED,
  CONTAINER_NARROW,
  CONTAINER_REGULAR,
  CONTAINER_WIDE,
  MEDIA_MEDIUM,
} from './constants';

export type ContainerVariant = 'narrow' | 'regular' | 'wide' | 'expanded';

const widths = {
  narrow: CONTAINER_NARROW,
  regular: CONTAINER_REGULAR,
  wide: CONTAINER_WIDE,
  expanded: CONTAINER_EXPANDED,
};

export const Container = styled.div<{ variant?: ContainerVariant }>`
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: ${({ variant = 'regular' }) => widths[variant]}px;
  ${MEDIA_MEDIUM} {
    padding-inline: 0;
  }
`;
