import * as React from 'react';

import { Styled } from './body-copy.styles';
import type { BodyCopyProps } from './body-copy.types';

export const BodyCopy = React.forwardRef<HTMLParagraphElement, BodyCopyProps>(
  (
    {
      children,
      className,
      ellipsis,
      fontWeight = 'regular',
      lineHeight,
      noMargin,
      size = 'medium',
      sizeConfined,
      sizeWide,
      ...props
    },
    forwardedRef
  ): JSX.Element => (
    <Styled.BodyCopy
      $ellipsis={ellipsis}
      $fontWeight={fontWeight}
      $lineHeight={lineHeight}
      $noMargin={noMargin}
      $size={size}
      $sizeConfined={sizeConfined}
      $sizeWide={sizeWide}
      className={className}
      data-xds="BodyCopy"
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Styled.BodyCopy>
  )
);

BodyCopy.displayName = 'BodyCopy';
