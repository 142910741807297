import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import {
  scale050,
  scale070,
  scale100,
  scale120,
  scale140,
  spaceM,
} from '@xing-com/tokens';

import { SkeletonRectangle } from '../';
import type {
  SkeletonHeadlineAlignment,
  SkeletonHeadlineSize,
} from './skeleton-headline.types';

type HeadlineTransientProps = {
  $alignment?: SkeletonHeadlineAlignment;
};

type HeadlineRowTransientProps = {
  $amountRows: number;
  $size?: SkeletonHeadlineSize;
  $sizeConfined?: SkeletonHeadlineSize;
  $sizeWide?: SkeletonHeadlineSize;
};

// *** Sizes ***
export const sizes = {
  small: css`
    height: ${scale100};
    margin-block-end: ${scale050};
  `,
  medium: css`
    height: ${scale120};
    margin-block-end: ${spaceM};
  `,
  large: css`
    height: ${scale140};
    margin-block-end: ${scale070};
  `,
};

// *** Text Alignments ***
export const alignments = {
  left: css`
    justify-content: flex-start;
  `,
  center: css`
    justify-content: center;
  `,
  right: css`
    justify-content: flex-end;
  `,
};

// *** Components ***
export const Headline = styled.div<HeadlineTransientProps>`
  display: flex;
  flex-wrap: wrap;
  ${({ $alignment }) => $alignment && alignments[$alignment]};
`;

export const HeadlineRow = styled(SkeletonRectangle)<HeadlineRowTransientProps>`
  ${({ $size }) => $size && sizes[$size]};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]};
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]};
      }
    `};

  ${({ $amountRows }) =>
    $amountRows > 1 &&
    css`
      &:last-child {
        width: 66%;
      }
    `};

  &:last-child {
    margin-block-end: 0;
  }
`;
