/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconNews = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconNews"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18 3v7h4v8a3 3 0 0 1-2.824 2.995L19 21H5a3 3 0 0 1-3-3V3zm-2 2H4v13a1 1 0 0 0 1 1h11zm4 7h-2v6a1 1 0 1 0 2 0zm-6 1v2H6v-2zm0-5v2H6V8z"
    />
  </svg>
);
export default IconNews;
