import styled from 'styled-components';

import { IconCross } from '@xing-com/icons';
import {
  xdlColorBackground,
  xdlColorText,
  spaceS,
  spaceL,
  space3XL,
  scale190,
} from '@xing-com/tokens';

type HeaderProps = { onClose: () => void };

const HeaderComponent = styled.header`
  height: ${scale190};
  top: ${scale190};
`;

const ButtonComponent = styled.button`
  background: ${xdlColorBackground};
  border: none;
  border-radius: 100%;
  color: ${xdlColorText};
  cursor: pointer;
  height: ${space3XL};
  padding: ${spaceS};
  position: fixed;
  right: ${spaceL};
  top: ${spaceS};
  width: ${space3XL};
  z-index: 18;
`;
const Header: React.FC<HeaderProps> = ({ onClose }) => {
  return (
    <HeaderComponent>
      <ButtonComponent data-testid="close-button" onClick={onClose}>
        <IconCross height={32} width={32} />
      </ButtonComponent>
    </HeaderComponent>
  );
};

Header.displayName = 'OmViewHeader';

export { Header };
