import * as React from 'react';

import { FeatureCondition } from '@xing-com/feature-condition';
import { FeatureSwitch } from '@xing-com/feature-switch';
import {
  IconBookmark,
  IconBookmarkFilled,
  IconBusinessSolutions,
  IconBusinessSolutionsFilled,
  IconCampus,
  IconCampusFilled,
  IconCompanies,
  IconCompaniesFilled,
  IconContact,
  IconContactFilled,
  IconContacts,
  IconContactsFilled,
  IconFilter,
  IconHome,
  IconHomeFilled,
  IconJobs,
  IconJobsFilled,
  IconLogout,
  IconMessages,
  IconMessagesFilled,
  IconMore,
  IconNewChat,
  IconNews,
  IconNewsFilled,
  IconNotificationCenter,
  IconNotificationFilled,
  IconOtherServices,
  IconOtherServicesFilled,
  IconPlus,
  IconPremium,
  IconPremiumFilled,
  IconProBusiness,
  IconProBusinessFilled,
  IconProJobs,
  IconProJobsFilled,
  IconRocket,
  IconSearch,
  IconSettings,
} from '@xing-com/icons';
import {
  NotLoggedOut,
  NotOnLarge,
  NotOnSmall,
  OnlyLoggedOut,
  OnlyOnLarge,
  OnlyOnSmall,
} from '@xing-com/layout-primitives';
import { SplitKitSwitch } from '@xing-com/splitkit';

import { Spacer } from './spacer';

function HtmlElement<T extends keyof JSX.IntrinsicElements>({
  as: Component,
  ...props
}: {
  as: T;
} & Omit<React.ComponentProps<T>, 'as'>): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
  return <Component {...(props as any)} />;
}

const HasComponent: React.FC<
  React.PropsWithChildren<{
    component?: React.ReactNode;
    elseCase?: React.ReactNode;
  }>
> = ({ children, component, elseCase }) => {
  return <>{component ? children : elseCase}</>;
};

export const utils = {
  HtmlElement,
  ReactFragment: React.Fragment,
  Spacer,
};

export const conditions = {
  FeatureCondition,
  FeatureSwitch,
  HasComponent,
  SplitKitSwitch,
  OnlyOnSmall,
  NotOnSmall,
  OnlyOnLarge,
  NotOnLarge,
  OnlyLoggedOut,
  NotLoggedOut,
};

export const icons = {
  IconBookmark,
  IconBookmarkFilled,
  IconBusinessSolutions,
  IconBusinessSolutionsFilled,
  IconCampus,
  IconCampusFilled,
  IconCompanies,
  IconCompaniesFilled,
  IconContact,
  IconContactFilled,
  IconContacts,
  IconContactsFilled,
  IconFilter,
  IconHome,
  IconHomeFilled,
  IconJobs,
  IconJobsFilled,
  IconLogout,
  IconMessages,
  IconMessagesFilled,
  IconMore,
  IconNewChat,
  IconNews,
  IconNewsFilled,
  IconNotificationCenter,
  IconNotificationFilled,
  IconOtherServices,
  IconOtherServicesFilled,
  IconPlus,
  IconPremium,
  IconPremiumFilled,
  IconProBusiness,
  IconProBusinessFilled,
  IconProJobs,
  IconProJobsFilled,
  IconRocket,
  IconSearch,
  IconSettings,
};

export const commonMetaRendererResolvers = {
  ...utils,
  ...conditions,
  ...icons,
};
