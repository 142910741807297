/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconContactsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconContactsFilled"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.42 14C14.6 14 16 18.8 16 20H2c0-1.2 1.4-6 5.58-6zm7.594 1C21 15 22 19 22 20h-4c0-.85-.66-3.247-2-5zM17.5 9a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5M9 3c1.38 0 2.63.56 3.536 1.464A4.98 4.98 0 0 1 14 8c0 1.38-.56 2.63-1.464 3.536A4.98 4.98 0 0 1 9 13c-1.38 0-2.63-.56-3.536-1.464A4.98 4.98 0 0 1 4 8c0-1.38.56-2.63 1.464-3.536A4.98 4.98 0 0 1 9 3"
      clipRule="evenodd"
    />
  </svg>
);
export default IconContactsFilled;
