import * as React from 'react';

import { Styled } from './meta.styles';
import type { MetaProps } from './meta.types';

export const Meta = React.forwardRef<HTMLParagraphElement, MetaProps>(
  (
    {
      children,
      className,
      ellipsis,
      fontWeight = 'regular',
      lineHeight,
      noMargin,
      size,
      sizeConfined,
      sizeWide,
      ...props
    },
    forwardedRef
  ): JSX.Element => (
    <Styled.Meta
      className={className}
      data-xds="Meta"
      ellipsis={ellipsis}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      noMargin={noMargin}
      ref={forwardedRef}
      size={size}
      sizeConfined={sizeConfined}
      sizeWide={sizeWide}
      {...props}
    >
      {children}
    </Styled.Meta>
  )
);

Meta.displayName = 'Meta';
