import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import type { HelmetProps } from 'react-helmet-async';

// eslint-disable-next-line import/order
import { NormalizeStyles } from './normalize-styles';
// eslint-disable-next-line import/order
import { BaseStyles } from './base-styles';

import fontXingSansBoldItalicWoff from '@xing-com/fonts/assets/XING-Sans-Bold-Italic.woff';
import fontXingSansBoldItalicWoff2 from '@xing-com/fonts/assets/XING-Sans-Bold-Italic.woff2';
import fontXingSansBoldWoff from '@xing-com/fonts/assets/XING-Sans-Bold.woff';
import fontXingSansBoldWoff2 from '@xing-com/fonts/assets/XING-Sans-Bold.woff2';
import fontXingSansItalicWoff from '@xing-com/fonts/assets/XING-Sans-Italic.woff';
import fontXingSansItalicWoff2 from '@xing-com/fonts/assets/XING-Sans-Italic.woff2';
import fontXingSansMediumItalicWoff from '@xing-com/fonts/assets/XING-Sans-Medium-Italic.woff';
import fontXingSansMediumItalicWoff2 from '@xing-com/fonts/assets/XING-Sans-Medium-Italic.woff2';
import fontXingSansMediumWoff from '@xing-com/fonts/assets/XING-Sans-Medium.woff';
import fontXingSansMediumWoff2 from '@xing-com/fonts/assets/XING-Sans-Medium.woff2';
import fontXingSansRegularWoff from '@xing-com/fonts/assets/XING-Sans-Regular.woff';
import fontXingSansRegularWoff2 from '@xing-com/fonts/assets/XING-Sans-Regular.woff2';

const meta = [
  {
    name: 'viewport',
    content: 'width=device-width, minimum-scale=1',
  },
];

const fonts = {
  normal: {
    fontWeight: 400,
    fontStyle: 'normal',
    woff2: fontXingSansRegularWoff2,
    woff: fontXingSansRegularWoff,
  },
  medium: {
    fontWeight: 700,
    fontStyle: 'bold',
    woff2: fontXingSansMediumWoff2,
    woff: fontXingSansMediumWoff,
  },
  bold: {
    fontWeight: 700,
    fontStyle: 'bold',
    woff2: fontXingSansBoldWoff2,
    woff: fontXingSansBoldWoff,
  },
  normalItalic: {
    fontWeight: 400,
    fontStyle: 'italic',
    woff2: fontXingSansItalicWoff2,
    woff: fontXingSansItalicWoff,
  },
  mediumItalic: {
    fontWeight: 700,
    fontStyle: 'italic',
    woff2: fontXingSansMediumItalicWoff2,
    woff: fontXingSansMediumItalicWoff,
  },
  boldItalic: {
    fontWeight: 700,
    fontStyle: 'italic',
    woff2: fontXingSansBoldItalicWoff2,
    woff: fontXingSansBoldItalicWoff,
  },
};

const isDev = process.env.NODE_ENV === 'development';
const usedFonts = [fonts.normal, fonts.bold];

const fontsString = usedFonts
  .map(({ fontWeight, fontStyle, woff, woff2 }) => {
    return `
      @font-face {
        font-family: 'XING Sans';
        font-display: block;
        font-style: ${fontStyle};
        font-weight: ${fontWeight};
        src: url(${woff2}) format("woff2"),
        url(${woff}) format("woff");
      }`;
  })
  .join('')
  .replace(/\n|^\s+/gm, '');

// preload web fonts
const link = usedFonts.map(({ woff2 }) => ({
  href: woff2,
  rel: 'preload',
  as: 'font',
  crossorigin: 'anonymous',
  type: 'font/woff2',
}));

const style = [
  {
    cssText: fontsString,
  },
  {
    cssText: `body {font-family: 'XING Sans', 'Trebuchet MS', Arial, 'Helvetica Neue', sans-serif;}`,
  },
];

const noscript = isDev
  ? [
      {
        innerHTML: `<style type="text/css">body { visibility: visible; }</style>`,
      },
    ]
  : [];

const props: HelmetProps = {
  meta,
  noscript,
  bodyAttributes: isDev ? { class: 'development' } : {},
  link,
  style,
};

const GlobalStyles: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <React.Fragment>
      <NormalizeStyles />
      <BaseStyles />
      <Helmet {...props} />
      {children}
    </React.Fragment>
  );
};
export default GlobalStyles;
