import * as React from 'react';

import * as Styled from './progress-badge.styles';
import type { ProgressBadgeProps } from './progress-badge.types';

/** Avoid progress being lower than 0% or beyond 100% */
const clampProgress = (progress: number): number =>
  Math.min(Math.max(0, progress), 100);

export const ProgressBadge = React.forwardRef<
  HTMLSpanElement,
  ProgressBadgeProps
>(({ className, progress = 0, ...props }, forwardedRef): JSX.Element => {
  const sanitizedProgress = progress && clampProgress(progress);

  return (
    <Styled.ProgressBadge
      $progress={sanitizedProgress}
      className={className}
      data-xds="ProgressBadge"
      ref={forwardedRef}
      {...props}
    >
      <Styled.Text forwardedAs="span" fontWeight="bold" noMargin size="xsmall">
        {`${sanitizedProgress}%`}
      </Styled.Text>
    </Styled.ProgressBadge>
  );
});

ProgressBadge.displayName = 'ProgressBadge';
