import styled, { css } from 'styled-components';

import {
  background_01_1x1_a,
  background_01_4x3_a,
  background_01_7x2_a,
} from '@xing-com/background-images';
import { spaceXL } from '@xing-com/tokens';

import {
  MEDIA_LARGE,
  MEDIA_MEDIUM,
  MEDIA_XSMALL,
  MEDIA_XLARGE,
  MIN_WIDTH,
  PADDING_MEDIUM_LARGE,
  PADDING_XSMALL_SMALL,
  PADDING_WIDE,
} from './constants';

export const Section = styled.section<{
  noBlockPadding?: boolean;
  noPagePadding?: boolean;
}>`
  padding-block: ${({ noBlockPadding }) => (noBlockPadding ? 0 : spaceXL)};
  padding-inline: ${({ noPagePadding }) =>
    noPagePadding ? 0 : PADDING_XSMALL_SMALL};
  min-width: ${MIN_WIDTH}px;

  ${MEDIA_MEDIUM} {
    padding-inline: ${({ noPagePadding }) =>
      noPagePadding ? 0 : PADDING_MEDIUM_LARGE};
  }

  ${MEDIA_XLARGE} {
    padding-inline: ${({ noPagePadding }) =>
      noPagePadding ? 0 : PADDING_WIDE};
  }
`;

export const BackgroundSection = styled(Section)<{ spacious?: boolean }>`
  background-image: url(${background_01_7x2_a});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 15%;

  ${MEDIA_XSMALL} {
    background-image: url(${background_01_1x1_a});
    background-position-y: 77%;
  }

  ${MEDIA_LARGE} {
    background-size: 110%;
    background-position-x: 55%;
  }

  ${({ spacious }) =>
    spacious &&
    css`
      background-image: url(${background_01_4x3_a});

      ${MEDIA_LARGE} {
        background-image: url(${background_01_7x2_a});
        background-size: cover;
      }
    `};
`;
