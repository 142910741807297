import * as React from 'react';

import { scale110 } from '@xing-com/tokens';

import { SkeletonCircle } from '../';
import type { SkeletonRadioButtonProps } from '../';

export const SkeletonRadioButton = React.forwardRef<
  HTMLDivElement,
  SkeletonRadioButtonProps
>(
  ({ className, ...props }, forwardedRef): JSX.Element => (
    <SkeletonCircle
      className={className}
      data-xds="SkeletonRadioButton"
      ref={forwardedRef}
      size={scale110}
      {...props}
    />
  )
);

SkeletonRadioButton.displayName = 'SkeletonRadioButton';
