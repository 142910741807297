import styled, { css } from 'styled-components';

import { spaceS, spaceL, spaceXL } from '@xing-com/tokens';

import { SkeletonRectangle } from '..';
import { SkeletonHeadline } from '../headline/skeleton-headline';

type CardTransientProps = {
  $noPadding?: boolean;
};

// *** Components ***
export const Card = styled(SkeletonRectangle)<CardTransientProps>`
  ${({ $noPadding }) =>
    $noPadding
      ? null
      : css`
          padding: ${spaceL};
        `};
`;

export const ImageContainer = styled.div`
  display: flex;
  margin-block-end: ${spaceXL};
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  align-items: flex-end;
  display: flex;
  margin-block-start: ${spaceXL};
  flex-grow: 1;
`;

export const CardHeadline = styled(SkeletonHeadline)`
  & > div {
    margin-block-end: ${spaceS};
  }
`;

export const ImageCardContent = styled.div`
  padding: ${spaceL};
`;
