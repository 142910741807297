import * as React from 'react';

import {
  FallbackImageAI,
  FallbackImageAvatar,
  FallbackImageCompany,
  FallbackImageGroups,
  FallbackImageTopic,
  FallbackImageUserFemale,
  FallbackImageUserMale,
  FallbackImageUserNeutral,
} from '@xing-com/illustrations';

import type { FallbackImageType } from '.';

export const FallbackImage = ({
  type,
  ...props
}: {
  type: FallbackImageType;
}): JSX.Element => {
  const renderFallbackImage = (type: FallbackImageType): JSX.Element => {
    switch (type) {
      case 'company':
        return <FallbackImageCompany {...props} />;
      case 'groups':
        return <FallbackImageGroups {...props} />;
      case 'topic':
        return <FallbackImageTopic {...props} />;
      case 'ai':
        return <FallbackImageAI {...props} />;
      case 'avatar':
        return <FallbackImageAvatar {...props} />;
      case 'female-user':
        return <FallbackImageUserFemale {...props} />;
      case 'male-user':
        return <FallbackImageUserMale {...props} />;
      case 'neutral-user':
        return <FallbackImageUserNeutral {...props} />;
      default:
        return <FallbackImageUserNeutral {...props} />;
    }
  };

  return <React.Fragment>{renderFallbackImage(type)}</React.Fragment>;
};
