import type { PropsWithChildren, FC } from 'react';
import { useState } from 'react';

import { BottomBarEnabledContext } from '@xing-com/hub';

export const BottomBarEnabledModule: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isBottomBarEnabled, setIsBottomBarEnabled] = useState(true);

  return (
    <BottomBarEnabledContext.Provider
      value={{ isBottomBarEnabled, setIsBottomBarEnabled }}
    >
      {children}
    </BottomBarEnabledContext.Provider>
  );
};
