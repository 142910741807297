import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { MetaRenderer } from '@xing-com/meta-renderer';
import type { Config, ConfigForResolvers } from '@xing-com/meta-renderer';
import { Logo } from '@xing-com/platform-layout-logo';
import {
  scale090,
  scale150,
  scale200,
  spaceL,
  spaceM,
  spaceXS,
  spaceXXL,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

import { commonResolvers } from './common-resolvers';
import { LegalFooterNavItem as FooterNavItem } from './legal-footer-nav-item';

const FooterBranding: FC<{ homeLink: string }> = ({ homeLink }) => {
  const { formatMessage } = useIntl();
  return (
    <StyledBranding data-qa="frame-footer-legal-branding">
      <StyledLogo dataQa="logo" homeLink={homeLink} variant="duo" />
      <span>
        <FormattedMessage
          id="MALT_FOOTER_COPYRIGHT_API"
          values={{
            siteoperator: formatMessage({
              id: 'VERTICAL_NAVIGATION_FOOTER_NEWWORKSE',
            }),
          }}
        />
      </span>
    </StyledBranding>
  );
};

const FooterBox = styled.div`
  color: ${xdlColorTextSecondary};

  @media ${mediaSmallOrTiny} {
    padding-left: ${spaceL};
    padding-right: ${spaceL};
    margin-bottom: ${scale090};
    margin-top: ${spaceM};
  }

  @media ${mediaConfined} {
    margin-bottom: ${scale200};
    margin-top: ${scale150};
  }
`;

const StyledBranding = styled.div`
  @media ${mediaSmallOrTiny} {
    margin-bottom: ${spaceXXL};
  }

  @media ${mediaConfined} {
    display: flex;
    align-items: center;
  }
`;

const FooterNav = styled.nav``;

const FooterNavLinks = styled.ul`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;

  @media ${mediaConfined} {
    flex-direction: row;
  }
`;

const FooterLanguage = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spaceL};

  @media ${mediaSmallOrTiny} {
    margin-right: ${spaceL};
    margin-top: ${spaceM};
  }
`;

const StyledLogo = styled(Logo)`
  flex: 0 0 64px;
  margin-bottom: ${spaceM};
  margin-right: ${spaceL};
  margin-top: ${spaceXS};
  padding-bottom: ${spaceXS};

  & svg {
    height: 29px;
    width: 64px;
  }
`;

const FooterLanguageLabel = styled.p`
  margin: 0;
  padding-right: ${spaceM};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ButtonIgnoringHomeLink: FC<{ homeLink: string }> = ({
  homeLink,
  ...props
}) => <Button {...props} />;

const resolvers = {
  ...commonResolvers,
  FooterNavItem,
  FooterBox,
  FooterBranding,
  FooterNav,
  FooterNavLinks,
  FooterLanguage,
  FooterLanguageLabel,
  Button: ButtonIgnoringHomeLink,
};

export type LegalFooterConfig = ConfigForResolvers<typeof resolvers>;

export const LegalFooterWithBranding: React.FC<{
  homeLink: string;
  config: Config;
}> = ({ homeLink, config }) => {
  return (
    <MetaRenderer resolvers={resolvers} config={config} homeLink={homeLink} />
  );
};
