import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Portal } from '@xing-com/portal';

import { BottomSheetPrimitive } from './bottom-sheet.primitive';
import * as Styled from './bottom-sheet.styles';
import type { BottomSheetProps } from './bottom-sheet.types';
const {
  bottomSheetDuration,
  bottomSheetTransitionName,
  dimmerDuration,
  dimmerTransitionName,
} = Styled;

export const BottomSheet = React.forwardRef<HTMLDivElement, BottomSheetProps>(
  (
    {
      children,
      className,
      dimmerTitle,
      keepBottomSheetInDom,
      onOutsideClick,
      show = false,
      showCloseButton = true,
      shouldDisableBodyScroll = true,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const disableScrolling = (): void => {
      document.body.style.overflow = 'hidden';
    };

    const enableScrolling = (): void => {
      document.body.style.overflow = 'visible';
    };

    const closeModal = (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      enableScrolling();
      onOutsideClick && onOutsideClick(event);
    };

    React.useEffect(() => {
      if (!shouldDisableBodyScroll) {
        return;
      }

      if (show) {
        disableScrolling();
      }

      return () => {
        enableScrolling();
      };
    }, [show, shouldDisableBodyScroll]);

    const BottomSheetTransition = (
      <CSSTransition
        appear={show}
        classNames={bottomSheetTransitionName}
        in={show}
        key="bottomSheet"
        mountOnEnter={!keepBottomSheetInDom}
        timeout={parseInt(bottomSheetDuration)}
        unmountOnExit={!keepBottomSheetInDom}
      >
        <BottomSheetPrimitive
          className={className}
          closeModal={closeModal}
          data-show={show}
          ref={forwardedRef}
          showCloseButton={showCloseButton}
          {...props}
        >
          {children}
        </BottomSheetPrimitive>
      </CSSTransition>
    );

    const DimmerTransition = (
      <CSSTransition
        appear={show}
        classNames={dimmerTransitionName}
        in={show}
        key="dimmer"
        mountOnEnter
        onEnter={() => {
          if (shouldDisableBodyScroll) {
            disableScrolling();
          }
        }}
        onExited={() => {
          if (shouldDisableBodyScroll) {
            enableScrolling();
          }
        }}
        timeout={parseInt(dimmerDuration)}
        unmountOnExit
      >
        <Styled.Dimmer
          $isNotClickable={!onOutsideClick}
          data-qa="bottom-sheet-dimmer"
          data-testid="bottom-sheet-dimmer"
          onClick={closeModal}
          title={dimmerTitle}
        />
      </CSSTransition>
    );

    return (
      <React.Fragment>
        <Portal>{BottomSheetTransition}</Portal>
        {DimmerTransition}
      </React.Fragment>
    );
  }
);

BottomSheet.displayName = 'BottomSheet';
