const getDirections = (
  triggerRect: DOMRect,
  overlayRect: DOMRect
): { isLeft: boolean; isUp: boolean } => {
  const collisions = {
    top: overlayRect.height > triggerRect.top,
    right: triggerRect.left + overlayRect.width > window.innerWidth,
    bottom: triggerRect.bottom + overlayRect.height > window.innerHeight,
    left: overlayRect.width > triggerRect.right,
  };

  return {
    isLeft: collisions.right && !collisions.left,
    isUp: collisions.bottom && !collisions.top,
  };
};

/**
 * Not all mobile browser calculate window.innerHeight correctly when the software keyboard
 * pops up. Therefore alwaysUp helps to fix the overlay position when necessary.
 */
export const autoPositionOverlay = (
  triggerRect: DOMRect,
  overlayRect: DOMRect,
  alwaysUp = false,
  isInFlow = false,
  offset = 0
):
  | {
      transform: string;
    }
  | {
      left: string;
      top: string;
    } => {
  const { isLeft, isUp } = getDirections(triggerRect, overlayRect);

  const absolutePosition = {
    left: isLeft
      ? `${triggerRect.right - overlayRect.width + window.pageXOffset}px`
      : `${triggerRect.left + window.pageXOffset}px`,
    top:
      alwaysUp || isUp
        ? `${
            triggerRect.top - offset - overlayRect.height + window.pageYOffset
          }px`
        : `${
            triggerRect.top + offset + triggerRect.height + window.pageYOffset
          }px`,
  };

  const translateX = isLeft ? triggerRect.width - overlayRect.width : 0;
  const translateY = isUp ? -triggerRect.height - overlayRect.height : 0;
  const transform = {
    transform: `translate(${translateX}px, ${translateY}px)`,
  };

  return isInFlow ? transform : absolutePosition;
};

/**
 * Accepts two refs a trigger and a container
 * out of those two this function returns auto positioning styles for overlays that should be
 * positioned absolute or fixed
 *
 * eg trigger is at the bottom of the page
 * container will be pushed upwards and not downwards
 *
 * @example {left: 0px; top: 33px;}
 */
export const autoPositionByRef = (
  triggerRef: React.RefObject<HTMLElement> | undefined,
  overlayRef: React.RefObject<HTMLElement> | undefined,
  isInFlow = false,
  offset = 0,
  alwaysUp = false
): React.CSSProperties => {
  if (triggerRef && triggerRef.current && overlayRef && overlayRef.current) {
    const style = autoPositionOverlay(
      triggerRef.current.getBoundingClientRect(),
      overlayRef.current.getBoundingClientRect(),
      alwaysUp,
      isInFlow,
      offset
    );
    return { ...style };
  } else {
    return {};
  }
};
