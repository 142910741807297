import * as React from 'react';

import * as Styled from './menu-headline.styles';
import type { MenuHeadlineProps } from './menu-headline.types';

export const MenuHeadline = React.forwardRef<
  HTMLParagraphElement,
  MenuHeadlineProps
>((props, forwardedRef): JSX.Element => {
  return (
    <Styled.MenuHeadline
      data-xds="MenuHeadline"
      ref={forwardedRef}
      {...props}
    />
  );
});

MenuHeadline.displayName = 'MenuHeadline';
