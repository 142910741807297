import * as React from 'react';

import * as Styled from './skeleton-circle.styles';
import type { SkeletonCircleProps } from './skeleton-circle.types';

export const SkeletonCircle = React.forwardRef<
  HTMLDivElement,
  SkeletonCircleProps
>(
  ({ className, size, ...props }, forwardedRef): JSX.Element => (
    <Styled.SkeletonCircle
      $size={size}
      className={className}
      data-xds="SkeletonCircle"
      ref={forwardedRef}
      {...props}
    />
  )
);

SkeletonCircle.displayName = 'SkeletonCircle';
