const BLUR_FACTOR = 0.06;

export const calculateBlur = (size?: number): string => {
  const fallbackSize = '5px';

  if (size !== undefined && size > 0) {
    return `${size * BLUR_FACTOR}px`;
  } else {
    return fallbackSize;
  }
};
