import styled from 'styled-components';

import { BottomSheet as XdlBottomSheet } from '@xing-com/bottom-sheet';
import { PopOver as XdlPopOver } from '@xing-com/pop-over';
import { spaceM, spaceL } from '@xing-com/tokens';

// *** Components ***
export const PopOver = styled(XdlPopOver)`
  padding-bottom: ${spaceM};
  padding-left: ${spaceL};
  padding-right: ${spaceL};
  padding-top: ${spaceM};
`;

export const BottomSheet = styled(XdlBottomSheet)`
  & > div {
    padding-bottom: ${spaceM};
    padding-left: ${spaceL};
    padding-right: ${spaceL};
    padding-top: ${spaceM};
  }
`;
