import styled from 'styled-components';

import { spaceS, spaceM } from '@xing-com/tokens';
import { Meta } from '@xing-com/typography';

// *** Components ***
export const MenuHeadline = styled(Meta)`
  display: block;
  margin: 0;
  padding-bottom: ${spaceM};
  padding-left: ${spaceS};
  padding-right: ${spaceS};
  padding-top: ${spaceM};
`;
