import type { FC, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { usePopOver } from '@xing-com/pop-over';
import ttt from '@xing-com/ticktricktrack';

import { SettingsDropdownList } from './settings-dropdown-list';
import {
  SettingsDropdownButton,
  SettingsDropdownContainer,
  StyledMenu,
} from './settings-dropdown-x3.styles';

export type SettingsDropdownX3Props = {
  icon: FunctionComponent;
  title: string;
  helpLink?: string;
  /**
   * @deprecated Not used anymore, but needs to stay in meta config until all
   * app updated to the version that has this comment.
   */
  settingsType?: string;
};

export const SettingsDropdownX3: FC<SettingsDropdownX3Props> = ({
  icon,
  title,
  helpLink,
}) => {
  const { formatMessage } = useIntl();
  const buttonLabel = formatMessage({ id: 'A11Y_SETTINGS_DROPDOWN_LABEL' });
  const popOver = usePopOver();

  const toggleSettings = (): void => {
    popOver.togglePopOver();

    ttt.event('PropTrackAction', {
      PropTrackAction: 'navigation_settings_drop_down',
      PropActionOrigin: 'navigation_badge_no_badge',
    });
  };

  return (
    <SettingsDropdownContainer>
      <SettingsDropdownButton
        aria-label={buttonLabel}
        icon={icon}
        data-testid="SettingsDropdownX3-button"
        size="small"
        innerRef={popOver.triggerRef}
        onClick={toggleSettings}
      >
        {title}
      </SettingsDropdownButton>

      <StyledMenu
        data-testid="SettingsDropdownX3"
        isInFlow
        isStatic
        dimmerTitle="close menu"
        onOutsideClick={popOver.handleHide}
        show={popOver.show}
        triggerRef={popOver.triggerRef}
      >
        <SettingsDropdownList
          helpLink={helpLink}
          closeModal={popOver.handleHide}
        />
      </StyledMenu>
    </SettingsDropdownContainer>
  );
};
