import styled, { keyframes } from 'styled-components';

import {
  xdlColorSkeletonPrimary,
  xdlColorSkeletonPrimaryAnimation,
  motionTimeXXL,
  motionEasingStandard,
} from '@xing-com/tokens';

// *** Animations ***
const animation = keyframes`
  0% {
    background-color: ${xdlColorSkeletonPrimaryAnimation};
  }
  100% {
    background-color: ${xdlColorSkeletonPrimary};
  }
`;

export const SkeletonBase = styled.div`
  animation-direction: alternate;
  animation-duration: ${motionTimeXXL};
  animation-iteration-count: infinite;
  animation-name: ${animation};
  animation-timing-function: ${motionEasingStandard};
  background-color: ${xdlColorSkeletonPrimary};
`;
