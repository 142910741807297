import * as Styled from './marker.styles';
const { variants } = Styled;

export type MarkerVariant = keyof typeof variants;
export type MarkerProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Label of the Marker, should be text, icons are handled separately */
  children?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLSpanElement>;
  /** Activate text truncation */
  ellipsis?: boolean;
  /** Hand over an icon component for the Marker */
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  /** Position the icon on the right side of the Marker */
  iconAfter?: boolean;
  /** The maximum characters for truncation. Only applies when ellipsis is activated. */
  maxCharacters?: number;
  /** The variant of the Marker */
  variant?: MarkerVariant;
} & React.HTMLAttributes<HTMLSpanElement>;
