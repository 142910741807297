import type * as React from 'react';

import * as Styled from './skeleton-button.styles';
const { sizes } = Styled;

export type SkeletonButtonSize = keyof typeof sizes;
export type SkeletonButtonVariant = 'primary' | 'secondary';

export type SkeletonButtonProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLDivElement>;
  /** The size on mobile screens or larger */
  size?: SkeletonButtonSize;
  /** The size on tablet screens or larger */
  sizeConfined?: SkeletonButtonSize;
  /** The size on desktop screens or larger */
  sizeWide?: SkeletonButtonSize;
  /** @deprecated: variant secondary is deprecated */
  variant?: SkeletonButtonVariant;
} & React.HTMLAttributes<HTMLDivElement>;
