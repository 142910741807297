import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

import type { AsyncHookResult, Experiments } from '@xing-com/hub';
import { ExperimentsContext, useVisitorId } from '@xing-com/hub';

import { useExperimentsQuery } from './experiments-query';
import { useExperiment, useExperimentTrackingData } from './hooks';

const context = { useExperiment, useExperimentTrackingData, useExperiments };

const InternalContext = createContext<AsyncHookResult<Experiments>>({
  loading: true,
});

export const SplitkitStaticModule: FC<
  PropsWithChildren<{ experiments: AsyncHookResult<Experiments> }>
> = ({ children, experiments }) => {
  return (
    <InternalContext.Provider value={experiments}>
      <ExperimentsContext.Provider value={context}>
        {children}
      </ExperimentsContext.Provider>
    </InternalContext.Provider>
  );
};

export const SplitkitModule: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const visitorId = useVisitorId();
  const experiments = useExperimentsQuery(visitorId);

  return (
    <SplitkitStaticModule experiments={experiments}>
      {children}
    </SplitkitStaticModule>
  );
};

export function useExperiments(): AsyncHookResult<Experiments> {
  return useContext(InternalContext);
}
