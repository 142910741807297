import styled from 'styled-components';

import { spaceS } from '@xing-com/tokens';

// *** Components ***
export const Container = styled.div`
  display: flex;
  gap: ${spaceS};
  flex-shrink: 0;
`;
