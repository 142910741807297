import * as React from 'react';

import { scale110, scale150 } from '@xing-com/tokens';

import { SkeletonPillShape } from '../';
import type { SkeletonToggleProps } from '../';

export const SkeletonToggle = React.forwardRef<
  HTMLDivElement,
  SkeletonToggleProps
>(
  ({ className, ...props }, forwardedRef): JSX.Element => (
    <SkeletonPillShape
      className={className}
      data-xds="SkeletonToggle"
      height={scale110}
      ref={forwardedRef}
      width={scale150}
      {...props}
    />
  )
);

SkeletonToggle.displayName = 'SkeletonToggle';
