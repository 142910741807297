import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type * as React from 'react';
import { Helmet } from 'react-helmet-async';

import type { AppliedTheme, Theme } from '@xing-com/hub';
import { ThemingContext } from '@xing-com/hub';
import { GlobalStyles } from '@xing-com/tokens';

export function ThemingModule({
  activeThemeCookie,
  children,
}: React.PropsWithChildren<{ activeThemeCookie?: string }>): JSX.Element {
  const initialTheme =
    activeThemeCookie === 'light' || activeThemeCookie === 'dark'
      ? activeThemeCookie
      : 'system';
  const [overrideTheme, setOverrideTheme] = useState<Theme | undefined>();
  const theme = overrideTheme ?? initialTheme;

  const setSettingsTheme = useCallback((theme: Theme) => {
    if (typeof window === 'undefined') return;

    window.document.cookie = `activeTheme=${theme};path=/`;

    setOverrideTheme(theme);
  }, []);

  const context = useMemo(() => {
    return {
      setSettingsTheme,
      useSettingsTheme,
      useAppliedTheme,
    };
  }, [setSettingsTheme]);

  return (
    <InternalContext.Provider value={theme}>
      <ThemingContext.Provider value={context}>
        <Helmet htmlAttributes={{ class: theme }} />
        <GlobalStyles />
        {children}
      </ThemingContext.Provider>
    </InternalContext.Provider>
  );
}

const InternalContext = createContext<Theme>('system');

function useSettingsTheme(): Theme {
  return useContext(InternalContext);
}

function useAppliedTheme(): AppliedTheme {
  const theme = useSettingsTheme();
  const [prefersDark, setPrefersDark] = useState<boolean | undefined>();

  useEffect(() => {
    if (theme !== 'system') {
      return;
    }

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    setPrefersDark(mediaQuery.matches);

    const listener = (e: MediaQueryListEvent): void => {
      setPrefersDark(e.matches);
    };

    mediaQuery.addEventListener('change', listener);

    return () => mediaQuery.removeEventListener('change', listener);
  }, [theme]);

  return theme === 'system' ? (prefersDark === true ? 'dark' : 'light') : theme;
}
