/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconWarning"
    {...props}
  >
    <path
      fill="currentColor"
      d="m11.17 10.91.2 4.38h1.28l.2-4.38zm.84 5.29a.88.88 0 0 0-.645.265.87.87 0 0 0-.265.635q0 .38.265.645a.88.88 0 0 0 .645.265.87.87 0 0 0 .635-.265.88.88 0 0 0 .265-.645.87.87 0 0 0-.265-.635.87.87 0 0 0-.635-.265"
    />
    <path fill="currentColor" d="M12 3 2 21h20zm6.601 16H5.399L12 7.118z" />
  </svg>
);
export default IconWarning;
