import type * as React from 'react';

import * as Styled from './badge.styles';

const { sizes } = Styled;

export type BadgeSize = keyof typeof sizes;
export type BadgeProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Label of the badge */
  children?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLAnchorElement>;
  /** The size of badge on mobile screens or larger */
  size?: BadgeSize;
  /** The size of badge on tablet screens or larger */
  sizeConfined?: BadgeSize;
  /** The size of badge on desktop screens or larger */
  sizeWide?: BadgeSize;
  sizeXWide?: BadgeSize;
} & React.HTMLAttributes<HTMLAnchorElement>;
