import styled, { css } from 'styled-components';

// *** Base ***
const baseLabel = css`
  align-items: center;
  display: flex;
  position: relative;
`;

// *** Components ***
export const Label = styled.label`
  ${baseLabel};
`;
