import * as React from 'react';

import {
  SkeletonBodyCopy,
  SkeletonButton,
  SkeletonCard,
  SkeletonHeadline,
  SkeletonImage,
  SkeletonProfileImage,
} from '..';
import * as Styled from './skeleton-card.styles';
import type { SkeletonCardPresetProps } from './skeleton-card.types';

export const SkeletonCardPreset = React.forwardRef<
  HTMLDivElement,
  SkeletonCardPresetProps
>(
  ({ className, variant, ...props }, forwardedRef): JSX.Element => (
    <React.Fragment>
      {variant === 'profile' && (
        <SkeletonCard className={className} ref={forwardedRef} {...props}>
          <Styled.ImageContainer>
            <SkeletonProfileImage size="small" />
          </Styled.ImageContainer>
          <Styled.CardHeadline amountRows={1} />
          <SkeletonBodyCopy amountRows={3} />
        </SkeletonCard>
      )}
      {variant === 'text' && (
        <SkeletonCard className={className} ref={forwardedRef} {...props}>
          <Styled.FlexContainer>
            <div>
              <Styled.CardHeadline amountRows={1} />
              <SkeletonBodyCopy amountRows={3} />
            </div>
            <Styled.ButtonContainer>
              <SkeletonButton />
            </Styled.ButtonContainer>
          </Styled.FlexContainer>
        </SkeletonCard>
      )}
      {variant === 'image' && (
        <SkeletonCard
          className={className}
          noPadding
          ref={forwardedRef}
          {...props}
        >
          <SkeletonImage width="100%" height="220px" />
          <Styled.ImageCardContent>
            <SkeletonHeadline />
          </Styled.ImageCardContent>
        </SkeletonCard>
      )}
    </React.Fragment>
  )
);

SkeletonCardPreset.displayName = 'SkeletonCardPreset';
