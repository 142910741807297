/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
const __DEV__ =
  process.env.NODE_ENV !== 'production' && typeof jest === 'undefined';

interface ConsoleMethods {
  (...args: any[]): void;
}

const Console: { [key: string]: ConsoleMethods } = {
  deprecate: console.warn,
  debug: console.debug,
  error: console.error,
  info: console.info,
  log: console.log,
  warn: console.warn,
  table: console.table,
  trace: console.trace,
  group: console.group,
  groupCollapsed: console.groupCollapsed,
  groupEnd: console.groupEnd,
  clear: console.clear,
  count: console.count,
  assert: console.assert,
};

const log = (key: string, ...args: any[]): void => {
  if (__DEV__) {
    Console[key](...args);
  }
};

export default {
  deprecate: (...args: any[]) =>
    log('deprecate', 'DeprecationWarning:', ...args),
  debug: (...args: any[]) => log('debug', ...args),
  error: (...args: any[]) => log('error', ...args),
  info: (...args: any[]) => log('info', ...args),
  log: (...args: any[]) => log('log', ...args),
  warn: (...args: any[]) => log('warn', ...args),
  table: (...args: any[]) => log('table', ...args),
  trace: (...args: any[]) => log('trace', ...args),
  group: (...args: any[]) => log('group', ...args),
  groupCollapsed: (...args: any[]) => log('groupCollapsed', ...args),
  groupEnd: (...args: any[]) => log('groupEnd', ...args),
  clear: (...args: any[]) => log('clear', ...args),
  count: (...args: any[]) => log('count', ...args),
  assert: (...args: any[]) => log('assert', ...args),
};
