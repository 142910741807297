import styled from 'styled-components';

import { mediaWide, zIndexElevatedLayer5 } from '@xing-com/layout-tokens';
import { Logo } from '@xing-com/platform-layout-logo';
import { CenterContainer } from '@xing-com/platform-layout-view-wrapper';
import {
  xdlColorBackground,
  scale010,
  xdlColorBorderSoft,
  scale190,
  spaceXS,
  spaceXL,
} from '@xing-com/tokens';

export const StyledHeaderPlacebo = styled.div`
  height: ${scale190};
`;

export const StyledHeaderWrapper = styled.header`
  background-color: ${xdlColorBackground};
  border-bottom: ${scale010} solid ${xdlColorBorderSoft};

  display: flex;
  justify-content: center;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: calc(${zIndexElevatedLayer5} - 50);
  height: ${scale190};
`;

export const StyledCenterContainer = styled(CenterContainer)`
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100%;
`;

export const StyledLogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  transition: opacity 50ms cubic-bezier(0.2, 0.2, 0.2, 1);
  width: 101px;
`;

export const StyledLogo = styled(Logo)`
  width: 100%;
  padding: 0 ${spaceXS};
`;

export const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledUpsellArea = styled.div`
  display: none;
  margin-left: ${spaceXL};

  @media ${mediaWide} {
    display: block;
  }
`;
