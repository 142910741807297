import * as React from 'react';

import * as Styled from './skeleton-rectangle.styles';
import type { SkeletonRectangleProps } from './skeleton-rectangle.types';

export const SkeletonRectangle = React.forwardRef<
  HTMLDivElement,
  SkeletonRectangleProps
>(
  (
    {
      className,
      cornerRadius,
      height,
      size,
      variant,
      width = '100%',
      ...props
    },
    forwardedRef
  ): JSX.Element => (
    <Styled.SkeletonRectangle
      $cornerRadius={cornerRadius}
      $height={height}
      $size={size}
      $variant={variant}
      $width={width}
      className={className}
      data-xds="SkeletonRectangle"
      ref={forwardedRef}
      {...props}
    />
  )
);

SkeletonRectangle.displayName = 'SkeletonRectangle';
