/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AdvertisingScrambledIdQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type AdvertisingScrambledIdQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    advertisingScrambledId?: string | null;
  } | null;
};

export const AdvertisingScrambledIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'advertisingScrambledId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'advertisingScrambledId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdvertisingScrambledIdQuery,
  AdvertisingScrambledIdQueryVariables
>;
