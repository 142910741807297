import type * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { zIndexSheet } from '@xing-com/layout-tokens';
import { motionEasingEnter, motionTimeS, spaceS } from '@xing-com/tokens';

// This button appears on top of the Logo, when on Focus.
// Make sure to keep it behind/above Header's z-index
const SkipNavigationButton = styled(Button)`
  left: ${spaceS};
  opacity: 0;
  position: fixed;
  top: ${spaceS};
  transition: opacity ${motionTimeS} ${motionEasingEnter};
  z-index: -${zIndexSheet};

  &:focus {
    opacity: 1;
    z-index: ${zIndexSheet};
  }
`;

export const SkipNavigation: React.FC = () => (
  <SkipNavigationButton href="#content" variant="secondary" size="small">
    <FormattedMessage id="HEADER_SKIP_NAVIGATION" />
  </SkipNavigationButton>
);
