import { useEffect, useState, type ElementType, type FC } from 'react';

import { useActiveItem, useNotifications } from '@xing-com/hub';
import { IconWithBadge } from '@xing-com/platform-layout-icon-with-badge';
import { eitherOrWithCount, trackingURL } from '@xing-com/xing-tracking';

import {
  StyledHoverEffect,
  StyledLink,
  StyledTitle,
} from './nav-item-header.styles';
import { IconComponentContextProvider } from './notification-center-icon';

type NavItemHeaderProps = {
  icon: ElementType<{ className?: string }>;
  provideIconContext?: boolean;
  activeIcon: ElementType<{ className?: string }>;
  href: string;
  onClick?: (event: React.MouseEvent) => void;
  notificationKey?: string;
  title: string;
  activeItemValues?: string[];
  tracking?: Record<string, string | [string, string]>;
};

export const NavItemHeader: FC<NavItemHeaderProps> = ({
  icon,
  provideIconContext,
  activeIcon,
  href,
  onClick,
  notificationKey,
  title,
  activeItemValues,
  tracking,
}) => {
  const activeItem = useActiveItem();
  const notifications = useNotifications();
  const url = href;
  const [to, setTo] = useState(href);

  const isActive =
    activeItem && activeItemValues
      ? activeItemValues.includes(activeItem)
      : false;
  const notificationCount =
    notifications && notifications[notificationKey || ''];

  // When active, use regular icon as fallback if no activeIcon is specified
  const IconElement = isActive && activeIcon ? activeIcon : icon;
  const trackingConditions = {
    sc_o_PropActionOrigin: eitherOrWithCount(notificationCount),
  };

  const trackingUrl = trackingURL(
    url,
    'tracking',
    trackingConditions
  )({ tracking });

  useEffect(() => {
    setTo(trackingUrl);
  }, [trackingUrl]);

  const inner = (
    <StyledLink
      $isActive={isActive}
      data-testid={`frame-vnav-${activeItemValues?.join('_')}`}
      to={to}
      onClick={onClick}
    >
      <StyledHoverEffect>
        <IconWithBadge
          icon={IconElement}
          title={title}
          count={notificationCount}
        />
      </StyledHoverEffect>
      <StyledTitle>{title}</StyledTitle>
    </StyledLink>
  );

  return provideIconContext ? (
    <IconComponentContextProvider value={{ count: notificationCount || 0 }}>
      {inner}
    </IconComponentContextProvider>
  ) : (
    inner
  );
};
