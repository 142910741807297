import type * as React from 'react';

import * as Styled from './flag.styles';
const { sizes, variants } = Styled;

export type FlagSize = keyof typeof sizes;
export type FlagVariant = keyof typeof variants;
export type FlagGender = 'female' | 'male' | 'diverse' | 'neutral';

export type FlagTypeText = {
  [key in FlagGender]: string;
};

export type FlagVariantText = {
  [key in FlagVariant]: FlagTypeText;
};

export type FlagProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLSpanElement>;
  /** The gender of the text */
  gender?: FlagGender;
  /** @private Only used for interactive flag **/
  icon?: React.ReactNode;
  /** @callback */
  onClick?: () => void;
  /** The size of the flag */
  size?: FlagSize;
  /** The size on tablet screens or larger */
  sizeConfined?: FlagSize;
  /** The size on desktop screens or larger */
  sizeWide?: FlagSize;
  /** The variant of the flag */
  variant: FlagVariant;
  /** @deprecated Please use the "ref" property instead */
  innerRef?: React.RefObject<HTMLElement>;
  /** show and hide flag. It is true by default */
  show?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;
