import * as React from 'react';

import { cornerRadiusS, scale110 } from '@xing-com/tokens';

import { SkeletonRectangle } from '../';
import type { SkeletonCheckboxProps } from '../';

export const SkeletonCheckbox = React.forwardRef<
  HTMLDivElement,
  SkeletonCheckboxProps
>(
  ({ className, ...props }, forwardedRef): JSX.Element => (
    <SkeletonRectangle
      className={className}
      cornerRadius={cornerRadiusS}
      data-xds="SkeletonCheckbox"
      ref={forwardedRef}
      size={scale110}
      {...props}
    />
  )
);

SkeletonCheckbox.displayName = 'SkeletonCheckbox';
