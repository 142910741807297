import styled, { css } from 'styled-components';

import {
  cornerRadiusXL,
  scale005,
  scale060,
  scale070,
  scale100,
  spaceS,
  spaceXXS,
  xdlColorBackgroundTertiary,
  xdlColorMarkerHighlight,
  xdlColorMarkerHighlightText,
  xdlColorMarkerMatch,
  xdlColorMarkerMatchText,
  xdlColorMarkerStrong,
  xdlColorText,
  xdlColorTextOnDark,
  xdlColorTextOnLight,
  xdlColorBackgroundLight,
  xdlColorBackgroundDark,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import type { MarkerVariant } from '.';

type MarkerTProps = {
  $ellipsis?: boolean;
  $variant?: MarkerVariant;
};

type IconContainerTProps = {
  $iconAfter?: boolean;
};

type TextTProps = {
  $ellipsis?: boolean;
  $iconAfter?: boolean;
  $maxCharacters: string;
  $variant?: MarkerVariant;
};

// *** Variants ***
export const variants = {
  default: css`
    background: ${xdlColorBackgroundTertiary};
    color: ${xdlColorText};
  `,
  match: css`
    background: ${xdlColorMarkerMatch};
    color: ${xdlColorMarkerMatchText};
  `,
  highlight: css`
    background: ${xdlColorMarkerHighlight};
    color: ${xdlColorMarkerHighlightText};
  `,
  strong: css`
    background: ${xdlColorMarkerStrong};
    color: ${xdlColorTextOnDark};
  `,
  onColor: css`
    background: ${xdlColorBackgroundLight};
    color: ${xdlColorTextOnLight};
  `,
  onColorStrong: css`
    background: ${xdlColorBackgroundDark};
    color: ${xdlColorTextOnDark};
  `,
};

// *** Components ***
export const Marker = styled.span<MarkerTProps>`
  align-items: center;
  border-radius: ${cornerRadiusXL};
  display: flex;
  height: ${scale100};
  padding-block-end: ${scale005};
  padding-block-start: ${spaceXXS};
  padding-inline-end: ${spaceS};
  padding-inline-start: ${spaceS};

  ${(props) => css`
    ${props.$variant && variants[props.$variant]};
    ${props.$ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
    `};
  `};
`;

export const IconContainer = styled.span<IconContainerTProps>`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: ${scale070};

  ${(props) => css`
    ${props.$iconAfter
      ? css`
          margin-inline-start: ${spaceXXS};
          margin-inline-end: -${spaceXXS};
          order: 1;
        `
      : css`
          margin-inline-end: ${spaceXXS};
          margin-inline-start: -${spaceXXS};
        `}
  `};
`;

export const Text = styled(BodyCopy)<TextTProps>`
  ${(props) => css`
    ${props.$ellipsis &&
    props.$maxCharacters &&
    css`
      display: block;
      max-width: ${props.$maxCharacters}ch;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

    ${props.$variant &&
    (props.$variant === 'strong' || props.$variant === 'onColorStrong') &&
    css`
      font-size: ${scale060};
      font-weight: bold;
    `}
  `};
`;
