import type * as React from 'react';
import styled, { css } from 'styled-components';

import { Divider } from '@xing-com/divider';
import {
  space3XL,
  space4XL,
  space5XL,
  space6XL,
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
  spaceXS,
  spaceXXL,
  spaceXXS,
} from '@xing-com/tokens';

const spaceTokens = {
  '3XL': space3XL,
  '4XL': space4XL,
  '5XL': space5XL,
  '6XL': space6XL,
  L: spaceL,
  M: spaceM,
  S: spaceS,
  XL: spaceXL,
  XS: spaceXS,
  XXL: spaceXXL,
  XXS: spaceXXS,
};

type Height = keyof typeof spaceTokens;

const StyledDivider = styled(Divider)`
  ${({ $height, $isVisible }: { $height: Height; $isVisible?: boolean }) => {
    const height = spaceTokens[$height];
    const halfHeight = `calc(${height} / 2)`;
    return $isVisible
      ? css`
          margin-top: ${halfHeight};
          margin-bottom: calc(${halfHeight} - 1px);
        `
      : css`
          height: 0;
          margin-top: ${height};
        `;
  }};
`;

export const Spacer: React.FC<{
  isVisible?: boolean;
  height?: Height;
}> = ({ isVisible, height = 'L' }) => {
  return <StyledDivider $isVisible={isVisible} $height={height} />;
};
