import * as React from 'react';

import { cornerRadiusXL } from '@xing-com/tokens';

import type { SkeletonContentBannerProps } from '../';
import { SkeletonRectangle } from '../';

export const SkeletonContentBanner = React.forwardRef<
  HTMLDivElement,
  SkeletonContentBannerProps
>(
  ({ className, ...props }, forwardedRef): JSX.Element => (
    <SkeletonRectangle
      className={className}
      cornerRadius={cornerRadiusXL}
      data-xds="SkeletonContentBanner"
      ref={forwardedRef}
      variant="outline"
      {...props}
    />
  )
);

SkeletonContentBanner.displayName = 'SkeletonContentBanner';
