import * as React from 'react';

import devNote from '@xing-com/dev-note';

import * as Styled from './skeleton-button.styles';
import type { SkeletonButtonProps } from './skeleton-button.types';

export const SkeletonButton = React.forwardRef<
  HTMLDivElement,
  SkeletonButtonProps
>(
  (
    { className, size = 'medium', sizeConfined, sizeWide, variant, ...props },
    forwardedRef
  ): JSX.Element => {
    if (variant) {
      devNote.deprecate(
        "The variant property is deprecated and should be removed. Variant 'secondary' is automatically mapped to 'primary'."
      );
    }

    return (
      <Styled.Button
        $size={size}
        $sizeConfined={sizeConfined}
        $sizeWide={sizeWide}
        className={className}
        data-xds="SkeletonButton"
        ref={forwardedRef}
        {...props}
      />
    );
  }
);

SkeletonButton.displayName = 'SkeletonButton';
