import { useQuery } from '@apollo/client';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

import { removeDuplicatesFromRecentlyViewedJobs } from './activity-center-rules';
import { GetActivityCenterTotalsDocument } from './graphql/get-activity-center-totals.gql-types';
import { usePrevious } from './utils';

const EVENTS = ['jobBookmarks', 'jobVisits'];

type UseTotalsResult = {
  total: number;
  totalByEvents: Record<string, number | null>;
  previousTotalByEvents: Record<string, number | null> | undefined;
  refetch: () => void;
  setTotalByEvents: Dispatch<SetStateAction<Record<string, number | null>>>;
};

export function useTotals(skip = false): UseTotalsResult {
  const [total, setTotal] = useState(0);
  const [totalByEvents, setTotalByEvents] = useState<
    Record<string, number | null>
  >({});
  const previousTotalByEvents = usePrevious(totalByEvents);

  const { refetch } = useQuery(GetActivityCenterTotalsDocument, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: skip,
    onCompleted: (data) => {
      const jobBookmarksNodes = data?.viewer?.jobBookmarks?.edges
        ?.map((edge) =>
          edge?.node.job?.__typename === 'VisibleJob' ? edge?.node.job : null
        )
        .filter((job) => job !== null);

      const jobVisitsNodes = data?.viewer?.jobVisits?.edges
        ?.map((edge) =>
          edge?.node.job?.__typename === 'VisibleJob' ? edge?.node.job : null
        )
        .filter((job) => job !== null);

      const deduplicatedJobVisits =
        jobBookmarksNodes && jobVisitsNodes
          ? removeDuplicatesFromRecentlyViewedJobs(
              jobVisitsNodes,
              jobBookmarksNodes
            )
          : null;

      const totalByEvents: Record<string, number | null> = {
        jobBookmarks: jobBookmarksNodes ? jobBookmarksNodes.length : null,
        jobVisits: deduplicatedJobVisits ? deduplicatedJobVisits.length : null,
      };

      setTotalByEvents(totalByEvents);
      setTotal(
        EVENTS.reduce((acc, event) => {
          const eventTotal = totalByEvents[event];
          if (eventTotal === null) {
            return acc;
          }
          return acc + eventTotal;
        }, 0)
      );
    },
  });

  return {
    total,
    totalByEvents,
    previousTotalByEvents,
    refetch,
    setTotalByEvents,
  };
}
