import styled from 'styled-components';

import { Button } from '@xing-com/button';
import {
  mediaConfined,
  mediaSmallOrTiny,
  mediaTopBarVisible,
  mediaTopBarHidden,
  zIndexElevatedLayer5,
} from '@xing-com/layout-tokens';
import { Logo } from '@xing-com/platform-layout-logo';
import { CenterContainer } from '@xing-com/platform-layout-view-wrapper';
import {
  xdlColorBackground,
  scale010,
  xdlColorBorderSoft,
  spaceL,
  scale170,
  scale210,
} from '@xing-com/tokens';

const pickMediaQuery = ({ size }: { size: string }): string => {
  return size === 'small' ? mediaTopBarHidden : mediaTopBarVisible;
};

export const StyledButtonSmallWhenTopBar = styled(Button)`
  @media ${pickMediaQuery} {
    display: none;
  }
`;

export const StyledPlacebo = styled.div`
  height: calc(${scale210});

  @media ${mediaTopBarVisible} {
    height: calc(${scale170});
  }
`;

export const StyledHeaderWrapper = styled.header`
  background-color: ${xdlColorBackground};
  border-bottom: ${scale010} solid ${xdlColorBorderSoft};
  display: flex;
  justify-content: center;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${zIndexElevatedLayer5};
  height: ${scale210};

  @media ${mediaTopBarVisible} {
    height: calc(${scale170});
  }
`;

export const StyledHeader = styled(CenterContainer)`
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media ${mediaSmallOrTiny} {
    margin-right: ${spaceL};
  }
`;

export const StyledLogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  transition: opacity 50ms cubic-bezier(0.2, 0.2, 0.2, 1);

  /* <= 739px */
  @media ${mediaSmallOrTiny} {
    margin-right: ${spaceL};
  }

  /* >= 740px */
  @media ${mediaConfined} {
    width: 101px;
  }
`;

export const StyledLogo = styled(Logo)`
  /* <= 739px */
  @media ${mediaSmallOrTiny} {
    width: 100%;
    margin-left: ${spaceL};
    display: ${({ butterfly }) => !butterfly && 'none'};
  }
  /* >= 740px */
  @media ${mediaConfined} {
    width: 100%;
    display: ${({ butterfly }) => butterfly && 'none'};
  }
`;

export const StyledNav = styled.nav`
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledNavItemContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  align-items: center;
`;
