import styled, { css, keyframes } from 'styled-components';

import {
  motionEasingStandard,
  motionTimeXXL,
  scale010,
  xdlColorSkeletonPrimary,
  xdlColorSkeletonPrimaryAnimation,
} from '@xing-com/tokens';

import { SkeletonBase } from './skeleton-base.styles';
import type {
  CornerRadius,
  SkeletonRectangleVariant,
} from './skeleton-rectangle.types';

type SkeletonRectangleTProps = {
  $cornerRadius?: CornerRadius;
  $height?: string;
  $size?: string;
  $width?: string;
  $variant?: SkeletonRectangleVariant;
};
//
// *** Animations ***
const borderAnimation = keyframes`
  0% {
    border-color: ${xdlColorSkeletonPrimaryAnimation};
  }
  100% {
    border-color: ${xdlColorSkeletonPrimary};
  }
`;

export const SkeletonRectangle = styled(SkeletonBase)<SkeletonRectangleTProps>`
  overflow: hidden;
  width: 100%;

  ${({ $variant }) =>
    $variant === 'outline' &&
    css`
      animation-direction: alternate;
      animation-duration: ${motionTimeXXL};
      animation-iteration-count: infinite;
      animation-name: ${borderAnimation};
      animation-timing-function: ${motionEasingStandard};
      background-color: transparent;
      border-color: ${xdlColorSkeletonPrimary};
      border-style: solid;
      border-width: ${scale010};
    `};

  ${({ $cornerRadius }) =>
    $cornerRadius &&
    css`
      border-radius: ${$cornerRadius};
    `};

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `};

  ${({ $height }) =>
    $height &&
    css`
      height: ${$height};
    `};

  ${({ $size }) =>
    $size &&
    css`
      height: ${$size};
      width: ${$size};
    `};
`;
