import * as React from 'react';
import { useIntl } from 'react-intl';

import type { AsyncHookResult, MetaConfig } from '@xing-com/hub';
import { asyncResult, MetaConfigContext } from '@xing-com/hub';
import type { Language } from '@xing-com/platform-layout-frame-config-templates';
import { v1 } from '@xing-com/platform-layout-frame-config-templates';
import { registerExperiment } from '@xing-com/splitkit';

const version = 1;
const context = { useMetaConfig };
const InternalContext = React.createContext<AsyncHookResult<MetaConfig>>({
  loading: true,
});

function useMetaConfig(): AsyncHookResult<MetaConfig> {
  return React.useContext(InternalContext);
}

export const CrateFrameConfigModule: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const intl = useIntl();

  const data = React.useMemo<AsyncHookResult<MetaConfig>>(() => {
    const translate = (id: string): string => {
      return intl.formatMessage({ id });
    };

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const lang = intl.locale as Language;

    const data = v1(translate, lang);

    // TODO: move into own module when available in the Brewery
    data.experiments.forEach(({ name, supportedVariants }) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      registerExperiment(name, supportedVariants as any);
    });

    return asyncResult({
      url: '(from bundle)',
      version,
      // FIXME
      ...(data as any),
    });
  }, [intl]);

  return (
    <InternalContext.Provider value={data}>
      <MetaConfigContext.Provider value={context}>
        {children}
      </MetaConfigContext.Provider>
    </InternalContext.Provider>
  );
};
