/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type XingFrameQueryQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type XingFrameQueryQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    webTrackingData?: {
      __typename?: 'WebTrackingData';
      PropHashedUserId?: string | null;
      PropTrackingThirdpartyExternal?: boolean | null;
    } | null;
    tracy?: {
      __typename?: 'Tracy';
      willingnessToChangeJobs?: {
        __typename?: 'TracyWillingnessToChangeJobs';
        probability?: number | null;
      } | null;
    } | null;
    networkEfficiencyIndicator?: {
      __typename?: 'NetworkEfficiencyIndicator';
      score: number;
      category: string;
      userTypeSupported: boolean;
    } | null;
    xingId?: {
      __typename?: 'XingId';
      academicTitle?: Types.AcademicTitle | null;
      birthday?: string | null;
      displayName: string;
      firstName: string;
      gender?: Types.Gender | null;
      globalId: string;
      id: string;
      lastName: string;
      pageName: string;
      userFlags?: {
        __typename?: 'UserFlags';
        displayFlag?: Types.UserFlagsDisplayFlag | null;
      } | null;
      profileImage?: Array<{
        __typename?: 'ProfileImage';
        url: string;
      } | null> | null;
      occupations?: Array<{
        __typename?: 'XingIdOccupation';
        headline: string;
        subline: string;
      } | null> | null;
    } | null;
  } | null;
};

export const XingFrameQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'xingFrameQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webTrackingData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PropHashedUserId' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PropTrackingThirdpartyExternal',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tracy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'willingnessToChangeJobs',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'probability' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'networkEfficiencyIndicator' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'consumer' },
                      value: {
                        kind: 'StringValue',
                        value: 'loggedin.web.wpt.topbar.neffi',
                        block: false,
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userTypeSupported' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'xingId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'academicTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'birthday' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userFlags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayFlag' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'globalId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileImage' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'size' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'SQUARE_128' },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'occupations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'headline' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subline' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<XingFrameQueryQuery, XingFrameQueryQueryVariables>;
