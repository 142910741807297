import type * as React from 'react';

import * as Styled from './text-button.styles';
const { sizes, fontWeights } = Styled;

export type TextButtonSizes = keyof typeof sizes;
export type TextButtonFontWeights = keyof typeof fontWeights;

export type TextButtonProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Label of the TextButton, should be text, icons are handled separately */
  children?: React.ReactNode;
  /** Add custom CSS */
  className?: string;
  /** Disable the TextButton */
  disabled?: boolean;
  /** Activate text truncation. This needs a fixed TextButton width or flex container around the TextButton to work */
  ellipsis?: boolean;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLButtonElement | HTMLAnchorElement>;
  /** The href, if it should be an anchor */
  href?: string;
  /** Error state of the TextButton */
  error?: boolean;
  /** Select different font-weight */
  fontWeight?: TextButtonFontWeights;
  /** Hand over an icon component for the TextButton */
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  /** Position the icon on the right side of the TextButton */
  iconAfter?: boolean;
  /** @deprecated Please use the "ref" property instead */
  innerRef?: React.Ref<HTMLButtonElement | HTMLAnchorElement>;
  /** Loading state of the TextButton */
  loading?: boolean;
  /** @callback */
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  /** The size on mobile screens or larger */
  size?: TextButtonSizes;
  /** The size on tablet screens or larger */
  sizeConfined?: TextButtonSizes;
  /** The size on desktop screens or larger */
  sizeWide?: TextButtonSizes;
  /** The to, if it should be an anchor */
  to?: string;
  /** The type of button */
  type?: 'button' | 'submit' | 'reset';
} & React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>;
