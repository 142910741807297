/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconOtherServicesFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconOtherServicesFilled"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 6.3a2.3 2.3 0 1 0 0-4.6 2.3 2.3 0 0 0 0 4.6M12 6.3a2.3 2.3 0 1 0 0-4.6 2.3 2.3 0 0 0 0 4.6M20 6.3a2.3 2.3 0 1 0 0-4.6 2.3 2.3 0 0 0 0 4.6M4 14.3a2.3 2.3 0 1 0 0-4.6 2.3 2.3 0 0 0 0 4.6M12 14.3a2.3 2.3 0 1 0 0-4.6 2.3 2.3 0 0 0 0 4.6M20 14.3a2.3 2.3 0 1 0 0-4.6 2.3 2.3 0 0 0 0 4.6M4 22.3a2.3 2.3 0 1 0 0-4.6 2.3 2.3 0 0 0 0 4.6M12 22.3a2.3 2.3 0 1 0 0-4.6 2.3 2.3 0 0 0 0 4.6M20 22.3a2.3 2.3 0 1 0 0-4.6 2.3 2.3 0 0 0 0 4.6"
      clipRule="evenodd"
    />
  </svg>
);
export default IconOtherServicesFilled;
