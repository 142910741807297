import type * as React from 'react';
import { useIntl } from 'react-intl';

import { useExperiment } from '@xing-com/hub';
import { IconSearch } from '@xing-com/icons';

import { SearchDropdownItem } from './search-dropdown-item';

export const SearchDropdownList: React.FC = () => {
  const { formatMessage } = useIntl();
  const isAiSearchForced = useExperiment('ABACUS-468') === 'B';

  const NAVIGATION_BADGE_NO_BADGE = 'navigation_badge_no_badge';
  const SEARCH_URL = isAiSearchForced
    ? '/jobs/search/ki/start'
    : '/jobs/search';

  return (
    <>
      <SearchDropdownItem
        itemKey="members"
        icon={IconSearch}
        href="/search/members"
        title={formatMessage({ id: 'SEARCH_DROPDOWN_MEMBERS' })}
        tracking={{
          sc_o: 'navigation_search_member_search',
          sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
        }}
      />
      <SearchDropdownItem
        itemKey="jobs"
        icon={IconSearch}
        href={SEARCH_URL}
        title={formatMessage({ id: 'SEARCH_DROPDOWN_JOBS' })}
        tracking={{
          sc_o: 'navigation_search_job_search',
          sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
        }}
      />
      <SearchDropdownItem
        itemKey="companies"
        icon={IconSearch}
        href="/search/companies"
        title={formatMessage({ id: 'SEARCH_DROPDOWN_COMPANIES' })}
        tracking={{
          sc_o: 'navigation_search_companies_search',
          sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
        }}
      />
    </>
  );
};
