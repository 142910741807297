import type * as React from 'react';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { zIndexElevatedLayer5 } from '@xing-com/layout-tokens';
import { SearchDropdownList } from '@xing-com/platform-search-dropdown';
import { PopOver, usePopOver } from '@xing-com/pop-over';
import ttt from '@xing-com/ticktricktrack';
import {
  xdlColorHover,
  spaceM,
  spaceXL,
  scale150,
  spaceS,
  spaceL,
} from '@xing-com/tokens';

export type SearchDropdownProps = {
  icon: React.ComponentType;
  title: string;
};

const SearchDropdownContainer = styled.div`
  position: relative;
`;

export const SearchButton = styled(Button)`
  border-radius: ${scale150};
  padding: 3px ${spaceM};
  margin-left: ${spaceS};
  height: 56px;

  &:hover {
    background-color: ${xdlColorHover};
  }

  & > div {
    flex-direction: column;
    font-weight: normal;

    & > span {
      /* Icon */
      &:nth-child(1) {
        width: ${spaceXL};
        height: ${spaceXL};
        margin-right: 0;
        & > svg {
          width: ${spaceXL};
          height: ${spaceXL};
        }
      }
    }
  }
`;

const listMaxHeightTabletAndDesktop = '556px';
const listMinWidth = '252px';
const topMarginTabletAndDesktop = '62px';

const SearchPopOver = styled(PopOver)`
  max-height: ${listMaxHeightTabletAndDesktop};
  min-width: ${listMinWidth};
  right: 0;
  && {
    z-index: ${zIndexElevatedLayer5};
    padding: ${spaceM} ${spaceL};
  }
  top: ${topMarginTabletAndDesktop};
`;

export const SearchDropdown: React.VFC<SearchDropdownProps> = ({
  icon,
  title,
}) => {
  const popOver = usePopOver();
  const toggleSearch = (): void => {
    popOver.togglePopOver();

    ttt.event('PropTrackAction', {
      PropTrackAction: 'navigation_search_drop_down',
      PropActionOrigin: 'navigation_badge_no_badge',
    });
  };

  return (
    <SearchDropdownContainer>
      <SearchButton
        size="small"
        // @ts-expect-error FIXME: SC6
        icon={icon}
        data-testid="search-dropdown-button"
        onClick={toggleSearch}
      >
        {title}
      </SearchButton>
      <SearchPopOver
        data-testid="search-dropdown-menu"
        isInFlow
        isStatic
        // @ts-expect-error FIXME: SC6
        dimmerTitle="close menu"
        onOutsideClick={popOver.handleHide}
        show={popOver.show}
        triggerRef={popOver.triggerRef}
      >
        <SearchDropdownList />
      </SearchPopOver>
    </SearchDropdownContainer>
  );
};
