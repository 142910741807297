import styled, { css } from 'styled-components';

import { scale050, spaceL, spaceM, spaceS } from '@xing-com/tokens';

import { SkeletonProfileImage, SkeletonHeadline } from '../';
import type { SkeletonProfileInfoVariant } from './skeleton-profile-info.types';

type ProfileInfoTProps = {
  $variant: SkeletonProfileInfoVariant;
};

// *** Components ***
export const ProfileInfoContainer = styled.div<ProfileInfoTProps>`
  display: flex;
  width: 100%;

  ${({ $variant }) =>
    $variant === 'horizontal' &&
    css`
      display: flex;
    `};

  ${({ $variant }) =>
    $variant === 'vertical' &&
    css`
      display: flex;
      flex-direction: column;
    `};
`;

export const ProfileInfoContent = styled.div`
  width: 100%;
`;

export const ProfileInfoImage = styled(SkeletonProfileImage)<ProfileInfoTProps>`
  flex-shrink: 0;

  ${({ $variant }) =>
    $variant === 'horizontal' &&
    css`
      margin-inline-end: ${spaceM};
      display: flex;
      align-content: flex-start;
    `};

  ${({ $variant }) =>
    $variant === 'vertical' &&
    css`
      margin-block-end: ${spaceL};
    `};
`;

export const ProfileInfoHeadline = styled(SkeletonHeadline)`
  & > div {
    margin-block-end: ${spaceS};
  }

  margin-block-end: ${scale050};
`;
