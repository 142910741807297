import * as React from 'react';

import * as Styled from './connection-degree.styles';
import type { ConnectionDegreeProps } from './connection-degree.types';

export const ConnectionDegree = React.forwardRef<
  HTMLAnchorElement,
  ConnectionDegreeProps
>(
  (
    {
      children,
      className,
      size,
      sizeConfined,
      sizeWide,
      sizeXWide,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => (
    <Styled.ConnectionDegreeBadge
      $variant={variant}
      className={className}
      data-xds="ConnectionDegree"
      ref={forwardedRef}
      size={size}
      sizeConfined={sizeConfined}
      sizeWide={sizeWide}
      sizeXWide={sizeXWide}
      {...props}
    >
      {children}
    </Styled.ConnectionDegreeBadge>
  )
);

ConnectionDegree.displayName = 'ConnectionDegree';
