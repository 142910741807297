import * as React from 'react';

import {
  LogoXINGButterflyBlack,
  LogoXINGButterflyDuo,
  LogoXINGButterflyInvert,
  LogoXINGButterflyWhite,
} from '@xing-com/illustrations';

import * as Styled from './butterfly.styles';
import type { ButterflyProps } from './butterfly.types';

const DEFAULT_SIZE = 28;

export const Butterfly = React.forwardRef<HTMLDivElement, ButterflyProps>(
  (
    {
      className,
      inverted = false,
      size = DEFAULT_SIZE,
      variant = 'duo',
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const width = size;
    const height = Math.round((31 * size) / DEFAULT_SIZE); // Calculate height of butterfly from viewbox
    const defaultProps = {
      height,
      width,
    };

    return (
      <Styled.Butterfly
        className={className}
        data-xds="Butterfly"
        ref={forwardedRef}
      >
        {variant === 'duo' && !inverted && (
          <LogoXINGButterflyDuo {...defaultProps} {...props} />
        )}
        {variant === 'duo' && inverted && (
          <LogoXINGButterflyInvert {...defaultProps} {...props} />
        )}
        {variant === 'mono' && !inverted && (
          <LogoXINGButterflyBlack {...defaultProps} {...props} />
        )}
        {variant === 'mono' && inverted && (
          <LogoXINGButterflyWhite {...defaultProps} {...props} />
        )}
      </Styled.Butterfly>
    );
  }
);

Butterfly.displayName = 'Butterfly';
