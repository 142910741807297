import * as React from 'react';

import * as Styled from './skeleton-pill-shape.styles';
import type { SkeletonPillShapeProps } from './skeleton-pill-shape.types';

export const SkeletonPillShape = React.forwardRef<
  HTMLDivElement,
  SkeletonPillShapeProps
>(
  (
    { className, height, width = '100%', ...props },
    forwardedRef
  ): JSX.Element => (
    <Styled.SkeletonPillShape
      $height={height}
      $width={width}
      className={className}
      data-xds="SkeletonPillShape"
      ref={forwardedRef}
      {...props}
    />
  )
);

SkeletonPillShape.displayName = 'SkeletonPillShape';
