import { parse, stringify } from 'qs';
import { useEffect, useState } from 'react';

import { useVisitorId } from '@xing-com/hub';

export const generateLink = (
  link: string,
  params?: Record<string, string>
): string => {
  const [path, query] = link.split('?');
  const queryParams = parse(query);
  const newQueryParams = { ...queryParams, ...params };
  const hasQueryParams = Object.keys(newQueryParams).length > 0;

  return `${path}${hasQueryParams ? '?' : ''}${stringify(newQueryParams)}`;
};

// Use generated link only after initial render, for SEO reasons
export const useGeneratedLink = (
  link: string,
  params?: Record<string, string>
): string => {
  const [resultingLink, setResultingLink] = useState(link);
  const visitorId = useVisitorId();

  const [path] = link.split('?');

  const isKununuOrOnlyfy = ['kununu', 'onlyfy'].some((domain) =>
    path.includes(domain)
  );

  const scCdvidParam: Record<string, string> = isKununuOrOnlyfy
    ? { sc_cdvid: encodeURIComponent(btoa(visitorId)) }
    : {};

  const generatedLink = generateLink(link, { ...params, ...scCdvidParam });

  useEffect(() => {
    setResultingLink(generatedLink);
  }, [generatedLink]);

  return resultingLink;
};
