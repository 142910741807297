import * as React from 'react';

import * as Styled from './checkbox.styles';
import type { CheckboxProps } from './checkbox.types';

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { className, disabled, error, name, on, onChange, ...props },
    forwardedRef
  ): JSX.Element => (
    <Styled.Container className={className}>
      <Styled.Checkbox
        $error={error}
        data-xds="Checkbox"
        disabled={disabled}
        name={name}
        onChange={onChange}
        ref={forwardedRef}
        type="checkbox"
        {...{ [onChange ? 'checked' : 'defaultChecked']: on }}
        {...props}
      />
      <Styled.Tick width={24} height={24} />
    </Styled.Container>
  )
);

Checkbox.displayName = 'Checkbox';
