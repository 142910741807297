import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import * as React from 'react';

import type { Notifications, NotificationsHandler } from '@xing-com/hub';
import {
  NotificationsContext,
  NotificationsHandlerContext,
  useErrorHandler,
} from '@xing-com/hub';

import type { AppStatsQueryQuery } from './app-stats-query.gql-types';
import { AppStatsQueryDocument } from './app-stats-query.gql-types';
import { NotificationsBadgesSeenMutationDocument } from './badges-seen-mutation.gql-types';

export function NotificationsModule({
  children,
  pollInterval,
}: React.PropsWithChildren<{ pollInterval?: number }>): JSX.Element {
  const captureException = useErrorHandler();
  const apolloClient = useApolloClient();
  const [notifications, setNotifications] = useState<Notifications>({});
  const [mutateBadgeSeen] = useMutation(
    NotificationsBadgesSeenMutationDocument
  );

  const {
    data: appStatsData = {},
    error,
    refetch,
  } = useQuery(AppStatsQueryDocument, {
    pollInterval,
  });
  const { viewer: appStatsViewer } = appStatsData;
  const { appStats } = appStatsViewer || {};

  if (error) captureException(error);

  useEffect(() => {
    if (!appStats) return;

    setNotifications({
      ...extractNotifications(appStats),
    });
  }, [appStats]);

  const handler: NotificationsHandler = React.useMemo(() => {
    return {
      acknowledge(...badgeNames) {
        mutateBadgeSeen({ variables: { badgeNames } });
      },
      change(key: string, count: number) {
        const viewer = apolloClient.readQuery({
          query: AppStatsQueryDocument,
        })?.viewer;

        if (!key || !viewer) return;

        const appStats:
          | Partial<Record<string, unknown | undefined>>
          | undefined
          | null = viewer.appStats;

        if (!appStats || (appStats[key] ?? 0) === count) {
          return;
        }

        // Update Apollo Cache
        apolloClient.writeQuery({
          query: AppStatsQueryDocument,
          data: {
            viewer: {
              ...viewer,
              appStats: {
                ...appStats,
                [key]: count,
              },
            },
          },
        });
      },
      refresh() {
        refetch();
      },
    };
  }, [apolloClient, mutateBadgeSeen, refetch]);

  return (
    <NotificationsContext.Provider value={notifications}>
      <NotificationsHandlerContext.Provider value={handler}>
        {children}
      </NotificationsHandlerContext.Provider>
    </NotificationsContext.Provider>
  );
}

type AppStatsViewer = Exclude<AppStatsQueryQuery['viewer'], null | undefined>;
type AppStats = Exclude<AppStatsViewer['appStats'], null | undefined>;

function extractNotifications({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  __typename,
  ...appStats
}: AppStats): Notifications {
  return Object.fromEntries(
    Object.entries(appStats).map(([key, value]) => {
      return [key, value || 0];
    })
  );
}
