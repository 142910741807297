import { useLink } from '@xing-com/hub';

export type To =
  | string
  | { pathname: string; hash?: string; search?: string; state?: unknown }
  | undefined;

// @TODO: use mutually exclusive prop instead of plain optional ones.
export type LinkProps = React.PropsWithChildren<{
  /** either a string or an object compatible to react router possibilities containing state */
  to?: To;
  /** If on reach router, you may handover state directly with a prop */
  state?: unknown;
  [key: string]: unknown;
}>;

export const Link: React.FC<LinkProps> = ({ children, to, ...props }) => {
  const Link = useLink();

  if (to) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  } else {
    return <a {...props}>{children}</a>;
  }
};
