import * as React from 'react';

import { useFeatures } from '@xing-com/hub';

export type MatchMode = 'either' | 'and';

export type FeatureConditionProps = {
  children?: React.ReactNode;
  elseCase?: React.ReactNode;
  flags: string | string[];
  matchMode?: MatchMode;
};

const wrapSingleElementIntoArray = <T,>(elementOrArray: T | T[]): T[] =>
  Array.isArray(elementOrArray) ? elementOrArray : [elementOrArray];

type Matcher = (
  features: Partial<Record<string, boolean | null>>,
  flagsArray: string[]
) => boolean;

const matchAll: Matcher = (features, flagsArray) => {
  return flagsArray.every((flag) => features[flag]);
};

const matchAny: Matcher = (features, flagsArray) => {
  return flagsArray.some((flag) => features[flag]);
};

export const FeatureCondition: React.FC<FeatureConditionProps> = ({
  children = null,
  elseCase = null,
  flags,
  matchMode,
}: FeatureConditionProps) => {
  const features = useFeatures();

  const flagsArray = wrapSingleElementIntoArray(flags);

  const matcher = matchMode === 'and' ? matchAll : matchAny;
  const matches =
    !features.loading && features.data && matcher(features.data, flagsArray);

  return <React.Fragment>{matches ? children : elseCase}</React.Fragment>;
};
