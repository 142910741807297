import type { ElementType, FC } from 'react';

import { useActiveItem, useNotifications } from '@xing-com/hub';
import { eitherOrWithCount, trackingURL } from '@xing-com/xing-tracking';

import {
  PlaceholderLi,
  PlaceholderIcon,
  PlaceholderText,
  PlaceholderItemText,
  StyledIconWithBadge,
  StyledLink,
  StyledTitle,
} from './nav-item.styles';

const keyToMembershipType = (key: string): string | undefined => {
  switch (key) {
    case 'premium':
    case 'premiumUpsell':
      return 'premium';
    case 'proJobs':
    case 'proJobsUpsell':
      return 'proJobs';
    case 'proBusiness':
    case 'proBusinessUpsell':
      return 'proBusiness';
    default:
      return undefined;
  }
};

const NavItemPlaceholder: FC = () => {
  return (
    <PlaceholderLi>
      <PlaceholderIcon />
      <PlaceholderText>
        <PlaceholderItemText />
      </PlaceholderText>
    </PlaceholderLi>
  );
};

type NavItemProps = {
  item: {
    activeIcon: ElementType;
    href: string;
    icon: ElementType;
    key: string;
    notificationKey?: string;
    title: string;
    titleShort?: string;
    tracking: Record<string, string | [string, string]>;
    activeItemValues?: string[];
  };
};

const NavItem: FC<NavItemProps> = ({ item }) => {
  const activeItem = useActiveItem();
  const notifications = useNotifications();

  const isActive =
    activeItem && item.activeItemValues
      ? item.activeItemValues.includes(activeItem)
      : activeItem === item.key;
  const notificationCount =
    notifications && notifications[item.notificationKey || ''];

  // When active, use regular icon as fallback if no activeIcon is specified
  const IconElement = isActive ? item.activeIcon || item.icon : item.icon;
  const trackingConditions = {
    sc_o_PropActionOrigin: eitherOrWithCount(notificationCount),
  };

  return (
    <li>
      <StyledLink
        $isActive={isActive}
        $membership={keyToMembershipType(item.key)}
        data-qa={`frame-vnav-${item.key}`}
        to={trackingURL(item.href, 'tracking', trackingConditions)(item)}
      >
        <StyledIconWithBadge
          $membership={keyToMembershipType(item.key)}
          icon={IconElement}
          title={item.title}
          count={notificationCount}
        />
        <StyledTitle $size="long">{item.title}</StyledTitle>
        <StyledTitle $size="short">{item.titleShort || item.title}</StyledTitle>
      </StyledLink>
    </li>
  );
};

export { NavItem, NavItemPlaceholder };
