import type { FC, SVGProps } from 'react';

const Bell: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path
        d="M3,19 L3,17 L5,17 L5,11 C5,7.47353094 7.60770164,4.55612324 10.9999918,4.07089004 L11,2 L13,2 L13.0010101,4.07103341 C16.3928118,4.55669765 19,7.4738781 19,11 L19,17 L21,17 L21,19 L3,19 Z M12,6 C9.23857625,6 7,8.23857625 7,11 L7,17 L17,17 L17,11 C17,8.23857625 14.7614237,6 12,6 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export { Bell };
