import type { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { mapBadges } from './map-badges';
import type { CommunicationMarkNotificationBadgesAsSeenMutation } from './mark-notification-badges-as-seen.gql-types';
import { CommunicationMarkNotificationBadgesAsSeenDocument } from './mark-notification-badges-as-seen.gql-types';

export type UseZerofyNotificationBadges = {
  markBadgesAsSeen: (
    badges: Array<string>
  ) => Promise<FetchResult<CommunicationMarkNotificationBadgesAsSeenMutation>>;
};

export const useZerofyNotificationBadges = (): UseZerofyNotificationBadges => {
  const [markAsSeen] = useMutation(
    CommunicationMarkNotificationBadgesAsSeenDocument
  );

  const markBadgesAsSeen: UseZerofyNotificationBadges['markBadgesAsSeen'] =
    useCallback(
      (badges) =>
        markAsSeen({
          variables: { badges: { badgeNames: mapBadges(badges) } },
        }),
      [markAsSeen]
    );

  return { markBadgesAsSeen };
};
