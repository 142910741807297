/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const FallbackImageAI = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 128"
    data-xds="FallbackImageAI"
    {...props}
  >
    <path fill="#F2E8FF" d="M0 0h128v128H0z" />
    <path
      fill="#8A2CE8"
      d="m79.584 64 2.295 7.31c.708 2.947 3.003 5.299 6.006 6.007l7.282 2.267-7.31 2.295c-2.947.708-5.298 3.003-6.007 6.006l-2.266 7.282-2.295-7.31c-.709-2.947-3.004-5.298-6.007-6.007L64 79.584l7.31-2.295c2.947-.709 5.299-3.004 6.007-6.007zM58.334 82.899l-5.667-14.054A8.84 8.84 0 0 0 47.822 64l-14.053-5.666 14.053-5.667a8.84 8.84 0 0 0 4.845-4.845l5.667-14.053L64 47.822a8.84 8.84 0 0 0 4.845 4.845L82.9 58.334 68.845 64A8.84 8.84 0 0 0 64 68.845z"
    />
  </svg>
);
export default FallbackImageAI;
