import type * as React from 'react';
import styled from 'styled-components';

import ContainedGrid, { Row, Column } from '@xing-com/xing-grid';

type ViewWrapperDefaultGridProp = {
  children?: React.ReactNode;
};

const StyledContainerGrid = styled(ContainedGrid)`
  margin: 0 auto;
`;

export const ViewWrapperDefaultGrid: React.FC<ViewWrapperDefaultGridProp> = ({
  children,
}) => (
  <StyledContainerGrid type="wide">
    <Row>
      <Column
        size={12}
        offset={0}
        offsetDesktop={2}
        sizeDesktop={8}
        offsetTablet={1}
        sizeTablet={10}
      >
        {children}
      </Column>
    </Row>
  </StyledContainerGrid>
);
