import * as React from 'react';

import { IconCross } from '@xing-com/icons';

import * as Styled from './pop-up-window.styles';
import type { PopUpWindowPrimitiveProps } from './pop-up-window.types';

export const PopUpWindowPrimitive = React.forwardRef<
  HTMLDivElement,
  PopUpWindowPrimitiveProps
>(
  ({ children, noPadding, hide, ...props }, forwardedRef): JSX.Element => (
    <Styled.PopUpWindow
      $noPadding={noPadding}
      data-xds="PopUpWindow"
      ref={forwardedRef}
      {...props}
    >
      {children}
      {hide && (
        <Styled.CloseButton
          aria-label="Close"
          data-dismiss="modal"
          icon={IconCross}
          onClick={hide}
          size="large"
          type="button"
        />
      )}
    </Styled.PopUpWindow>
  )
);

PopUpWindowPrimitive.displayName = 'PopUpWindow (Primitive)';
