/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FeatureSwitchesQueryQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FeatureSwitchesQueryQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    featureSwitches?: Array<string> | null;
    features?: {
      __typename?: 'Feature';
      isXingEmployee?: boolean | null;
      isJobsPoster?: boolean | null;
      isAdmasterUser?: boolean | null;
      isBrandPageCollaborator?: boolean | null;
      isBasic?: boolean | null;
      isPremium?: boolean | null;
      isExecutive?: boolean | null;
      isSales?: boolean | null;
      hasProJobsMembership?: boolean | null;
      isCraUser?: boolean | null;
      isSeatManagerAdmin?: boolean | null;
      showProbusinessInNavigation?: boolean | null;
      showUpsellHint?: boolean | null;
      showJobSeekerBoneyardUpsellHint?: boolean | null;
      showPremiumBoneyardUpsellHint?: boolean | null;
      hasCoachProfile?: boolean | null;
      isBrandManagerEditor?: boolean | null;
    } | null;
  } | null;
};

export const FeatureSwitchesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'featureSwitchesQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Feature' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isXingEmployee' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isJobsPoster' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isAdmasterUser' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'isBrandPageCollaborator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isBasic' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isPremium' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isExecutive' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isSales' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'hasProJobsMembership',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isCraUser' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'isSeatManagerAdmin',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'showProbusinessInNavigation',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'showUpsellHint' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'showJobSeekerBoneyardUpsellHint',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'showPremiumBoneyardUpsellHint',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasCoachProfile' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'isBrandManagerEditor',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featureSwitches' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FeatureSwitchesQueryQuery,
  FeatureSwitchesQueryQueryVariables
>;
