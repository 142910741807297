import type { LoginState } from '@xing-com/crate-xinglet';
import { LOGGED_IN, LOGGED_OUT, SOFT_LOGGED_IN } from '@xing-com/crate-xinglet';
import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';

export type UseLoginState = {
  loginState?: LoginState;
  isLoggedOut: boolean;
  isLoggedIn: boolean;
  isSoftLoggedIn: boolean;
};

export function useLoginState(): UseLoginState {
  const { loginState } = useRuntimeConfig();

  return {
    loginState,
    isLoggedIn: loginState === LOGGED_IN,
    isLoggedOut: loginState === LOGGED_OUT,
    isSoftLoggedIn: loginState === SOFT_LOGGED_IN,
  };
}
