import styled, { css } from 'styled-components';

import { SkeletonBase } from './skeleton-base.styles';

type SkeletonCircleTProps = {
  $size?: string;
};

export const SkeletonCircle = styled(SkeletonBase)<SkeletonCircleTProps>`
  border-radius: 50%;

  ${({ $size }) =>
    $size &&
    css`
      height: ${$size};
      width: ${$size};
    `};
`;
