import styled, { css } from 'styled-components';

import { TextButton } from '@xing-com/button';
import { cornerRadiusL, spaceS, spaceM, xdlColorHover } from '@xing-com/tokens';

type SingleSelectTransientProps = {
  $selected?: boolean;
};

// *** Components ***
export const SingleSelect = styled(TextButton)<SingleSelectTransientProps>`
  && {
    border-radius: ${cornerRadiusL};
    display: flex;
    font-weight: 400;
    height: auto;
    justify-content: flex-start;
    padding-bottom: ${spaceM};
    padding-left: ${spaceS};
    padding-right: ${spaceS};
    padding-top: ${spaceM};
    width: 100%;

    ${({ $selected }) =>
      $selected &&
      css`
        font-weight: bold;
      `}

    &:focus,
      &:hover {
      background: ${xdlColorHover};
    }
  }
`;

export const SingleSelectContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
