import styled, { css } from 'styled-components';

import { IconTick } from '@xing-com/icons';
import {
  cornerRadiusS,
  motionEasingStandard,
  motionTimeM,
  motionTimeS,
  scale010,
  scale110,
  xdlColorBackground,
  xdlColorBorder,
  xdlColorDisabledSoft,
  xdlColorDisabledStrong,
  xdlColorError,
  xdlColorSelected,
} from '@xing-com/tokens';

type CheckboxTransientProps = {
  $error?: boolean;
};

const errorStyle = css`
  border-color: ${xdlColorError};
  border-style: solid;
`;

// *** Components ***
export const Container = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  height: ${scale110};
  position: relative;
  width: ${scale110};
`;

export const Checkbox = styled.input<CheckboxTransientProps>`
  appearance: none;
  background-color: ${xdlColorBackground};
  border-color: ${xdlColorBorder};
  border-radius: ${cornerRadiusS};
  border-style: solid;
  border-width: ${scale010};
  box-sizing: border-box;
  display: inline-flex;
  flex-shrink: 0;
  height: ${scale110};
  margin: 0;
  width: ${scale110};

  ${(props) => css`
    ${props.$error && errorStyle};
  `};

  &:hover {
    cursor: pointer;
  }

  &:checked {
    border-color: ${xdlColorSelected};
    border-style: solid;
    border-width: calc(${scale110} / 2);
    transition-duration: ${motionTimeS};
    transition-property: border;
    transition-timing-function: ${motionEasingStandard};

    ${(props) => css`
      ${props.$error && errorStyle};
    `};
  }

  &[disabled] {
    border-color: ${xdlColorDisabledSoft};
  }

  &[disabled]:hover {
    cursor: default;
  }
`;

export const Tick = styled(IconTick)`
  color: ${xdlColorBackground};
  height: ${scale110};
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: ${scale110};
  z-index: 1;

  ${Checkbox}:checked ~ & {
    opacity: 1;
    transition-delay: ${motionTimeS};
    transition-duration: ${motionTimeM};
    transition-property: opacity;
    transition-timing-function: ${motionEasingStandard};
  }

  ${Checkbox}[disabled]:checked ~ & {
    color: ${xdlColorDisabledStrong};
  }
`;
