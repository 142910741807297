import { useEffect, useRef, type FC } from 'react';
import styled from 'styled-components';

import {
  useActivityCenterTotal,
  useIsActivityCenterVisible,
  useSetActivityCenterVisible,
} from '@xing-com/hub';
import { mediaTopBarVisible } from '@xing-com/layout-tokens';
import type { LinkProps } from '@xing-com/link';
import { Link } from '@xing-com/link';
import { space3XL, spaceL, spaceXS, xdlColorText } from '@xing-com/tokens';

import { SuperEllipseWithBadge } from './super-ellipse-with-badge';

type ActivityCenterButtonProps = {
  item: {
    key: string;
    title: string;
    href?: string;
  };
};

export const ActivityCenterButton: FC<ActivityCenterButtonProps> = ({
  item,
}) => {
  const total = useActivityCenterTotal();
  const setActivityCenterVisible = useSetActivityCenterVisible();
  const isActivityCenterVisible = useIsActivityCenterVisible();
  const isActivityCenterAvailable = setActivityCenterVisible.length > 0;

  const justClosedRef = useRef<boolean>(false);

  useEffect(() => {
    if (isActivityCenterVisible) return;

    justClosedRef.current = true;

    const timerId = setTimeout(() => {
      justClosedRef.current = false;
    }, 100);

    return () => {
      clearTimeout(timerId);
      justClosedRef.current = false;
    };
  }, [isActivityCenterVisible]);

  return (
    <StyledLink
      id="activity-center-button"
      data-qa="frame-vnav-activity-center-button"
      to={item.href}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (isActivityCenterAvailable) {
          event.preventDefault();
        }

        if (!justClosedRef.current) {
          setActivityCenterVisible(!isActivityCenterVisible);
        }
      }}
    >
      <SuperEllipseWithBadge count={total} />
      <StyledTitle>{item.title}</StyledTitle>
    </StyledLink>
  );
};

const StyledLink = styled(Link)<LinkProps>`
  margin-right: ${space3XL};
  margin-bottom: ${spaceXS};

  color: ${xdlColorText};
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @media ${mediaTopBarVisible} {
    margin-right: ${spaceL};
    margin-bottom: 0;
  }
  position: relative;
`;

const StyledTitle = styled.p`
  margin: 0;
  text-decoration: none;
  text-align: left;

  @media ${mediaTopBarVisible} {
    display: none;
  }
`;
