/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconJobs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconJobs"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 3a5 5 0 0 1 4.9 4H21v14H3V7h4.1A5 5 0 0 1 12 3m-1 11H5v5h14v-5h-6v2h-2zm8-5H5v3h14zm-7-4a3 3 0 0 0-2.83 2h5.66A3 3 0 0 0 12 5"
    />
  </svg>
);
export default IconJobs;
