import { useIntl } from 'react-intl';

import { useFeature } from '@xing-com/hub';

import type { ProductsMenuProps } from './products-menu';
import { ProductsMenu } from './products-menu';
import { ProductsMenuItem } from './products-menu-item';
import { ProductsMenuUpsellArea } from './products-menu-upsell-area';
import { StyledHeadline } from './products-menu.styles';

// LOGGED OUT - ProductsMenuWithItems
export const ProductsMenuWithItemsLoggedOut: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <ProductsMenu>
      <StyledHeadline size="xxlarge">
        {formatMessage({
          id: 'MAINNAV_PRODUCTS_MENU_HEADLINE',
        })}
      </StyledHeadline>
      <ProductsMenuUpsellArea />
    </ProductsMenu>
  );
};

// LOGGED IN - ProductsMenuWithItems
export const ProductsMenuWithItemsLoggedIn: React.FC<ProductsMenuProps> = ({
  hideTitleOnSmallerScreens = false,
}) => {
  const { formatMessage } = useIntl();

  const isAdmasterUser = useFeature('isAdmasterUser', false);
  const isBrandManagerEditor = useFeature('isBrandManagerEditor', false);
  const isJobsPoster = useFeature('isJobsPoster', false);
  const isCraUser = useFeature('isCraUser', false);
  const isSeatManagerAdmin = useFeature('isSeatManagerAdmin', false);
  const isRecruitingConditionMet =
    (isJobsPoster && !isCraUser) || (!isJobsPoster && isCraUser);

  return (
    <ProductsMenu hideTitleOnSmallerScreens={hideTitleOnSmallerScreens}>
      <StyledHeadline size="xxlarge">
        {formatMessage({
          id: 'MAINNAV_PRODUCTS_MENU_HEADLINE',
        })}
      </StyledHeadline>

      {isAdmasterUser ? (
        <ProductsMenuItem
          testId="products-menu-item-admaster"
          link={{
            href: '/xam/personal/ads',
            title: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_ADVERTISING',
            }),
            description: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_ADVERTISING_DESCRIPTION',
            }),
            tracking: {
              sc_o: 'navigation_ad_manager',
            },
          }}
        />
      ) : null}
      {isBrandManagerEditor ? (
        <ProductsMenuItem
          testId="products-menu-item-brand-manager"
          link={{
            href: '/brandmanager',
            title: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_BRAND_MANAGER',
            }),
            description: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_BRAND_MANAGER_DESCRIPTION',
            }),
            tracking: {
              sc_o: 'navigation_brand_manager',
            },
          }}
        />
      ) : null}
      {isSeatManagerAdmin ? (
        <ProductsMenuItem
          testId="products-menu-item-seat-manager"
          link={{
            href: '/seat-manager',
            title: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_SEAT_MANAGER',
            }),
            description: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_SEAT_MANAGER_DESCRIPTION',
            }),
            tracking: {
              sc_o: 'navigation_licence_manager',
            },
          }}
        />
      ) : null}
      {isJobsPoster ? (
        <ProductsMenuItem
          testId="products-menu-item-jobs-poster"
          link={{
            href: '/jobmanager/online',
            title: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_JOBS_POSTER',
            }),
            description: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_JOBS_POSTER_DESCRIPTION',
            }),
            tracking: {
              sc_o: 'navigation_job_manager',
            },
          }}
        />
      ) : null}
      {isCraUser ? (
        <ProductsMenuItem
          testId="products-menu-item-talent-manager"
          link={{
            href: '/xtm',
            title: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_TALENT_MANAGER',
            }),
            description: formatMessage({
              id: 'MAINNAV_PRODUCTS_MENU_TALENT_MANAGER_DESCRIPTION',
            }),
            tracking: {
              sc_o: 'navigation_talent_manager',
            },
          }}
        />
      ) : null}

      <ProductsMenuUpsellArea
        isAdmasterUser={isAdmasterUser}
        isJobsPoster={isJobsPoster}
        isCraUser={isCraUser}
        isRecruitingConditionMet={isRecruitingConditionMet}
        isBrandManagerEditor={isBrandManagerEditor}
      />
    </ProductsMenu>
  );
};
