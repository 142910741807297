import type * as React from 'react';
import styled, { css } from 'styled-components';

import type { ChildrenType } from '@xing-com/badge';
import { NotificationBadge } from '@xing-com/badge';
import { spaceXS, spaceS, spaceM, spaceL, scale110 } from '@xing-com/tokens';

const StyledIconWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  position: relative;
`;

const spaceForCount = (countString: ChildrenType | undefined): string => {
  const count = Number(countString);
  if (isNaN(count)) return spaceS;
  if (count < 10) return spaceS;
  if (count > 99) return spaceL;
  return spaceM;
};

const StyledNotificationBadge = styled(NotificationBadge)`
  position: absolute;
  top: calc(${spaceXS} * -1);
  right: ${({ children }) => css`calc(${spaceForCount(children)} * -1)`};
`;

type IconWithBadgeProps = {
  icon: React.ElementType<{ className?: string }>;
  title?: string;
  className?: string;
  count?: number | null;
};

const UnstyledIconWithBadge: React.FC<IconWithBadgeProps> = ({
  icon: Icon,
  title,
  count = 0,
  className,
}) => {
  return (
    <StyledIconWrapper>
      <Icon className={className} aria-label={title} />
      {count ? (
        <StyledNotificationBadge>{count}</StyledNotificationBadge>
      ) : null}
    </StyledIconWrapper>
  );
};

const IconWithBadge = styled(UnstyledIconWithBadge)`
  flex: 0 0 ${scale110};
  height: ${scale110};
  width: ${scale110};
`;

export { IconWithBadge };
