import styled, { css } from 'styled-components';

import { IconArrowDown } from '@xing-com/icons';
import { spaceS, spaceXS, spaceXXS } from '@xing-com/tokens';

import { Flag } from '..';

type IconTransientProps = {
  $show: boolean;
};

// *** States ***
const stateIconDown = css`
  transform: rotate(0deg);
  transition: transform 0.2s linear;
`;

const stateIconUp = css`
  transform: rotate(-180deg);
  transition: transform 0.2s linear;
`;

// *** Components ***
export const InteractiveFlag = styled(Flag)`
  cursor: pointer;
  padding-left: ${spaceS};
  padding-right: ${spaceXS};
`;

export const Icon = styled(IconArrowDown)<IconTransientProps>`
  margin-left: ${spaceXXS};
  ${({ $show }) => ($show ? stateIconUp : stateIconDown)}
`;
