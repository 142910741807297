import styled, { css } from 'styled-components';

import {
  scale010,
  scale050,
  scale060,
  scale100,
  scale110,
  scale130,
  xdlColorNotification,
  xdlColorTextOnDark,
} from '@xing-com/tokens';

import { Badge } from '../badge';
import type { NotificationBadgeVariant } from './notification-badge.types';

type NotificationBadgeTransientProps = {
  $variant: NotificationBadgeVariant;
};

// *** Variants ***
export const variants = {
  circle: css`
    width: ${scale100};
  `,
  pill: css`
    width: ${scale110};
  `,
  widePill: css`
    width: ${scale130};
  `,
};

// *** Components ***
export const NotificationBadge = styled(Badge)<NotificationBadgeTransientProps>`
  background-color: ${xdlColorNotification};
  border-color: ${xdlColorTextOnDark};
  border-radius: ${scale050};
  border-style: solid;
  border-width: ${scale010};
  color: ${xdlColorTextOnDark};
  font-size: ${scale060};
  font-weight: bold;
  height: ${scale100};
  line-height: 0;
  ${({ $variant }) => $variant && variants[$variant]};
`;
