import type { FC, PropsWithChildren, ReactNode } from 'react';

import { BottomBar } from '@xing-com/platform-layout-bottom-bar';
import { Footer } from '@xing-com/platform-layout-footer';
import { SideBar } from '@xing-com/platform-layout-side-bar';
import { SkipNavigation } from '@xing-com/platform-layout-skip-navigation';
import { CenterContainer } from '@xing-com/platform-layout-view-wrapper';
import { useMediaListener, BREAKPOINTS } from '@xing-com/util';
import { GridContainer } from '@xing-com/xing-grid';

import {
  StyledLayoutMainWrapper,
  StyledHeaderLoggedIn,
  StyledSideBarWrapper,
  StyledScrollWrapper,
  StyledContainer,
  StyledMain,
} from './layout-main.styles';

type LayoutMainInnerWrappersProps = {
  contentClassName?: string;
  gridClassName?: string;
  gridless?: boolean;
  sidebar?: ReactNode;
  type?: 'standard' | 'wide';
};

export const LayoutMainInnerWrappers: FC<
  PropsWithChildren<LayoutMainInnerWrappersProps>
> = ({
  children,
  contentClassName,
  gridClassName,
  gridless = false,
  sidebar,
  type = 'standard',
}) => {
  return (
    <CenterContainer>
      {/* Hide sidebar if LoggedOut */}
      <StyledSideBarWrapper $gridless={gridless}>
        {sidebar || <SideBar />}
      </StyledSideBarWrapper>

      <StyledScrollWrapper>
        <StyledContainer $gridless={gridless} className={contentClassName}>
          <StyledMain $gridless={gridless} id="content">
            {gridless ? (
              children
            ) : (
              <GridContainer className={gridClassName} type={type} withSidenav>
                {children}
              </GridContainer>
            )}
          </StyledMain>
        </StyledContainer>
      </StyledScrollWrapper>
    </CenterContainer>
  );
};

type LayoutMainProps = LayoutMainInnerWrappersProps & {
  className?: string;
  helpLink?: string; // not in use currently
  homeLink?: string;
  testId?: string;
  // @deprecated: Is always true now, make sure to use a top bar
  withSubNav?: true;
};

export const LayoutMain: FC<PropsWithChildren<LayoutMainProps>> = ({
  children,
  className,
  contentClassName,
  gridClassName,
  homeLink = '/',
  sidebar,
  testId,
  type,
  gridless,
}) => {
  const breakpoint = useMediaListener();
  // Footer should be hidden on mobile
  const isNotHandheld = breakpoint !== BREAKPOINTS.handheld;

  return (
    <StyledLayoutMainWrapper
      className={className}
      data-qa={testId && `core-${testId}`}
    >
      <SkipNavigation />
      <StyledHeaderLoggedIn homeLink={homeLink} />

      <LayoutMainInnerWrappers
        {...{
          children,
          contentClassName,
          gridClassName,
          gridless,
          sidebar,
          type,
        }}
      />

      {isNotHandheld && <Footer homeLink={homeLink} />}
      <BottomBar />
    </StyledLayoutMainWrapper>
  );
};
