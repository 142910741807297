import * as React from 'react';

import * as Styled from './label.styles';
import type { LabelProps } from './label.types';

export const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  ({ children, className, htmlFor, ...props }, forwardedRef): JSX.Element => (
    <Styled.Label
      className={className}
      data-xds="Label"
      htmlFor={htmlFor}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Styled.Label>
  )
);

Label.displayName = 'Label';
