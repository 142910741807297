import { useIntl } from 'react-intl';

import { IconOtherServices } from '@xing-com/icons';
import { HorizontalNavMenu } from '@xing-com/platform-layout-horizontal-nav-menu';
import { scale440 } from '@xing-com/tokens';

import {
  StyledIconWithBadge,
  StyledTitle,
  StyledLink,
  ProductsWrapper,
} from './products-menu.styles';

export type ProductsMenuProps = {
  hideTitleOnSmallerScreens?: boolean;
};

export const ProductsMenu: React.FC<
  React.PropsWithChildren<ProductsMenuProps>
> = ({ children, hideTitleOnSmallerScreens = false }) => {
  const { formatMessage } = useIntl();

  return (
    <ProductsWrapper>
      <HorizontalNavMenu
        tracking={{ PropTrackAction: 'navigation_products_menu' }}
        data-testid="products-menu-drop-down"
        withMenu
        width={scale440}
        trigger={(onClick: (event: React.MouseEvent) => void) => (
          <StyledLink
            to={'https://www.xing.com/business-solutions'}
            $isActive={false}
            data-testid="products-menu"
            onClick={onClick}
          >
            <StyledIconWithBadge icon={IconOtherServices} />
            <StyledTitle $hideTitleOnSmallerScreens={hideTitleOnSmallerScreens}>
              {formatMessage({ id: 'MAINNAV_PRODUCTS' })}
            </StyledTitle>
          </StyledLink>
        )}
      >
        {children}
      </HorizontalNavMenu>
    </ProductsWrapper>
  );
};
