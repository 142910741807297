/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconHome"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 2.089 3 9.998v12h7v-5h4v5h7v-12zm0 2.662 7 6.152v9.095h-3v-5H8v5H5v-9.095z"
    />
  </svg>
);
export default IconHome;
