/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const FallbackImageTopic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 128"
    data-xds="FallbackImageTopic"
    {...props}
  >
    <path fill="#F4F4F5" d="M0 0h128v128H0z" />
    <path fill="#DBE2E6" d="M37.77 47.213H20.984V64H37.77z" />
    <path
      fill="#ACB8CA"
      fillRule="evenodd"
      d="M107.016 13.64H20.984v22.032h86.032zm0 58.753H20.984v4.197h86.032zm-86.032 12.59h86.032v4.197H20.984zm86.032 12.59H20.984v4.197h86.032zm-86.032 12.591h86.032v4.197H20.984zm86.032-62.95H48.262v4.196h58.754zm-58.754 12.59h58.754V64H48.262z"
      clipRule="evenodd"
    />
  </svg>
);
export default FallbackImageTopic;
