import type { FC, SVGProps } from 'react';

const Clapper: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path
        d="M9,19 C9,20.6568542 10.3431458,22 12,22 C13.6568542,22 15,20.6568542 15,19 L13,19 C13,19.5522847 12.5522847,20 12,20 C11.4477153,20 11,19.5522847 11,19 L9,19 Z"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export { Clapper };
