import * as React from 'react';
import styled from 'styled-components';

import { mediaWideNavless } from '@xing-com/layout-tokens';
import { MetaRenderer } from '@xing-com/meta-renderer';
import type { Config } from '@xing-com/meta-renderer';
import { spaceS } from '@xing-com/tokens';

import { commonResolvers } from './common-resolvers';
import { FooterNavItem } from './footer-nav-item';
import { ToggledFooterSection } from './toggled-footer-section';

const resolvers = {
  ...commonResolvers,
  FooterSection: ToggledFooterSection,
  FooterNavItem,
};

export const SmallScreenFooter: React.FC<{ config: Config }> = ({ config }) => {
  const [activeId, setActiveId] = React.useState<string>('');

  const register = React.useCallback((id: string) => {
    setActiveId((activeId) => activeId || id);
  }, []);

  const activate = React.useCallback(
    (id: string) => {
      setActiveId(id === activeId ? '' : id);
    },
    [activeId, setActiveId]
  );

  return (
    <FooterSections data-qa="frame-footer-smallscreen-sections">
      <MetaRenderer
        resolvers={resolvers}
        config={config}
        activeId={activeId}
        activate={activate}
        register={register}
      />
    </FooterSections>
  );
};

const FooterSections = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  margin-top: ${spaceS};
  width: 100%;

  @media ${mediaWideNavless} {
    display: none;
  }
`;
