import * as React from 'react';

import { IconCross } from '@xing-com/icons';

import * as Styled from './bottom-sheet.styles';
import type { BottomSheetProps } from './bottom-sheet.types';

export const BottomSheetPrimitive = React.forwardRef<
  HTMLDivElement,
  BottomSheetProps & { closeModal: (event: React.SyntheticEvent) => void }
>(
  (
    { children, className, closeModal, show, showCloseButton, ...props },
    forwardedRef
  ): JSX.Element => (
    <Styled.BottomSheet
      className={className}
      data-show={show}
      data-xds="BottomSheet"
      ref={forwardedRef}
      {...props}
    >
      <Styled.Wrapper>
        {children}
        {showCloseButton ? (
          <Styled.Close icon={IconCross} onClick={closeModal} />
        ) : null}
      </Styled.Wrapper>
    </Styled.BottomSheet>
  )
);

BottomSheetPrimitive.displayName = 'BottomSheet (Primitive)';
