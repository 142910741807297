import styled from 'styled-components';

import { spaceM } from '@xing-com/tokens';

export const StyledSettingsDropdownList = styled.div`
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0 ${spaceM} ${spaceM};
`;
