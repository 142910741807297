import styled, { css } from 'styled-components';

import { mediaTopBarHidden, mediaTopBarVisible } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import { IconWithBadge } from '@xing-com/platform-layout-icon-with-badge';
import {
  cornerRadiusL,
  scale040,
  scale060,
  scale110,
  scale150,
  xdlColorHover,
  xdlColorHoverSecondary,
  xdlColorText,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

type LinkProps = {
  $isActive: boolean;
};

export const StyledIconWithBadge = styled(IconWithBadge)`
  border-radius: ${cornerRadiusL};

  @media ${mediaTopBarVisible} {
  }
  height: ${scale110};
  width: ${scale150};
  flex: 0 0 ${scale150};
`;

const linkBase = css<LinkProps>`
  color: ${xdlColorText};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:active {
    ${StyledIconWithBadge} {
      background-color: ${xdlColorHoverSecondary};
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const StyledLink = styled(Link)<LinkProps>`
  &:hover ${StyledIconWithBadge} {
    background-color: ${xdlColorHover};
  }

  ${linkBase}
  ${({ $isActive }) =>
    $isActive &&
    css`
      font-weight: bold;
    `};
`;

export const StyledHeadline = styled(Headline)`
  padding-top: ${scale040};
`;

export const ProductsWrapper = styled.div`
  position: relative;
  padding-bottom: ${scale110};
  top: ${scale060};
`;

type StyledTitleProps = {
  $hideTitleOnSmallerScreens: boolean;
};

export const StyledTitle = styled.p<StyledTitleProps>`
  margin: 0;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;

  @media ${mediaTopBarVisible} {
    display: none;
  }

  @media ${mediaTopBarHidden} {
    display: ${({ $hideTitleOnSmallerScreens }) =>
      $hideTitleOnSmallerScreens ? 'none' : 'block'};
  }
`;
