import * as React from 'react';

import { scale130, scale150, scale160, scale180 } from '@xing-com/tokens';

import { SkeletonPillShape } from '..';
import type { SkeletonInputBarProps } from '..';

export const SkeletonInputBar = React.forwardRef<
  HTMLDivElement,
  SkeletonInputBarProps
>(({ className, size = 'medium', ...props }, forwardedRef): JSX.Element => {
  const sizes = {
    small: scale130,
    medium: scale150,
    large: scale160,
    xlarge: scale180,
  };

  return (
    <SkeletonPillShape
      className={className}
      data-xds="SkeletonPillShape"
      height={sizes[size]}
      ref={forwardedRef}
      {...props}
    />
  );
});

SkeletonInputBar.displayName = 'SkeletonInputBar';
