import * as React from 'react';

import { cornerRadiusXL } from '@xing-com/tokens';

import type { SkeletonCardProps } from '../';
import * as Styled from './skeleton-card.styles';

export const SkeletonCard = React.forwardRef<HTMLDivElement, SkeletonCardProps>(
  ({ children, className, noPadding, ...props }, forwardedRef): JSX.Element => (
    <Styled.Card
      $noPadding={noPadding}
      className={className}
      cornerRadius={cornerRadiusXL}
      data-xds="SkeletonCard"
      ref={forwardedRef}
      variant="outline"
      {...props}
    >
      {children}
    </Styled.Card>
  )
);

SkeletonCard.displayName = 'SkeletonCard';
