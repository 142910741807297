import * as React from 'react';

import { calculateBlur, FallbackImage } from '../util';
import * as Styled from './super-ellipse.styles';
import type { SuperEllipseProps } from './super-ellipse.types';

export const SuperEllipse = React.forwardRef<HTMLDivElement, SuperEllipseProps>(
  (
    {
      blur,
      className,
      profileName,
      size = 'medium',
      sizeConfined,
      sizeWide,
      sizeXWide,
      src,
      type,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [containerSize, setContainerSize] = React.useState<
      number | undefined
    >();
    const [imageError, setImageError] = React.useState(false);

    React.useEffect(() => {
      if (blur) {
        setContainerSize(ref.current?.clientWidth);
      }
    }, [ref, blur]);

    const blurValue = blur ? calculateBlur(containerSize) : undefined;
    const handleImageError = (): void => setImageError(true);

    return (
      <Styled.SuperEllipse
        $size={size}
        $sizeConfined={sizeConfined}
        $sizeWide={sizeWide}
        $sizeXWide={sizeXWide}
        className={className}
        data-xds="SuperEllipse"
        ref={forwardedRef}
      >
        <Styled.ImageSvgBorder viewBox="0 0 256 256">
          <path
            className="strokeWidth"
            d="M18.947 18.947C5.627 32.267 1 61.517 1 128s4.626 95.732 17.947 109.053C32.267 250.373 61.517 255 128 255s95.732-4.626 109.053-17.947C250.373 223.733 255 194.483 255 128s-4.626-95.732-17.947-109.053C223.733 5.627 194.483 1 128 1S32.268 5.626 18.947 18.947z"
            fill="transparent"
            stroke="transparent"
            vectorEffect="non-scaling-stroke"
          />
        </Styled.ImageSvgBorder>
        <Styled.ImageContainer $blur={blur} $blurValue={blurValue} ref={ref}>
          {src && !imageError ? (
            <Styled.Image
              alt={profileName}
              aria-label={profileName}
              onError={handleImageError}
              title={profileName}
              {...props}
              src={src}
            />
          ) : (
            <FallbackImage
              aria-label={profileName}
              data-qa="fallback-image"
              data-testid="fallback-image"
              type={type}
              {...props}
            />
          )}
        </Styled.ImageContainer>
      </Styled.SuperEllipse>
    );
  }
);

SuperEllipse.displayName = 'SuperEllipse';
