import styled, { css } from 'styled-components';

import { IllustrationStickerNetwork } from '@xing-com/illustrations';
import { SingleSelect } from '@xing-com/menu';
import {
  xdlColorMenuHighlight,
  xdlColorMenuHighlightHover,
  xdlColorMenuHighlightPressed,
  xdlColorMenuStrong,
  xdlColorMenuStrongHover,
  xdlColorMenuStrongPressed,
  spaceS,
  spaceL,
  spaceM,
  spaceXL,
  cornerRadiusXL,
  space3XL,
  scale160,
} from '@xing-com/tokens';

export const StyledUpsellContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: ${space3XL};
  align-items: center;
  white-space: nowrap;
`;

export const StyledIllustrationNetwork = styled(IllustrationStickerNetwork)`
  width: ${scale160};
  height: ${scale160};
  margin-block: -${scale160};
`;

export const StyledSingleSelect = styled(SingleSelect)`
  padding-block: ${spaceM};
  padding-inline: ${spaceS};
`;

const colorSets = {
  upsell: {
    background: xdlColorMenuHighlight,
    hover: xdlColorMenuHighlightHover,
    pressed: xdlColorMenuHighlightPressed,
  },
  upsell360: {
    background: xdlColorMenuStrong,
    hover: xdlColorMenuStrongHover,
    pressed: xdlColorMenuStrongPressed,
  },
} as const;

export const StyledUpsellSingleSelect = styled(SingleSelect)<{
  $colorSet: keyof typeof colorSets;
}>`
  && {
    padding-block: ${spaceXL};
    padding-inline: ${spaceL};
    border-radius: ${cornerRadiusXL};
    margin-block: ${spaceM};

    & > *,
    & > * > * {
      width: 100%;
    }

    ${({ $colorSet }) => css`
      background-color: ${colorSets[$colorSet].background};
      &:hover {
        background-color: ${colorSets[$colorSet].hover};
      }
      &:active {
        background-color: ${colorSets[$colorSet].pressed};
      }
    `}
  }
`;
