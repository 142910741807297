import type { RouteComponentProps } from '@reach/router';
import type * as React from 'react';

import { XingletLoader } from '@xing-com/crate-xinglet';

export const NotFound: React.FC<RouteComponentProps> = () => (
  <XingletLoader
    name="@xing-com/crate-communication-error-pages"
    errorType="NotFound"
  />
);
