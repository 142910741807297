/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconNotificationCenter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconNotificationCenter"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11 19a1 1 0 1 0 2 0zm1-13a5 5 0 0 0-5 5v6h10v-6a5 5 0 0 0-5-5m3 13a3 3 0 1 1-6 0H3v-2h2v-6a7 7 0 0 1 6-6.93V2h2l.001 2.071A7 7 0 0 1 19 11v6h2v2z"
    />
  </svg>
);
export default IconNotificationCenter;
