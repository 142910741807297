import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import {
  scale005,
  scale010,
  scale110,
  scale130,
  scale160,
  scale190,
  scale220,
  scale250,
  scale280,
  scale310,
  scale340,
} from '@xing-com/tokens';

import { SkeletonSuperEllipse } from '../';
import type { SkeletonProfileImageSize } from './skeleton-profile-image.types';

type SuperEllipseTProps = {
  $size?: SkeletonProfileImageSize;
  $sizeConfined?: SkeletonProfileImageSize;
  $sizeWide?: SkeletonProfileImageSize;
};

// *** Sizes ***
export const sizes = {
  xxsmall: css`
    height: calc(${scale110} + (2 * ${scale005}));
    width: calc(${scale110} + (2 * ${scale005}));
  `,
  xsmall: css`
    height: calc(${scale130} + (2 * ${scale005}));
    width: calc(${scale130} + (2 * ${scale005}));
  `,
  small: css`
    height: calc(${scale160} + (2 * ${scale005}));
    width: calc(${scale160} + (2 * ${scale005}));
  `,
  medium: css`
    height: calc(${scale190} + (2 * ${scale005}));
    width: calc(${scale190} + (2 * ${scale005}));
  `,
  large: css`
    height: calc(${scale220} + (2 * ${scale010}));
    width: calc(${scale220} + (2 * ${scale010}));
  `,
  xlarge: css`
    height: calc(${scale250} + (2 * ${scale010}));
    width: calc(${scale250} + (2 * ${scale010}));
  `,
  xxlarge: css`
    height: calc(${scale280} + (2 * ${scale010}));
    width: calc(${scale280} + (2 * ${scale010}));
  `,
  xxxlarge: css`
    height: calc(${scale310} + (2 * ${scale010}));
    width: calc(${scale310} + (2 * ${scale010}));
  `,
  xxxxlarge: css`
    height: calc(${scale340} + (2 * ${scale010}));
    width: calc(${scale340} + (2 * ${scale010}));
  `,
};

// *** Components ***
export const SuperEllipse = styled(SkeletonSuperEllipse)<SuperEllipseTProps>`
  ${({ $size }) => $size && sizes[$size]};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]};
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]};
      }
    `};
`;
