import { useLazyQuery } from '@apollo/client';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

import { AdvertisingScrambledIdDocument as GET_SCRAMBLED_ID } from './advertising-scrambled-id.gql-types';

export const useScrambledId = (): void => {
  const [fetchScrambledId] = useLazyQuery(GET_SCRAMBLED_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.viewer?.advertisingScrambledId) {
        Cookies.set('s_id2', data?.viewer?.advertisingScrambledId, {
          expires: 30,
          domain: 'xing.com',
        });
      }
    },
  });

  useEffect(() => {
    if (
      !Cookies.get('s_id2') &&
      Cookies.get('userConsent')?.includes('"marketing":true')
    ) {
      fetchScrambledId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
