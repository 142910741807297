import type * as React from 'react';
import styled from 'styled-components';

import { Link } from '@xing-com/link';
import { xdlColorText } from '@xing-com/tokens';

import { useGeneratedLink } from './generate-link';

export const FooterNavItem: React.FC<{
  title: string;
  href: string;
  tracking?: Record<string, string>;
}> = ({ title, href, tracking }) => {
  const link = useGeneratedLink(href, tracking);

  const reOpenConsent = (): void => {
    // @ts-expect-error UC_UI comes from the usercentrics consent script
    UC_UI.showSecondLayer();
  };

  return (
    <FooterNavItemLi>
      {link === 'usercentricsConsentBanner' ? (
        <Link to={''} onClick={reOpenConsent}>
          {title}
        </Link>
      ) : (
        <Link to={link}>{title}</Link>
      )}
    </FooterNavItemLi>
  );
};

const FooterNavItemLi = styled.li`
  line-height: 2.5em;

  & a {
    color: ${xdlColorText};
  }
`;
