import type { FC, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import {
  pagePaddingHD,
  pagePaddingM,
  pagePaddingL,
  pagePaddingXL,
  pagePaddingXXL,
  mediaConfined,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import { xdlColorBackground } from '@xing-com/tokens';

type ViewWrapperProps = {
  className?: string;
  contentClassName?: string;
  Footer?: ReactNode;
  Header?: ReactNode;
  testId?: string;
  preventPropagation?: boolean;
  stopPropagation?: () => void;
};

const StyledWrapper = styled.div`
  background: ${xdlColorBackground};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledPageDiv = styled.div`
  flex-grow: 1;
  margin: 0 0;
  @media ${mediaConfined} {
    margin: 0 ${pagePaddingM};
  }
  @media ${mediaWideNavless} {
    margin: 0 ${pagePaddingL};
  }

  @media (min-width: 1113px) {
    margin: 0 ${pagePaddingXL};
  }

  @media (min-width: 1680px) {
    margin: 0 ${pagePaddingXXL};
  }

  @media (min-width: 1920px) {
    margin: 0 ${pagePaddingHD};
  }
`;

export const ViewWrapper: FC<PropsWithChildren<ViewWrapperProps>> = ({
  children,
  className,
  contentClassName,
  Footer,
  Header,
  testId,
  preventPropagation,
  stopPropagation = (e: { stopPropagation: () => unknown }) =>
    e.stopPropagation(),
  ...props
}) => {
  return (
    <StyledWrapper
      data-qa={testId && `core-${testId}`}
      onClick={preventPropagation ? stopPropagation : undefined}
      {...props}
    >
      {Header}
      <StyledPageDiv className={className}>
        <div className={contentClassName}>
          <main id="content">{children}</main>
        </div>
      </StyledPageDiv>
      {Footer}
    </StyledWrapper>
  );
};
