/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconOtherServices = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconOtherServices"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0M14 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0M22 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0M6 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0M14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0M22 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0M6 20a2 2 0 1 1-4 0 2 2 0 0 1 4 0M14 20a2 2 0 1 1-4 0 2 2 0 0 1 4 0M22 20a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
    />
  </svg>
);
export default IconOtherServices;
