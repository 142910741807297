import type { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import {
  pagePaddingHD,
  pageMaxWidth,
  pagePaddingM,
  pagePaddingL,
  pagePaddingXL,
  pagePaddingXXL,
  mediaConfined,
  mediaWideNavless,
  pagePaddingWXGA,
} from '@xing-com/layout-tokens';

type CenterContainerProps = {
  className?: string;
  type?: 'standard' | 'wide' | 'fullScreen';
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-wrap: nowrap;
  max-width: ${pageMaxWidth};
  padding-left: 0;
  padding-right: 0;
  width: 100%;

  @media ${mediaConfined} {
    padding-left: ${pagePaddingM};
    padding-right: ${pagePaddingM};
  }

  @media ${mediaWideNavless} {
    padding-left: ${pagePaddingL};
    padding-right: ${pagePaddingL};
  }

  @media (min-width: 1113px) {
    padding-left: ${pagePaddingXL};
    padding-right: ${pagePaddingXL};
  }

  @media (min-width: 1440px) {
    padding-left: ${pagePaddingWXGA};
    padding-right: ${pagePaddingWXGA};
  }

  @media (min-width: 1680px) {
    padding-left: ${pagePaddingXXL};
    padding-right: ${pagePaddingXXL};
  }

  @media (min-width: 1920px) {
    margin: auto;
    padding-left: ${pagePaddingHD};
    padding-right: ${pagePaddingHD};
  }
`;

export const CenterContainer: FC<PropsWithChildren<CenterContainerProps>> = ({
  children,
  className = '',
}) => <StyledContainer className={className}>{children}</StyledContainer>;
