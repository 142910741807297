import * as React from 'react';

import * as Styled from './mini-badge.styles';
import type { MiniBadgeProps } from './mini-badge.types';

export const MiniBadge = React.forwardRef<HTMLSpanElement, MiniBadgeProps>(
  ({ className, ...props }, forwardedRef): JSX.Element => (
    <Styled.MiniBadge
      className={className}
      data-xds="MiniBadge"
      ref={forwardedRef}
      {...props}
    />
  )
);

MiniBadge.displayName = 'MiniBadge';
