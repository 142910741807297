import styled, { css } from 'styled-components';

import { mediaNavCollapsed } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import {
  cornerRadiusL,
  cornerRadiusM,
  scale150,
  xdlColorHover,
  xdlColorHoverSecondary,
  xdlColorText,
} from '@xing-com/tokens';

type LinkProps = {
  $isActive: boolean;
};

export const StyledHoverEffect = styled.div`
  border-radius: ${cornerRadiusL};
  width: ${scale150};
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
`;

export const StyledTitle = styled.p`
  margin: 0;
  text-decoration: none;
  text-align: left;
  white-space: nowrap;
`;

const linkBase = css<LinkProps>`
  color: ${xdlColorText};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media ${mediaNavCollapsed} {
    border-radius: ${cornerRadiusM};
    margin-bottom: 0;
  }

  &:active {
    ${StyledHoverEffect} {
      background-color: ${xdlColorHoverSecondary};
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const StyledLink = styled(Link)<LinkProps>`
  ${linkBase}
  &:hover {
    ${StyledHoverEffect} {
      background-color: ${xdlColorHover};
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      font-weight: bold;
    `};
`;
