import type { LoggedInFooterConfig } from '@xing-com/platform-layout-footer';

import type { ConfigurationItem, Translate, Language } from '../types';

export const loggedInFooter: ConfigurationItem = (
  t: Translate,
  lang: Language
): LoggedInFooterConfig => {
  return [
    [
      'FooterSection',
      {
        id: 'company',
        title: t('VERTICAL_NAVIGATION_FOOTER_COMPANY'),
        children: {
          $meta: [
            [
              'FooterNavItem',
              {
                title: t('VERTICAL_NAVIGATION_FOOTER_NEWWORKSE'),
                href:
                  lang === 'de'
                    ? 'https://www.new-work.se/de'
                    : 'https://www.new-work.se/en',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                title: t('VERTICAL_NAVIGATION_FOOTER_CAREER'),
                href:
                  lang === 'de'
                    ? 'https://www.new-work.se/de/karriere/'
                    : 'https://www.new-work.se/en/career/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                title: t('VERTICAL_NAVIGATION_FOOTER_INVESTOR_RELATIONS'),
                href:
                  lang === 'de'
                    ? 'https://www.new-work.se/de/investor-relations/'
                    : 'https://www.new-work.se/en/investor-relations/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                title: t('VERTICAL_NAVIGATION_FOOTER_PRESS'),
                href:
                  lang === 'de'
                    ? 'https://www.new-work.se/de/newsroom/'
                    : 'https://www.new-work.se/en/newsroom/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
          ],
        },
      },
    ],
    [
      'FooterSection',
      {
        id: 'resources',
        title: t('VERTICAL_NAVIGATION_FOOTER_RESOURCES'),
        children: {
          $meta: [
            [
              'FooterNavItem',
              {
                title: t('VERTICAL_NAVIGATION_FOOTER_HELP'),
                href:
                  lang === 'de'
                    ? 'https://faq.xing.com/de'
                    : 'https://faq.xing.com/en',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                title: t('VERTICAL_NAVIGATION_FOOTER_DEVBLOG'),
                href: 'https://tech.new-work.se/',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                title: 'Baller League',
                href: 'https://ballerleague.xing.com/',
              },
            ],
          ],
        },
      },
    ],
    [
      'FooterSection',
      {
        id: 'memberships',
        title: t('VERTICAL_NAVIGATION_FOOTER_MEMBERSHIPS'),
        children: {
          $meta: [
            [
              'FooterNavItem',
              {
                title: t('VERTICAL_NAVIGATION_FOOTER_PREMIUM_MEMBERSHIP'),
                href: 'https://www.xing.com/purchase/premium?reagent=uplt_98',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
            [
              'FooterNavItem',
              {
                title: t('VERTICAL_NAVIGATION_FOOTER_PROJOBS_MEMBERSHIP'),
                href: 'https://www.xing.com/purchase/projobs?reagent=uplt_205',
                tracking: {
                  sc_o: 'navigation_footer',
                },
              },
            ],
          ],
        },
      },
    ],
    [
      'FooterSection',
      {
        id: 'apps',
        title: t('VERTICAL_NAVIGATION_FOOTER_APPS'),
        children: {
          $meta: [
            [
              'FooterNavItem',
              {
                title: t('VERTICAL_NAVIGATION_FOOTER_IPHONE_IPAD'),
                href: 'https://itunes.apple.com/de/app/xing/id297625850?mt=8&ct=footer_Marketing&at=10lRnD',
              },
            ],
            [
              'FooterNavItem',
              {
                title: t('VERTICAL_NAVIGATION_FOOTER_ANDROID'),
                href: 'https://play.google.com/store/apps/details?id=com.xing.android&referrer=utm_source%3DXINGWeb%26utm_medium%3DFooter',
              },
            ],
          ],
        },
      },
    ],
  ];
};
