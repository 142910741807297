import * as React from 'react';

import { useMediaListener } from '@xing-com/util';

import * as Styled from './menu.styles';
import type { MenuProps } from './menu.types';

export const Menu = React.forwardRef<HTMLDivElement, MenuProps>(
  (
    {
      children,
      dimmerTitle,
      isInFlow,
      isStatic,
      offset = 0,
      onOutsideClick,
      show = false,
      triggerRef,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const matchedMedia = useMediaListener();
    const isHandheld = matchedMedia === 'handheld';

    return (
      <React.Fragment>
        {isHandheld ? (
          <Styled.BottomSheet
            dimmerTitle={dimmerTitle}
            onOutsideClick={onOutsideClick}
            ref={forwardedRef}
            show={show}
            {...props}
          >
            {children}
          </Styled.BottomSheet>
        ) : (
          <Styled.PopOver
            isInFlow={isInFlow}
            isStatic={isStatic}
            offset={offset}
            onOutsideClick={onOutsideClick}
            ref={forwardedRef}
            show={show}
            triggerRef={triggerRef}
            {...props}
          >
            {children}
          </Styled.PopOver>
        )}
      </React.Fragment>
    );
  }
);

Menu.displayName = 'Menu';
