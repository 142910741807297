import * as React from 'react';

import {
  LogoXINGLogoBlack,
  LogoXINGLogoDuo,
  LogoXINGLogoInvert,
  LogoXINGLogoWhite,
} from '@xing-com/illustrations';

import * as Styled from './xing-logo.styles';
import type { XingLogoProps } from './xing-logo.types';

const DEFAULT_SIZE = 106;

export const XingLogo = React.forwardRef<HTMLDivElement, XingLogoProps>(
  (
    {
      className,
      inverted = false,
      size = DEFAULT_SIZE,
      variant = 'duo',
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const width = size;
    const height = Math.round((31 * size) / DEFAULT_SIZE); // Calculate height of logo from viewBox
    const defaultProps = {
      height,
      width,
    };

    return (
      <Styled.XingLogo
        className={className}
        data-xds="XingLogo"
        ref={forwardedRef}
      >
        {variant === 'duo' && !inverted && (
          <LogoXINGLogoDuo {...defaultProps} {...props} />
        )}
        {variant === 'duo' && inverted && (
          <LogoXINGLogoInvert {...defaultProps} {...props} />
        )}
        {variant === 'mono' && !inverted && (
          <LogoXINGLogoBlack {...defaultProps} {...props} />
        )}
        {variant === 'mono' && inverted && (
          <LogoXINGLogoWhite {...defaultProps} {...props} />
        )}
      </Styled.XingLogo>
    );
  }
);

XingLogo.displayName = 'XingLogo';
