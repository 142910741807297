import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import {
  cornerRadiusS,
  scale080,
  scale100,
  spaceS,
  spaceXS,
  xdlColorControlFillTertiary,
  xdlColorNotification,
  xdlColorPremium,
  xdlColorProBusiness,
  xdlColorProJobsFill,
  xdlColorText,
  xdlColorTextOnDark,
  xdlColorTextOnLight,
  xdlColorUserRoles,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';
import type { BodyCopyProps } from '@xing-com/typography';

import type { FlagSize, FlagVariant } from './flag.types';

type FlagTransientProps = {
  $size: FlagSize;
  $sizeConfined?: FlagSize;
  $sizeWide?: FlagSize;
  $variant: FlagVariant;
};

// *** Sizes ***
export const sizes = {
  small: css`
    height: ${scale080};
    padding-inline-end: ${spaceXS};
    padding-inline-start: ${spaceXS};
  `,
  medium: css`
    height: ${scale100};
    padding-inline-end: ${spaceS};
    padding-inline-start: ${spaceS};
  `,
};

// *** Variants ***
export const variants = {
  basic: css`
    background-color: ${xdlColorControlFillTertiary};
    color: ${xdlColorText};
  `,
  premium: css`
    background-color: ${xdlColorPremium};
    color: ${xdlColorTextOnDark};
  `,
  proJobs: css`
    background-color: ${xdlColorProJobsFill};
    color: ${xdlColorTextOnLight};
  `,
  insider: css`
    background-color: ${xdlColorUserRoles};
    color: ${xdlColorTextOnDark};
  `,
  new: css`
    background-color: ${xdlColorNotification};
    color: ${xdlColorTextOnDark};
  `,
  beta: css`
    background-color: ${xdlColorNotification};
    color: ${xdlColorTextOnDark};
  `,
  // deprecated
  ambassador: css`
    background-color: ${xdlColorUserRoles};
    color: ${xdlColorTextOnDark};
  `,
  // deprecated
  moderator: css`
    background-color: ${xdlColorUserRoles};
    color: ${xdlColorTextOnDark};
  `,
  // deprecated
  proBusiness: css`
    background-color: ${xdlColorProBusiness};
    color: ${xdlColorTextOnDark};
  `,
  // deprecated
  proCoach: css`
    background-color: ${xdlColorPremium};
    color: ${xdlColorTextOnDark};
  `,
  // deprecated
  proTrainer: css`
    background-color: ${xdlColorPremium};
    color: ${xdlColorTextOnDark};
  `,
};

// *** Components ***
export const Flag = styled.span<FlagTransientProps>`
  align-items: center !important;
  border-radius: ${cornerRadiusS};
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;

  ${({ $size }) => sizes[$size]}

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]};
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]};
      }
    `};

  ${({ $variant }) => variants[$variant]}
`;

export const Text = styled(BodyCopy)<BodyCopyProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1;
`;
