import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide, mediaXWide } from '@xing-com/layout-tokens';
import type { SuperEllipseSize } from '@xing-com/super-ellipse';
import {
  scale110,
  scale130,
  scale160,
  scale190,
  scale220,
  scale250,
  scale280,
  scale310,
  scale340,
} from '@xing-com/tokens';

type ContainerTProps = {
  $size?: SuperEllipseSize;
  $sizeConfined?: SuperEllipseSize;
  $sizeWide?: SuperEllipseSize;
  $sizeXWide?: SuperEllipseSize;
};

type ProgressBadgeContainerTProps = {
  $size?: 'xsmall' | 'small';
};

// *** Badge Position ***
export const badgePosition = {
  xxsmall: css`
    inset-block-end: -1px;
    inset-inline-end: -9px;
  `,
  xsmall: css`
    inset-block-end: -3px;
    inset-inline-end: -3px;
  `,
  small: css`
    inset-block-end: -3px;
    inset-inline-end: -3px;
  `,
  medium: css`
    inset-block-end: -3px;
    inset-inline-end: -3px;
  `,
  large: css`
    inset-block-end: -4px;
    inset-inline-end: -4px;
  `,
  xlarge: css`
    inset-block-end: -4px;
    inset-inline-end: -4px;
  `,
  xxlarge: css`
    inset-block-end: -4px;
    inset-inline-end: -4px;
  `,
  xxxlarge: css`
    inset-block-end: 0;
    inset-inline-end: 0;
  `,
  xxxxlarge: css`
    inset-block-end: -2px;
    inset-inline-end: -2px;
  `,
};

// Same sizes than super-ellipse.style.tsx
export const containerSizes = {
  xxsmall: css`
    height: ${scale110};
    width: ${scale110};
  `,
  xsmall: css`
    height: ${scale130};
    width: ${scale130};
  `,
  small: css`
    height: ${scale160};
    width: ${scale160};
  `,
  medium: css`
    height: ${scale190};
    width: ${scale190};
  `,
  large: css`
    height: ${scale220};
    width: ${scale220};
  `,
  xlarge: css`
    height: ${scale250};
    width: ${scale250};
  `,
  xxlarge: css`
    height: ${scale280};
    width: ${scale280};
  `,
  xxxlarge: css`
    height: ${scale310};
    width: ${scale310};
  `,
  xxxxlarge: css`
    height: ${scale340};
    width: ${scale340};
  `,
};

export const progressBadgePosition = {
  xsmall: css`
    inset-block-start: 19px;
    inset-inline-start: 14px;
  `,
  small: css`
    inset-block-start: 32px;
    inset-inline-start: 24px;
  `,
};

// *** Components ***
export const Container = styled.div<ContainerTProps>`
  display: inline-table;
  position: relative;
  ${({ $size }) => $size && containerSizes[$size]};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${containerSizes[$sizeConfined]};
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${containerSizes[$sizeWide]};
      }
    `};

  ${({ $sizeXWide }) =>
    $sizeXWide &&
    css`
      @media ${mediaXWide} {
        ${containerSizes[$sizeXWide]};
      }
    `};
`;

export const BadgeContainer = styled.div<ContainerTProps>`
  position: absolute;
  z-index: 1;

  ${({ $size }) => $size && badgePosition[$size]};

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${badgePosition[$sizeConfined]};
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${badgePosition[$sizeWide]};
      }
    `};

  ${({ $sizeXWide }) =>
    $sizeXWide &&
    css`
      @media ${mediaXWide} {
        ${badgePosition[$sizeXWide]};
      }
    `};
`;

export const ProgressBadgeContainer = styled.div<ProgressBadgeContainerTProps>`
  position: absolute;
  z-index: 1;

  ${({ $size }) => $size && progressBadgePosition[$size]};
`;
