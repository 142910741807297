import { useLocation } from '@reach/router';

import { Header } from '@xing-com/crate-layout-header';
import { useLoginAppUrl } from '@xing-com/crate-xinglet';

const useLoginLinkWithParams = ({
  loginDestination,
  loginTracking,
}: {
  loginDestination?: string;
  loginTracking?: string;
}): string => {
  let loginAppUrl = useLoginAppUrl();
  const { pathname } = useLocation();

  if (loginDestination) {
    if (loginDestination === 'AUTO') {
      loginDestination = pathname;
    }
    loginAppUrl += `?dest_url=${encodeURIComponent(loginDestination)}`;
  }

  if (loginTracking) {
    if (loginDestination) {
      loginAppUrl += '&';
    } else {
      loginAppUrl += '?';
    }
    loginAppUrl += loginTracking;
  }

  return loginAppUrl;
};

export const HeaderLoggedOutWithLoginLink: React.FC<{
  loginDestination?: string;
  loginTracking?: string;
}> = ({ loginDestination, loginTracking }) => {
  const loginLink = useLoginLinkWithParams({
    loginDestination,
    loginTracking,
  });

  return <Header loginLink={loginLink} />;
};
