import type { FlagVariantText } from './flag.types';

export const flagVariantText: FlagVariantText = {
  ambassador: {
    female: 'XDS_FLAG_AMBASSADOR_FEMALE',
    male: 'XDS_FLAG_AMBASSADOR_MALE',
    diverse: 'XDS_FLAG_AMBASSADOR_DIVERSE',
    neutral: 'XDS_FLAG_AMBASSADOR_NEUTRAL',
  },
  basic: {
    female: 'XDS_FLAG_BASIC_FEMALE',
    male: 'XDS_FLAG_BASIC_MALE',
    diverse: 'XDS_FLAG_BASIC_DIVERSE',
    neutral: 'XDS_FLAG_BASIC_NEUTRAL',
  },
  beta: {
    male: 'XDS_FLAG_BETA_MALE',
    female: 'XDS_FLAG_BETA_FEMALE',
    diverse: 'XDS_FLAG_BETA_DIVERSE',
    neutral: 'XDS_FLAG_BETA_NEUTRAL',
  },
  insider: {
    male: 'XDS_FLAG_INSIDER_MALE',
    female: 'XDS_FLAG_INSIDER_FEMALE',
    diverse: 'XDS_FLAG_INSIDER_DIVERSE',
    neutral: 'XDS_FLAG_INSIDER_NEUTRAL',
  },
  moderator: {
    male: 'XDS_FLAG_MODERATOR_MALE',
    female: 'XDS_FLAG_MODERATOR_FEMALE',
    diverse: 'XDS_FLAG_MODERATOR_DIVERSE',
    neutral: 'XDS_FLAG_MODERATOR_NEUTRAL',
  },
  new: {
    male: 'XDS_FLAG_NEW_MALE',
    female: 'XDS_FLAG_NEW_FEMALE',
    diverse: 'XDS_FLAG_NEW_DIVERSE',
    neutral: 'XDS_FLAG_NEW_NEUTRAL',
  },
  premium: {
    male: 'XDS_FLAG_PREMIUM_MALE',
    female: 'XDS_FLAG_PREMIUM_FEMALE',
    diverse: 'XDS_FLAG_PREMIUM_DIVERSE',
    neutral: 'XDS_FLAG_PREMIUM_NEUTRAL',
  },
  proBusiness: {
    male: 'XDS_FLAG_PROBUSINESS_MALE',
    female: 'XDS_FLAG_PROBUSINESS_FEMALE',
    diverse: 'XDS_FLAG_PROBUSINESS_DIVERSE',
    neutral: 'XDS_FLAG_PROBUSINESS_NEUTRAL',
  },
  proCoach: {
    male: 'XDS_FLAG_PROCOACH_MALE',
    female: 'XDS_FLAG_PROCOACH_FEMALE',
    diverse: 'XDS_FLAG_PROCOACH_DIVERSE',
    neutral: 'XDS_FLAG_PROCOACH_NEUTRAL',
  },
  proJobs: {
    male: 'XDS_FLAG_PROJOBS_MALE',
    female: 'XDS_FLAG_PROJOBS_FEMALE',
    diverse: 'XDS_FLAG_PROJOBS_DIVERSE',
    neutral: 'XDS_FLAG_PROJOBS_NEUTRAL',
  },
  proTrainer: {
    male: 'XDS_FLAG_PROTRAINER_MALE',
    female: 'XDS_FLAG_PROTRAINER_FEMALE',
    diverse: 'XDS_FLAG_PROTRAINER_DIVERSE',
    neutral: 'XDS_FLAG_PROTRAINER_NEUTRAL',
  },
};
