import * as React from 'react';

import { scale130, scale190 } from '@xing-com/tokens';

import { SkeletonRectangle } from '../';
import type { SkeletonTagProps } from './skeleton-tag.types';

export const SkeletonTag = React.forwardRef<HTMLDivElement, SkeletonTagProps>(
  ({ className, ...props }, forwardedRef): JSX.Element => (
    <SkeletonRectangle
      className={className}
      cornerRadius={scale130}
      data-xds="SkeletonTag"
      height={scale130}
      ref={forwardedRef}
      width={scale190}
      {...props}
    />
  )
);

SkeletonTag.displayName = 'SkeletonTag';
