import { useQuery } from '@apollo/client';

import type {
  AsyncHookResult,
  Experiment,
  Experiments,
  ExperimentType,
  RegisteredExperiment,
  Variant,
} from '@xing-com/hub';

import type { ExperimentsQuery } from './experiments.gql-types';
import { ExperimentsDocument } from './experiments.gql-types';
import { FALLBACK_VARIANT } from './hooks';
import { getRegisteredExperiments } from './register-experiment';

type RawExperiment = { name: string; variant: string };

export function experimentNamesByType(
  experiments: RegisteredExperiment[],
  type: ExperimentType
): 'NONE' | string[] {
  const names = experiments
    .filter(({ experimentType }) => experimentType === type)
    .map(({ name }) => name);

  return names.length > 0 ? Array.from(new Set(names)).sort() : 'NONE';
}

export function createExperiments(
  registeredExperiments: RegisteredExperiment[],
  data: ExperimentsQuery,
  defaultStatus: 200 | 404 | 500
): Experiments {
  const experiments: Experiments = Object.fromEntries(
    registeredExperiments.map((experiment): [string, Experiment] => {
      return [
        experiment.name,
        {
          ...experiment,
          variant: FALLBACK_VARIANT,
          status: defaultStatus,
        },
      ];
    })
  );

  const rawVisitorExperiments: RawExperiment[] =
    data?.featureSwitchesLoggedOutExperiments?.collection ?? [];

  const rawUserExperiments: RawExperiment[] =
    data?.viewer?.featureSwitchesExperiments?.collection ?? [];

  const rawExperiments: RawExperiment[] = [
    ...rawUserExperiments,
    ...rawVisitorExperiments,
  ];

  for (const rawExp of rawExperiments) {
    if (experiments[rawExp.name]) {
      experiments[rawExp.name] = {
        ...experiments[rawExp.name],
        status: 200,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        variant: rawExp.variant as Variant,
      };
    }
  }

  return experiments;
}

export function useExperimentsQuery(
  visitorId: string
): AsyncHookResult<Experiments> {
  const visitorIdOrFallback = visitorId;
  const registeredExperiments = getRegisteredExperiments();
  const variables = {
    userExperimentNames: experimentNamesByType(registeredExperiments, 'user'),
    visitorExperimentNames: experimentNamesByType(
      registeredExperiments,
      'visitor'
    ),
    visitorId: visitorIdOrFallback,
  };

  const { data, error } = useQuery(ExperimentsDocument, { variables });

  if (data) {
    const experiments = createExperiments(
      registeredExperiments,
      data,
      error ? 500 : 404
    );

    return { loading: false, data: experiments };
  } else if (error) {
    return { loading: false, error };
  } else {
    return { loading: true };
  }
}
