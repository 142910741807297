import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import {
  ViewWrapper,
  ViewWrapperDefaultGrid,
} from '@xing-com/platform-layout-view-wrapper';
import { Portal } from '@xing-com/portal';

import { CloseButton } from './close-button';
import * as Styled from './modal-page.styles';
import type { ModalPageProps } from './modal-page.types';

const { modalPageDuration, modalPageTransitionName } = Styled;

export const ModalPage = React.forwardRef<HTMLDivElement, ModalPageProps>(
  (
    {
      children,
      closeButton,
      contentClassName,
      isAnimated,
      isShown,
      noGrid,
      noMargin,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const content = (
      <ViewWrapper
        aria-modal
        contentClassName={contentClassName}
        data-xds="ModalPage"
        {...props}
      >
        {closeButton ? <CloseButton {...closeButton} /> : undefined}
        <Styled.Container $noMargin={noMargin} ref={forwardedRef}>
          {children}
        </Styled.Container>
      </ViewWrapper>
    );

    const wrappedContent = noGrid ? (
      content
    ) : (
      <ViewWrapperDefaultGrid>{content}</ViewWrapperDefaultGrid>
    );

    if (isAnimated) {
      return (
        <Portal>
          <CSSTransition
            appear={isShown}
            classNames={modalPageTransitionName}
            in={isShown}
            key="modalPage"
            mountOnEnter
            timeout={parseInt(modalPageDuration)}
            unmountOnExit
          >
            <Styled.AnimatedModalPage>
              {wrappedContent}
            </Styled.AnimatedModalPage>
          </CSSTransition>
        </Portal>
      );
    }

    return wrappedContent;
  }
);

ModalPage.displayName = 'ModalPage';
