import { Fragment } from 'react';
import type { FC, ReactNode } from 'react';

import { useFeatureSwitches } from '@xing-com/hub';

const wrapSingleElementIntoArray = <T,>(elementOrArray: T | T[]): T[] =>
  Array.isArray(elementOrArray) ? elementOrArray : [elementOrArray];

const matchAll = (featureSwitches: string[], flagsArray: string[]): boolean => {
  return flagsArray.every((flag) => featureSwitches.includes(flag));
};

const matchAny = (featureSwitches: string[], flagsArray: string[]): boolean => {
  return flagsArray.some((flag) => featureSwitches.includes(flag));
};

export type MatchMode = 'either' | 'and';

export type FeatureSwitchProps = {
  children?: ReactNode;
  elseCase?: ReactNode;
  flags: string | string[];
  matchMode?: MatchMode;
};

export const FeatureSwitch: FC<FeatureSwitchProps> = ({
  children = null,
  flags,
  elseCase = null,
  matchMode,
}) => {
  const { data } = useFeatureSwitches();

  if (!data) return null;

  const flagsArray = wrapSingleElementIntoArray(flags);

  const matcher = matchMode === 'and' ? matchAll : matchAny;
  const matches = matcher(data, flagsArray);
  return <Fragment>{matches ? children : elseCase}</Fragment>;
};
