/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const FallbackImageUserFemale = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 128"
    data-xds="FallbackImageUserFemale"
    {...props}
  >
    <path fill="#F4F4F5" d="M0 0h128v128H0z" />
    <path
      fill="#ACB8CA"
      fillRule="evenodd"
      d="m62.174 66.315 3.765.057q0-.016.003-.238c.028-2.202.244-19.29-.003-35.847-.113-8.075-2.786-13.93-7.944-17.43-11.67-7.907-31.66-.434-32.508-.114l1.337 3.52c.188-.075 19.011-7.096 29.063-.282 4.066 2.767 6.174 7.586 6.287 14.362.246 16.432.032 33.406.003 35.71l-.002.214zm18.974-17.9c0 1.035.847 1.882 1.883 1.882a1.89 1.89 0 0 0 1.882-1.882v-6.118a1.89 1.89 0 0 0-1.882-1.882 1.89 1.89 0 0 0-1.883 1.882zm-36.25 1.336a1.89 1.89 0 0 1-1.882-1.882V41.75c0-1.035.848-1.882 1.883-1.882s1.882.847 1.882 1.882v6.118A1.89 1.89 0 0 1 44.9 49.75m-6.065 27.35c6.965 6.966 16.113 10.448 25.261 10.448s18.297-3.483 25.261-10.447l-2.654-2.654c-12.461 12.46-32.753 12.46-45.214 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default FallbackImageUserFemale;
