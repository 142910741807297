import type * as React from 'react';

import {
  StyledIconWrapper,
  StyledClapper,
  StyledBell,
} from './ringing-bell.styles';

export const RingingBell: React.FC<{ className?: string }> = ({
  className,
}) => (
  <StyledIconWrapper className={className}>
    <StyledClapper />
    <StyledBell />
  </StyledIconWrapper>
);
