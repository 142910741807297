import * as React from 'react';

import { useZerofyNotificationBadges } from '@xing-com/crate-communication-use-zerofy-notification-badges';
import {
  useActiveItem,
  useNotifications,
  useNotificationsHandler,
} from '@xing-com/hub';

const notificationKeysMap: Record<string, string> = {
  supi: 'ncWeb',
};

const Zerofier: React.VFC = () => {
  const activeItem = useActiveItem();
  const notifications = useNotifications();
  const { change: changeNotification } = useNotificationsHandler();

  const { markBadgesAsSeen } = useZerofyNotificationBadges();
  const currentNotificationBadge =
    activeItem && notificationKeysMap[activeItem];

  const hasNotifications =
    currentNotificationBadge &&
    Boolean(notifications[currentNotificationBadge]);

  React.useEffect(() => {
    if (hasNotifications) {
      changeNotification(currentNotificationBadge, 0);
      markBadgesAsSeen([currentNotificationBadge]);
    }
  }, [
    changeNotification,
    currentNotificationBadge,
    hasNotifications,
    markBadgesAsSeen,
  ]);

  return <></>;
};

export { Zerofier };
