import * as React from 'react';

import { Styled } from './caption.styles';
import type { CaptionProps } from './caption.types';

export const Caption = React.forwardRef<HTMLParagraphElement, CaptionProps>(
  (
    {
      children,
      className,
      ellipsis,
      fontWeight = 'regular',
      lineHeight,
      noMargin,
      ...props
    },
    forwardedRef
  ): JSX.Element => (
    <Styled.Caption
      $ellipsis={ellipsis}
      $fontWeight={fontWeight}
      $lineHeight={lineHeight}
      $noMargin={noMargin}
      className={className}
      data-xds="Caption"
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Styled.Caption>
  )
);

Caption.displayName = 'Caption';
