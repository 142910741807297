import type { BottomNavigationLoggedOutConfig } from '@xing-com/platform-layout-bottom-bar';

import type { ConfigurationItem, Translate, Language } from '../types';

const NAVIGATION_BADGE_NO_BADGE = 'navigation_badge_no_badge';

export const bottomNavigationLoggedOut: ConfigurationItem = (
  t: Translate,
  _lang: Language
): BottomNavigationLoggedOutConfig => [
  [
    'MainList',
    {
      'data-testid': 'MainListLoggedOut',
      children: {
        $meta: [
          [
            'MainListItem',
            {
              item: {
                key: 'jobs',
                icon: { $component: 'IconJobs' },
                activeIcon: { $component: 'IconJobsFilled' },
                title: t('MAINNAV_JOBS_FIND'),
                href: 'https://www.xing.com/',
              },
            },
          ],
          [
            'MainListItem',
            {
              item: {
                key: 'companies',
                icon: { $component: 'IconCompanies' },
                activeIcon: { $component: 'IconCompaniesFilled' },
                href: 'https://www.xing.com/companies',
                title: t('MAINNAV_COMPANIES'),
              },
            },
          ],
          [
            'MainListItem',
            {
              item: {
                key: 'contacts',
                icon: { $component: 'IconContacts' },
                activeIcon: { $component: 'IconContactsFilled' },
                title: t('MAINNAV_NETWORK'),
                activeItemValues: ['members'],
                href: 'https://www.xing.com/network',
              },
            },
          ],
          [
            'MainListItem',
            {
              item: {
                key: 'disco',
                icon: { $component: 'IconNews' },
                activeItemValues: ['insights'],
                activeIcon: { $component: 'IconNewsFilled' },
                title: t('MAINNAV_INSIGHTS'),
                href: 'https://www.xing.com/insights',
                notificationKey: 'insights',
                tracking: {
                  sc_o: 'navigation_insights',
                  sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
                },
              },
            },
          ],
        ],
      },
    },
  ],
];
