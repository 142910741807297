import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { useOmViewRef, useOmViewVisible } from '@xing-com/hub';

const omViewAnimationDuration = 200; // ms

const StyledOmViewPortalTarget = styled.div`
  display: none;

  &.om-view-transition-enter,
  &.om-view-transition-enter-done,
  &.om-view-transition-exit {
    display: block;
  }

  &.om-view-transition-enter,
  &.om-view-transition-exit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 24;
    transition:
      opacity ${omViewAnimationDuration}ms ease-out,
      transform ${omViewAnimationDuration}ms ease-out;
  }

  &.om-view-transition-enter,
  &&.om-view-transition-exit-active {
    opacity: 0.1;
    transform: translateY(5vh);
  }

  &&.om-view-transition-enter-active,
  &.om-view-transition-exit {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const OmViewAnimation: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const isOmViewVisible = useOmViewVisible();
  const omViewRef = useOmViewRef();

  return (
    <React.Fragment>
      <CSSTransition
        in={isOmViewVisible}
        classNames="om-view-transition"
        timeout={omViewAnimationDuration}
      >
        <StyledOmViewPortalTarget ref={omViewRef} />
      </CSSTransition>

      <div style={isOmViewVisible ? { display: 'none' } : undefined}>
        {children}
      </div>
    </React.Fragment>
  );
};
