import { useIntl } from 'react-intl';

import { IconCross } from '@xing-com/icons';

import { StyledButton } from './close-button.styles';
import type { CloseButtonPropsInternal } from './close-button.types';

export const CloseButton = ({
  ...buttonProps
}: CloseButtonPropsInternal): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledButton
      aria-label={intl.formatMessage({ id: 'BUTTON_CLOSEWINDOW' })}
      data-qa="close"
      data-testid="close"
      icon={IconCross}
      size="large"
      {...buttonProps}
    />
  );
};

CloseButton.displayName = 'CloseButton';
