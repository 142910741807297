import type * as React from 'react';

import { Bell } from './bell';
import { StyledIconWrapper, StyledClapper } from './ringing-bell.styles';

export const RingingBell: React.FC = () => (
  <StyledIconWrapper>
    <Bell />
    <StyledClapper />
  </StyledIconWrapper>
);
