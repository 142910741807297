import styled, { css } from 'styled-components';

import { SkeletonBase } from './skeleton-base.styles';

type SkeletonPillShapeTProps = {
  $height: string;
  $width: string;
};

export const SkeletonPillShape = styled(SkeletonBase)<SkeletonPillShapeTProps>`
  max-height: 100px;
  border-radius: 100px;

  ${({ $height }) =>
    $height &&
    css`
      height: ${$height};
    `};

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `};
`;
