/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ExperimentsQueryVariables = Types.Exact<{
  visitorId: Types.Scalars['UUID']['input'];
  userExperimentNames?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.Scalars['String']['input']>>
    | Types.InputMaybe<Types.Scalars['String']['input']>
  >;
  visitorExperimentNames?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.Scalars['String']['input']>>
    | Types.InputMaybe<Types.Scalars['String']['input']>
  >;
}>;

export type ExperimentsQuery = {
  __typename?: 'Query';
  featureSwitchesLoggedOutExperiments?: {
    __typename?: 'FeatureSwitchesExperimentsCollection';
    collection: Array<{
      __typename?: 'FeatureSwitchesExperiment';
      name: string;
      variant: string;
    }>;
  } | null;
  viewer?: {
    __typename?: 'Viewer';
    id: string;
    featureSwitchesExperiments?: {
      __typename?: 'FeatureSwitchesExperimentsCollection';
      collection: Array<{
        __typename?: 'FeatureSwitchesExperiment';
        name: string;
        variant: string;
      }>;
    } | null;
  } | null;
};

export const ExperimentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Experiments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visitorId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userExperimentNames' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visitorExperimentNames' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'featureSwitchesLoggedOutExperiments',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'identifier' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'visitorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'names' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'visitorExperimentNames' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variant' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featureSwitchesExperiments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'names' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userExperimentNames' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variant' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExperimentsQuery, ExperimentsQueryVariables>;
