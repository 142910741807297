import { useRef } from 'react';

import { useHost } from '@xing-com/crate-xinglet';
import type { Variant } from '@xing-com/splitkit';
import { registerExperiment } from '@xing-com/splitkit';

export const useRegisterExperiments = (): void => {
  const host = useHost();
  const metadata = host.getXingletMetadata();
  const registered = useRef(false);

  if (!registered.current) {
    metadata.forEach((data) => {
      data.contributes?.experiments &&
        data.contributes.experiments.forEach(
          ({ name, supportedVariants, experimentType }) => {
            registerExperiment(
              name,
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              supportedVariants as ['A', ...Variant[]],
              experimentType
            );
          }
        );
    });

    registered.current = true;
  }
};
