import styled from 'styled-components';

import { mediaConfined, zIndexElevatedLayer5 } from '@xing-com/layout-tokens';
import { Menu } from '@xing-com/menu';

export const MoreMenuContainer = styled.div`
  position: relative;
`;

const listMaxHeightMobile = '90vh';
const listMaxHeightTabletAndDesktop = '556px';
const listMinWidth = '264px';
const topMarginTabletAndDesktop = '-62px';

export const StyledMenu = styled(Menu)`
  max-height: ${listMaxHeightMobile};
  min-width: ${listMinWidth};
  overflow: auto;
  z-index: ${zIndexElevatedLayer5};

  /* >= 740px */
  @media ${mediaConfined} {
    max-height: ${listMaxHeightTabletAndDesktop};
    position: absolute;
    top: ${topMarginTabletAndDesktop};
  }
`;
