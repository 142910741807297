import type { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useViewerData } from '@xing-com/hub';

import { MEDIA_LARGE, MEDIA_MEDIUM } from './constants';

export const OnlyOnSmall = styled.div`
  display: block;

  ${MEDIA_MEDIUM} {
    display: none;
  }
`;

export const NotOnSmall = styled.div`
  display: none;

  ${MEDIA_MEDIUM} {
    display: block;
  }
`;

export const OnlyOnLarge = styled.div`
  display: none;

  ${MEDIA_LARGE} {
    display: block;
  }
`;

export const NotOnLarge = styled.div`
  display: block;

  ${MEDIA_LARGE} {
    display: none;
  }
`;

export const OnlyLoggedOut: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { data } = useViewerData();
  if ((data?.loginState ?? 'LOGGED_OUT') === 'LOGGED_OUT') {
    return <>{children}</>;
  } else {
    return null;
  }
};

export const NotLoggedOut: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { data } = useViewerData();
  if ((data?.loginState ?? 'LOGGED_OUT') === 'LOGGED_OUT') {
    return null;
  } else {
    return <>{children}</>;
  }
};
