import type * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IconSearch } from '@xing-com/icons';
import { HorizontalNavMenu } from '@xing-com/platform-layout-horizontal-nav-menu';
import { SearchDropdownList } from '@xing-com/platform-search-dropdown';

import { NavItemHeader } from './nav-item-header';

export type SearchDropdownProps = {
  title: string;
};

const SearchButtonContainer = styled.div`
  position: relative;
`;

export const SearchDropdown: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <SearchButtonContainer>
      <HorizontalNavMenu
        tracking={{ PropTrackAction: 'navigation_search_drop_down' }}
        trigger={(onClick: (event: React.MouseEvent) => void) => (
          <NavItemHeader
            icon={IconSearch}
            activeIcon={IconSearch}
            href="https://www.xing.com/jobs/search"
            activeItemValues={['search']}
            title={formatMessage({ id: 'FRAME_HEADER_SEARCH' })}
            onClick={onClick}
          />
        )}
      >
        <SearchDropdownList />
      </HorizontalNavMenu>
    </SearchButtonContainer>
  );
};
